<template>
    <div>
        <EditFitterHourModal
            :fitterHourBefore="selectedFitterHour"
            :isOpen="editFitterHourModalOpen"
        />
        <sui-table>
            <sui-table-header :key="`${fitterName}-header`">
                <sui-table-row>
                    <sui-table-header-cell :colspan="dates.length+1">
                        <i class="fa-solid fa-check" style="color: green;" v-if="groupedHours.TOTAL.total.allApproved" title="Alle noterede timer er godkendt" />
                        {{tableHeader}}
                        <sui-button style="float: right;" @click="updateFitterHours()"><i class="fa-solid fa-rotate" :class="groupedHours.TOTAL.total.loading ? 'fa-spin' : ''"></i></sui-button>
                        <sui-button style="float: right;" @click="addFitterHours(groupedHours.TOTAL.total.fitterID)"><i class="fa-solid fa-plus"></i></sui-button>
                    </sui-table-header-cell>
                </sui-table-row>
                <sui-table-row>
                    <sui-table-header-cell>{{ activeSalaryPeriodName }}</sui-table-header-cell>
                    <sui-table-header-cell v-for="date of dates" :key="date" :class="{todayCell: dateIsToday(date)}">{{ toUserFriendlyDate(date, false) }}</sui-table-header-cell>
                </sui-table-row>
            </sui-table-header>
            <sui-table-body :key="`${fitterName}-body`">
                <template v-for="(projectAndCat, projectAndCategoryString) in groupedHours">
                    <sui-table-row v-if="projectAndCategoryString != 'TOTAL'" :key="projectAndCategoryString" :warning="projectMatchesResponsible(projectAndCat)" 
                    :class="classes(
                        categoryIsOff(projectAndCategoryString) ? 'off-hours' : '',
                        projectAndCategoryString.includes('TAKE BACK TIME') ? 'take-back-time' : '',
                        projectAndCategoryString.includes('ARBEJDSDAG - TBT') ? 'arbejdsfredag' : '',
                        projectAndCategoryString.includes('Lærlinge ARBEJSDAG - TBT') ? 'arbejdsfredag' : '',
                    )"
                >
                        <sui-table-cell>
                            {{parseProjectAndCategory(projectAndCategoryString)[0]}}<br>
                            <small>{{parseProjectAndCategory(projectAndCategoryString)[1]}}</small>&nbsp;
                            <i 
                                v-if="tbtMasterWarning && parseProjectAndCategory(projectAndCategoryString)[1] == 'TAKE BACK TIME'" 
                                :title="`${tbtMasterWarning}\nSkrevet TBT: ${groupedHours.TOTAL.total.takeBackTime} - Beregnet TBT: ${estimateTbTFromTotalsObject(groupedHours.TOTAL, expectedTBTWorkHours)}`" 
                                class="fa-solid fa-triangle-exclamation" style="color: red;"
                            />
                            <i
                                v-if="tbtWorkWarning && (parseProjectAndCategory(projectAndCategoryString)[1] == 'ARBEJDSDAG - TBT' || parseProjectAndCategory(projectAndCategoryString)[1] == 'Lærlinge ARBEJSDAG - TBT')"
                                :title="tbtWorkWarning"
                                class="fa-solid fa-triangle-exclamation" style="color: red;"
                            />
                            <i 
                                v-if="internalWarningGroup(projectAndCategoryString)"
                                title="Denne løndel skal normalt skrives på interne timer"
                                class="fa-solid fa-triangle-exclamation" style="color: orange;"
                            />
                        </sui-table-cell>
                        <sui-table-cell v-for="date of dates" :key="`${date}//${projectAndCategoryString}`" :class="{todayCell: dateIsToday(date)}">
                            <p v-for="hour of projectAndCat[date]" :key="hour.ID" :title="titleForHour(hour)">
                                <span v-if="hour.BasisTotalHours">{{ hour.BasisTotalHours }}</span>
                                <span v-if="hour.FitterHourWorkTypeOtherTotalHours" style="color: orange; font-weight: bold;">({{ hour.FitterHourWorkTypeOtherTotalHours }})</span>
                                <span v-if="hour.OvertimeTotalHours" style="color: red; font-weight: bold;">({{ hour.OvertimeTotalHours }})</span>
                                <i class="fa-solid fa-check" style="color: green;" v-if="hour.ApprovedBy" :title="`Godkendt af ${hour.ApprovedBy}\n${toUserFriendlyTimestamp(hour.ApprovedDate)}`" />
                                <!-- Regigering af timer virker PT ikke, der oprettes i stedet kopier: E-komplet ticket ID 66251 -->
                                <!-- <i class="fa-solid fa-pen editBtn" v-else-if="userHasHourCheckRole || hour.FitterSalaryID.substring(0,3) == userInitials" @click="editHour(hour)" /> -->
                            </p>
                            <p v-if="!projectAndCat[date] || !projectAndCat[date].length">-</p>
                        </sui-table-cell>
                    </sui-table-row>
                </template>
                <sui-table-row v-if="groupedHours && groupedHours.TOTAL">
                    <sui-table-cell v-if="groupedHours.TOTAL.total">
                        <i class="fa-solid fa-check" style="color: green;" v-if="groupedHours.TOTAL.total.allApproved" title="Alle noterede timer er godkendt" />
                        <b>
                            Total: {{ groupedHours.TOTAL.total.basisHours }}
                            <i 
                                v-if="showBasisHoursWarning(groupedHours.TOTAL.total, weeksCount)" 
                                :title="showBasisHoursWarning(groupedHours.TOTAL.total, weeksCount)"
                                class="fa-solid fa-triangle-exclamation" style="color: orange;" 
                            />
                            <br>
                            <span v-if="groupedHours.TOTAL.total.otherHours" style="color: orange; font-weight: bold;">({{ groupedHours.TOTAL.total.otherHours }})</span>
                            <span v-if="groupedHours.TOTAL.total.overtimeHours" style="color: red; font-weight: bold;">({{ groupedHours.TOTAL.total.overtimeHours }})</span>
                        </b>
                    </sui-table-cell>
                    <sui-table-cell v-else><b>Total:</b></sui-table-cell>
                    <sui-table-cell v-for="date of dates" :key="`${date}-sum`" :class="{todayCell: dateIsToday(date), bottomCell: dateIsToday(date)}">
                        <b v-if="groupedHours.TOTAL[date]">
                            {{ groupedHours.TOTAL[date].basisHours }} <i class="fa-solid fa-check" style="color: green;" v-if="groupedHours.TOTAL[date].allApproved" title="Dagens timer er godkendt" />
                            <span v-if="groupedHours.TOTAL[date].otherHours" style="color: orange; font-weight: bold;"><br>({{ groupedHours.TOTAL[date].otherHours }})</span>
                            <span v-if="groupedHours.TOTAL[date].overtimeHours" style="color: red; font-weight: bold;"><br>({{ groupedHours.TOTAL[date].overtimeHours }})</span>
                        </b>
                    </sui-table-cell>
                </sui-table-row>
            </sui-table-body>
        </sui-table>
    </div>
</template>
<script>
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { FitterHourMixin } from '@/lib/Mixins/FitterHourMixin.js'
import EventBus from '@/EventBus'
import EditFitterHourModal from '@/components/Salary/EditFitterHourModal.vue'

export default {
    name: "SalaryCheckFitterTable",
    mixins: [Mixin, DateMixin, FitterHourMixin],
    components: {EditFitterHourModal},
    props: {
        groupedHours: Object,
        dates: Array,
        fitterName: String,
        tableHeader: String,
        projectResponsible: Array,
        expectedTBTWorkHours: Number,
        userInitials: String,
        userHasHourCheckRole: Boolean,
        activeSalaryPeriodName: String,
    },
    data() {
        return {
            selectedFitterHour: null,
            editFitterHourModalOpen: false,
        }
    },
    methods: {
        parseProjectAndCategory(projectAndCategory) {
            return projectAndCategory.split(' // ')
        },
        projectMatchesResponsible(project){
            if (!this.projectResponsible?.length) return false
            for (let date of Object.keys(project)) {
                if (this.projectResponsible.includes(project[date][0]?.ProjectResponsible))
                    return true
            }
            return false
        },
        updateFitterHours() {
            EventBus.$emit('update-fitter-hours', this.fitterName)
        },
        categoryIsOff(cat){
            if (!cat) return false
            if (String(cat).includes('//')) {
                cat = this.parseProjectAndCategory(cat)[1]
            }
            return this.offCategories.includes(String(cat).toUpperCase())
        },
        titleForHour(hour){
            if (!hour) return null
            let titleString = ''
            if (hour.DateCreated) {
                titleString += `${this.toUserFriendlyTimestamp(hour.DateCreated)}\n`
            }
            titleString += hour.Note || '-Ingen note-'
            return titleString
        },
        editHour(hour){
            console.log('User started editing hour:', hour)
            this.selectedFitterHour = hour
            this.editFitterHourModalOpen = true
        },
        addFitterHours(fitterID){
            console.log('User started creating new hour')
            this.selectedFitterHour = {
                Date: new Date(),
                FitterID: fitterID,
                ProjectID: null,
                FitterCategoryID: null,
                Hours: 1,
                Note: null
            }
            this.editFitterHourModalOpen = true
        },
        dateIsToday(date){
            return this.formatMachineDate(date) == this.formatMachineDate(new Date())
        },
    },
    computed: {
        tbtMasterWarning() {
            if (typeof this.groupedHours?.TOTAL?.total?.takeBackTime != 'number' && typeof this.groupedHours?.TOTAL?.total?.tbtWorkday != 'number') return false // No TBT hours registered
            return this.showTbTWarning(this.groupedHours.TOTAL, this.expectedTBTWorkHours)
        },
        tbtWorkWarning() {
            if (typeof this.groupedHours?.TOTAL?.total?.takeBackTime != 'number' && typeof this.groupedHours?.TOTAL?.total?.tbtWorkday != 'number') return false // No TBT hours registered
            return this.showTbTWorkWarning(this.groupedHours.TOTAL, this.expectedTBTWorkHours)
        },
        /**
         * Returns the number of weeks in the selected period
         */
         weeksCount() {
            if (!this.dates || !this.dates.length) return 0
            return Math.ceil(this.dates.length / 7)
        },
    },
    mounted() {
        EventBus.$on('edit-fitter-hour-modal-close', () => {
            this.editFitterHourModalOpen = false
            this.selectedFitterHour = null
        })
    }
}
</script>
<style scoped>
tr.off-hours {
    background-color: rgb(255 0 0 / 5%) !important;
}
tr.arbejdsfredag {
    background-color: rgb(3 123 228 / 5%) !important;
}
tr.take-back-time {
    background-color: rgb(0 193 0 / 5%) !important;
}
.editBtn {
    color: gray;
}
.editBtn:hover {
    color: black;
    cursor: pointer;
}
th.todayCell {
    border-top: 3px solid #007bff;
    border-left: 3px solid #007bff !important;
    border-right: 3px solid #007bff;
}
.todayCell {
    border-left: 3px solid #007bff;
    border-right: 3px solid #007bff;
}
.todayCell.bottomCell {
    border-bottom: 3px solid #007bff;
}
</style>