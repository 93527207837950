<template>
    <div>
        <div class="ui negative message">
            <div class="header">
                Warning!
            </div>
            <p>
                Modifies contacts and projects, will fundamentally change how a property is used.
            </p>
        </div>

        <strong>Description:</strong>
        <p>
            Will remove <sui-label>Company</sui-label> (Firebase ref) and replace it with <sui-label>Company</sui-label> (string) on all contacts and projects.
        </p>

        <hr />

        <sui-button primary size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerDry">Run Dry</sui-button>
        <sui-button negative size="tiny" :loading="isRunning" :disabled="isRunning" @click="triggerNormal">Run</sui-button>
    </div>
</template>
<script>
import { db } from '@/firebase.js'

export default {
    name: 'ContactsAndProjectRefs',

    data() {
        return {
            isRunning: false,
            dryRun: false,
        }
    },

    methods: {
        log(message) {
            this.$emit('log', message)
        },

        async triggerDry() {
            this.dryRun = true
            await this.trigger()
        },

        async triggerNormal() {
            this.dryRun = false
            await this.trigger()
        },

        async trigger() {
            this.isRunning = true
            this.log(`----------------`)
            this.log(`Starting process`)

            if (this.dryRun) {
                this.log(`Dry mode - No updates will actually occur.`)
            }

            this.log('Getting all contacts')
            let contacts = [];
            await db.collection('Contacts').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let contact = doc.data();
                    contact.id = doc.id;
                    contacts.push(contact);
                });
            });
            this.log(`Got ${contacts.length} contacts`);

            this.log('Looping through contacts');
            for (let contact of contacts){
                this.log(`Contact ID: ${contact.id}, Name: ${contact.Name}`);

                let companyRef = contact.Company
                console.log(companyRef)
                let doc = await companyRef.get();
                let refString = doc.ref.path.toString();
                refString = refString.split('/').pop();
                this.log(`Company ref: ${refString}`);
                delete contact.Company;
                contact.Company = refString;
                console.log('Updated Contact:', contact);

                if (!this.dryRun) {
                    await db.collection('Contacts').doc(contact.id).set(contact);
                }
            }

            this.log('----------------')
            this.log('Getting all projects')
            let projects = [];
            await db.collection('Projects').get().then((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    let project = doc.data();
                    project.id = doc.id;
                    projects.push(project);
                });
            });
            this.log(`Got ${projects.length} projects`);

            this.log('Looping through projects');
            for (let project of projects){

                for (let contact of project.Contacts){
                    this.log(`Project ID: ${project.id}, Contact ID: ${contact.Email}, Name: ${contact.Name}`);
                    let companyRef = contact.Company
                    console.log(companyRef)
                    let doc = await companyRef.get();
                    let refString = doc.ref.path.toString();
                    refString = refString.split('/').pop();
                    this.log(`Company ref: ${refString}`);
                    delete contact.Company;
                    contact.Company = refString;
                }
                console.log('Updated project contacts:', project.Contacts);
                if (!this.dryRun) {
                    await db.collection('Projects').doc(project.id).set(project);
                }
            }
            
            this.log('----------------')

            this.isRunning = false
        }
    }
}
</script>
<style scoped>
.float.right {
    float: right;
}
</style>