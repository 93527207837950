export const ScrollMixin = {
    methods: {
        
        scrollToRef(refName, behavior = 'smooth') {
            try {
                if (typeof refName != 'undefined') {
                        refName.scrollIntoView({ behavior })
                } else {
                    console.warn(`Reference ${refName} not found.`);
                }
            } catch(error) {
                console.error(`Error scrolling to ${refName}:`, error);
            }
        },
    }
}
