import swal from 'sweetalert'
import { db, auth, firestore, cloudFunctions } from '../../firebase'
import AppointmentState from '../Enums/AppointmentState'

export default class Firebase {

    THISROOT = null
    
    addMetadata(doc, docType, documentContainsPII) {
        let timestamp = new Date()
        timestamp = timestamp.toISOString()
        let metadata = {
            created: timestamp,
            createdBy: this.THISROOT.$children[0].userMeta,
            updated: timestamp,
            updatedBy: this.THISROOT.$children[0].userMeta,
            documentType: docType,
            documentContainsPII: documentContainsPII
        }
        doc.metadata = metadata
    }

    updateMetadata(doc) {  
        let timestamp = new Date()
        timestamp = timestamp.toISOString()

        doc['metadata.updatedBy'] = this.THISROOT.$children[0].userMeta
        doc['metadata.updated'] = timestamp
    }

    async getAPIData() {
        let apiData = await db.collection('API').doc('PilotBI').get()
        return apiData.data()
    }

    async getUser(useremail) {
        let response = await db.collection('Users').doc(useremail).get()
        return response.data()
    }

    async getAllProjects() {
        let projects = []
        let firebaseProjects = await db.collection(`Projects`).get()

        firebaseProjects.forEach(project => {
            let _obj = project.data()
            _obj.id = project.id
            projects.push(_obj)
        })

        return projects
    }

    async getProjectById(projectId) {
        let project = await db.collection('Projects')
            .doc(projectId)
            .get();
        
        if (!project.exists) {
            throw new Error(`No project with key ${projectId} was found.`)
        }

        let projectData = project.data()
        projectData.id = projectId

        return projectData
    }

    async getProjectByAreaCode(areaCode) {
        let project = await db.collection('Projects').where("AreaCodes", "array-contains", areaCode).get()
        if (project.docs.length) {
            project = await this.getProjectById(project.docs[0].id)
            return project
        }
        else {
            return {}
        }
    }

    // eslint-disable-next-line
    async getNotes(configurationItemLabel) {
        let Notes = await db.collection(`Notes`).where("configurationItemLabel","==",configurationItemLabel).get()
        //let noteArray = Notes._.docs
        let noteArray = []
        Notes.forEach(note => {
            let _obj = note.data()
            _obj.id = note.id
            noteArray.push(_obj)
        })
        return noteArray
    }

    async postNote(note) {
        let response = await db.collection(`Notes`).add(note)
        return response
    }

    async addOrUpdateFormToPdfTemplate(docID, data){
        if (docID) { // Update
            let docref = db.collection('FormToPdfTemplates').doc(docID)
            this.updateMetadata(data)

            let response = await docref
            .update(data)

            return response
        }

        this.addMetadata(data, {type: 'FormToPdfTemplate', friendlyFieldName: 'PDF Generator Template'}, false)
        let response = await db.collection('FormToPdfTemplates')
            .add(data)

        return response
    }

    async addOrUpdateFormToPdfForm(docID, data){
        if (docID) { // Update
            let docref = db.collection('FormToPdfForms').doc(docID)
            this.updateMetadata(data)

            let response = await docref.update(data)
            return response
        }

        this.addMetadata(data, {type: 'FormToPdfForm', friendlyFieldName: 'PDF Generator Form'}, true)
        let response = await db.collection('FormToPdfForms').add(data)

        return response
    }

    async deleteFormToPdfFormPermanently(docID){
        let response = await db.collection('FormToPdfForms').doc(docID).delete()
        return response
    }

    async addOrUpdateAppointment(appointmentId, data) {

        for (let i in Object.keys(data)) { //Loop through keys, and delete any that are undefined
            let key = Object.keys(data)[i]
            try {
                if (typeof data[key] === typeof undefined) {
                    const errorMessage = `Appointment data with key '${key}' was undefined, and will be ${appointmentId ? 'removed, to avoid overwriting valid data' : 'set to null, to avoid undefined error - important data might be missing!'}`
                    console.error(errorMessage)
                    throw new Error(errorMessage)
                }
            } catch (error) {
                if (appointmentId) {
                    delete data[key]
                } else {
                    data[key] = null
                }
            }
        }
        console.log(data)

        if (!data.ProjectInstallationType && !appointmentId) {
            swal('Kunne ikke gemme aftale','Den opgave du forsøger at lave en aftale på har tilsyneladende ingen projekttype. Den bliver derfor ikke gemt, da projekttypen skal bruges når aftalen skal hentes igen senere.\n\nI mange tilfælde kan problemet afhjælpes ved at opdatere siden','error')
            throw new Error('ProjectInstallationType is not set for appointment, so it will not be saved')
        }

        if (appointmentId) {
            delete data.LinkedProjects
            delete data.AreaCode
            delete data.ProjectInstallationType

            let docref = db.collection(`Appointments`).doc(appointmentId)
            this.updateMetadata(data)

            let response = await docref
            .update(data)

            return response
        }

        this.addMetadata(data, {type: 'appointment', friendlyFieldName: 'Aftale'}, true)
        let response = await db.collection(`Appointments`)
            .add(data)

        return response
    }

    async getAppointments(projectId) { //Might be obsolete? Has no state filter...
        let appointments = await db.collection(`Appointments`).where('LinkedProjects', 'array-contains', projectId).get()
        //if (appointments.empty) return null
        let appointmentArray = []
        appointments.forEach(a => {
            appointmentArray.push({
                id: a.id, 
                ...a.data()
            })
        })
        return appointmentArray
    }

    async getAppointmentsOnInst(instNumber) {
        let appointments = await db.collection('Appointments').where("InstallationLabel", "==", instNumber).get()
        let appointmentArray = []
        appointments.forEach(a => {
            appointmentArray.push({
                id: a.id, 
                ...a.data()
            })
        })
        return appointmentArray
    }

    async getClosedAppointmentsSince(projectId, since){
        let appointments = await db.collection(`Appointments`).where('LinkedProjects', 'array-contains', projectId).where('State','==','closed').where('TimeWindowString', '>=', since).get()
        let appointmentArray = []
        appointments.forEach(a => {
            appointmentArray.push({
                id: a.id, 
                ...a.data()
            })
        })
        return appointmentArray
    }

    // async getAppointmentsForInst(projectId, instNr) {
    //     let appointmnets = await db.collection(`Projects/${projectId}/Appointments`)
    //         .where('InstallationLabel', '==', instNr).get()
    //     if (appointmnets.empty) {
    //         return null
    //     }
    //     var appointmentArray = []
    //     appointmnets.forEach(app => {
    //         appointmentArray.push({
    //             id: app.id,
    //             ...app.data()
    //         })
    //     })
    // }
    
    async getAppointment(projectId, projectTask, appointmentType, onlyActive = true) {
        let taskObj = { ...projectTask }
        if (!Object.prototype.hasOwnProperty.call(projectTask, 'Code')) {
            taskObj = {
                Code: typeof projectTask.code == 'string' ? projectTask.code : 'TICKET',
                Id: projectTask.id,
                Number: projectTask.number
            }
            // console.log(taskObj)
        }
        let appointments = []
        let query = db.collection(`Appointments`)
            .where('ProjectTasks', 'array-contains', taskObj)
            .where('AppointmentType', '==', appointmentType)
        if (onlyActive) {
            query = query.where('State', '==', 'active')
        }
        let snapshot = await query.get()
        
        if (snapshot.empty) return null
        snapshot.forEach(doc => appointments.push(doc.data()))

        appointments = appointments.filter(a => {
            if (!a.LinkedProjects) return false
            return a.LinkedProjects.includes(projectId)
        })

        if (!appointments.length) return null
        return {
            id: snapshot.docs[0].id,
            ...appointments[0]
        }
    }

    // eslint-disable-next-line
    async getAppointmentById(projectId, appointmentId) {
        let appointment = await db.collection(`Appointments`)
            // .where('LinkedProjects', 'array-contains', projectId)
            .doc(appointmentId)
            .get()
        
        if (!appointment.exists) return null

        return {
            id: appointmentId,
            ...appointment.data()
        }
    }

    async getAppointmentByLabelAndProjectIdAndType(label, projectId, type) {
        let appointment = await db.collection('Appointments')
            .where('LinkedProjects', 'array-contains', projectId)
            .where('InstallationLabel', '==', label)
            .where('AppointmentType', '==', type)
            .get()

        if (!appointment.exists) return null
        return {
            id: appointment.id,
            ...appointment.data()
        }
    }
    
    async removeAppointment(appointmentId) {
        if (!appointmentId) {
            throw new Error('Cannot cancel appointment without id')
        }
        let response = await db.collection(`Appointments`)
            .doc(appointmentId)
            .update({
                'State': AppointmentState.CANCELED
            })
        return response
    }

    async getAllProjectCoordinates(projectId) {
        if (!projectId) {
            throw new Error('Cannot get all coordinates without projectId')
        }
        let coordinatesCollection = await db.collection(`Coordinates`)
            .where('LinkedProjects', 'array-contains', projectId)
            .get()

        let coordinatesMap = new Map()
        coordinatesCollection.forEach(coordinate => {
            const row = coordinate.data()
            coordinatesMap.set(row.InstallationLabel, { Lat: row.Lat, Lng: row.Lng })
        })

        return coordinatesMap
    }

    // eslint-disable-next-line
    async getCoordinates(label, projectId) {
        let coordinatesObj = await db.collection(`Coordinates`).doc(label).get()
        return coordinatesObj.data()
    }

    async saveCoordinates(resultsMap, projectId) {
        let results = Array.from(resultsMap, ([label, coords]) => ({ label, coords }))
        
        for (let i in results) {
            if (!results) {
                console.error('Results array is empty.')
                console.log(resultsMap)
            }
            const label = results[i].label
            const coords = results[i].coords

            if (!label) {
                throw new Error(`Cannot save coordinate result without label: ${JSON.stringify(results[i])}`)
            }

            if (!coords.lat || !coords.lng) {
                console.error(`Error in result (must contain both coords.lat and coords.lng):\n${JSON.stringify(results[i])}`)
                continue
            }

            let doc = await db.collection('Coordinates').doc(label).get()
            if (doc.exists) {
                let prevData = doc.data()

                if (prevData.LinkedProjects.includes(projectId)) {
                    console.error(`saveCoordinates: Should save projectID '${projectId}' to LinkedProjects prop for inst#${label}, but the value already exist, so writing is skipped`)
                    continue // Weird state??
                }

                await db.collection('Coordinates')
                    .doc(label)
                    .update({
                        LinkedProjects: [...prevData.LinkedProjects, projectId]
                    })

                continue
            }

            await db
                .collection(`Coordinates`)
                .doc(label)
                .set({
                    Lat: coords.lat,
                    Lng: coords.lng,
                    DataProvider: coords.provider,
                    DataProviderId: coords.id,
                    InstallationLabel: label,
                    LinkedProjects: [projectId],
                    ProjectInstallationType: coords.type,
                    AreaCode: coords.areaCode
                })
        }
    }

    async postFeedback(obj) {
        let user = await auth.currentUser;
        obj = {
            CreatedBy: user.email,
            ...obj
        }
        if(!obj) return null
        return db.collection('Feedback').add(obj)
    }

    async upvoteFeedback(feedbackObj){
        let user = await auth.currentUser;
        // console.log(user.email)
        // console.log(feedbackObj.id)
        await db.collection('Feedback').doc(feedbackObj.id).update({
            Votes: firestore.FieldValue.arrayUnion(user.email)
        })
    }

    async unvoteFeedback(feedbackObj){
        let user = await auth.currentUser;
        // console.log(user.email, feedbackObj.id)
        await db.collection('Feedback').doc(feedbackObj.id).update({
            Votes: firestore.FieldValue.arrayRemove(user.email)
        })
    }

    async postComment(feedbackId, comment){
        let user = await auth.currentUser;
        console.log(user)
        comment = {
            ...comment,
            CreatedBy: user.email
        }
        await db.collection('Feedback').doc(feedbackId).update({
            Comments: firestore.FieldValue.arrayUnion(comment)
        })
    }

    async updateFeedbackState(feedbackId, state){
        let response = await db.collection('Feedback').doc(feedbackId).update({
            State: state
        })
        return response
    }

    async addEmail(emailObj){
        if (emailObj.to && (emailObj.message || emailObj.template)){
            let response = await db.collection('SendgridEmail').add(emailObj)
            return response
        } else {
            console.error("Email object must contain 'to' and 'message'/'template' key")
            return null
        }
    }

    async addSMS(smsObj) {
        if (!smsObj.To || (!smsObj.Message && !smsObj.Template)) {
            throw new Error('SMS must contain `to` and either `message` or `template` properties.')
        }

        let response = await db.collection('SMSMessages').add({ Request: smsObj })
        return response
    }

    async createNewHub(hubObj) {
        let response = await db.collection('HUBs').doc(hubObj.id).set(hubObj)
        return response
    }
    
    async getContactsFromCompany(companyName) {
        if (!companyName) throw new Error('Company name must be provided.')
        // let companyReference = db.collection('Companies').doc(companyName)

        let contacts = []
        let contactsDB = await db.collection('Contacts').where('Company', '==', companyName).get()
        contactsDB.forEach(c => {
            contacts.push(c.data())
        })

        return contacts
    }

    async addOrUpdateWorkDay(workDay, projectId) {
        if (!workDay.Date){
            throw new Error('workDay must contain Date, as Date is the key value')
        }
        let response = await db.collection('Projects').doc(projectId).collection('WorkDays').doc(workDay.Date).set(workDay)
        return response
    }

    async addOrUpdateUnitWork(unitWorkDocument, unitWorkId){
        let response
        if (unitWorkId){
            response = await db.collection('UnitWork').doc(unitWorkId).update(unitWorkDocument)
        } else {
            response = await db.collection('UnitWork').add(unitWorkDocument)
        }
        return response
    }

    async getUnitWorkForInst(instLabel){
        if (!instLabel) return [] //This might be bad practice - letting firebase throw an error below might be better, idk
        let response = await db.collection('UnitWork').where('ConfigurationItem.Label','==',instLabel).get()
        let unitWork = []
        response.forEach(uw => {
            unitWork.push({
                id: uw.id,
                ...uw.data()
            })
        })
        return unitWork
    }

    async deleteUnitWork(unitWork){
        let response = await db.collection('UnitWork').doc(unitWork.id).delete()
        return response
    }

    async getSubtasksForInst(instLabel){
        if (!instLabel) return []
        let response = await db.collection(`InternalSubTasks`).where('InstallationLabel', '==', instLabel).get()
        let subtasks = []
        response.forEach(uw => {
            subtasks.push({
                id: uw.id,
                ...uw.data()
            })
        })
        return subtasks
    }

    async genericUpdateDocument(collection, docID, doc){
        let response = await db.collection(collection).doc(docID).update(doc)
        return response   
    }

    async saveUnitWorkOutputSettings(presetId, settingsObj){
        let response = await db.collection('UnitWorkOutputNoteSettings').doc(presetId, settingsObj).set({
            id: presetId,
            settings: settingsObj
        })
        return response
    }

    //Revoke users refresh token, using the callable cloud function 'revokeToken'
    async revokeRefreshToken(UID){
        let response = await cloudFunctions.httpsCallable('revokeToken')({uid: UID})
        return response
    }
}
