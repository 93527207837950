<template>
    <div>
        <!-- <portal to="page-title">{{ this.project.Name }}</portal> -->

        <div class="row" v-if="this.project">
            <div class="col-sm-8">
                <DataCard title="Detaljer" :subtitle="ProjectStatus.getValueFromCode(project.Status)">
                        <p>Her kommer et overblik over projektet, med følgende data:</p>
                        <ul>
                            <li>Samlet antal kunder på projekt</li>
                            <li>Status på kunder (CPE monteret antal x/y)</li>
                            <li>Antal kunder klar til blæsning</li>
                            <li>Antal kunder klar til installation</li>
                            <li>Antal planlagte installationer (evt Planlagt/Klar/Total)</li>
                            <li>Dato for næste kunde-installation</li>
                            <li>Første ledige tid</li>
                            <li>Dato for seneste opgave-afslutning</li>
                        </ul>
                </DataCard>
            </div>
            <div class="col-sm-4">
                <div style="padding-bottom: 10px;" v-if="userHasLeadPermissions">
                    <sui-button content="Redigér projekt" icon="edit" label-position="left" @click="editProject"/>
                </div>
                <DataCard v-if="!containsMultipleProjects" title="Kontaktpersoner" no-padding>
                    <sui-table class="workers-table">
                        <sui-table-row v-for="(contact, index) in project.Contacts" :key="`c-${index}`">
                            <sui-table-cell v-if="contact">
                                <TableWorker
                                    type="contact"
                                    :worker="contact"
                                    :role="contact.Roles || null"
                                />
                            </sui-table-cell>
                        </sui-table-row>
                    </sui-table>
                </DataCard>
            </div>
        </div>
        <div class="row">
            <div class="col-sm-4">
                <DataCard title="Omr&aring;der" no-padding overflow-x>
                    <sui-table>
                        <sui-table-header>
                            <sui-table-row>
                                <sui-table-header-cell>SonWinID</sui-table-header-cell>
                                <sui-table-header-cell>Omr&aring;de</sui-table-header-cell>
                            </sui-table-row>
                        </sui-table-header>
                        <sui-table-body>
                            <sui-table-row v-for="(area, index) in uniqueAreas" :key="index">
                                <sui-table-cell>{{ area.id }}</sui-table-cell>
                                <sui-table-cell>{{ area.name }}</sui-table-cell>
                            </sui-table-row>
                        </sui-table-body>
                    </sui-table>
                </DataCard>
            </div>
            <div class="col-sm-4">
                <DataCard title="Skabe">
                    <p>Her kommer en oversigt over skabe, med tilhørende statusser:</p>
                    <ul>
                        <li>Skab sat</li>
                        <li>Kabel blæst</li>
                        <li>Budi monteret</li>
                        <li>Kabel ført ind</li>
                        <li>Videresplidsning OK (ind og ud) (antal?)</li>
                        <li>Kundefiber blæst (blæst/gravet/tilmeldt)</li>
                        <li>Kundefiber splidset (evt splidset/blæst/gravet/tilmeldt) </li>
                    </ul>
                    <p>Flere statusser er afhængige af hinanden, hvorfor der kan være redundante statusser, som ikke skal vises sammen</p>
                </DataCard>
            </div>
            <div class="col-sm-4">
                <DataCard title="Medarbejdere" no-padding>
                    <sui-table v-if="Object.values(workers).length" class="workers-table">
                        <sui-table-row v-for="(worker, index) in project.Workers" :key="index">
                            <sui-table-cell>
                                <TableWorker
                                    v-if="workers[lowercase(worker.Email)]"
                                    type="worker"
                                    :worker="workers[lowercase(worker.Email)]"
                                />
                                <TableWorker
                                    v-else
                                    :worker="{ Name: 'Ukendt (Ikke fundet)', Email: worker.Email }"
                                    type="worker"
                                />
                            </sui-table-cell>
                        </sui-table-row>
                    </sui-table>
                    <div v-else>
                        <TableLoader />
                    </div>
                </DataCard>
            </div>
        </div>

    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DataAPI } from '@/lib/DataAPI.js'
import { db } from '@/firebase.js'
import ProjectStatus from '@/lib/Enums/ProjectStatus.js'
import TableLoader from '@/components/TableLoader.vue'
import TableWorker from '@/components/Worker/TableWorker.vue'
import EventBus from '@/EventBus.js'
import UserRoles from '../../lib/Enums/UserRoles'


export default {
    name: 'ProjectDetailView',
    mixins: [Mixin, DataAPI],

    components: {
        TableLoader,
        TableWorker
    },

    enums: {
        ProjectStatus,
        UserRoles
    },

    data() {
        return {
            apiWorkers: [],
            firWorkers: [],
            projectTasks: []
        }
    },

    computed: {
        ...mapGetters({
            rawProject: 'activeProject'
        }),

        containsMultipleProjects() {
            return !Object.prototype.hasOwnProperty.call(this.rawProject, 'id')
        },

        project() {
            if (!this.containsMultipleProjects) {
                return this.rawProject
            }

            // If the rawProject does not have a length property, it means that the values are not loaded yet.
            if (!Object.prototype.hasOwnProperty.call(this.rawProject, 'length')) {
                return []
            }

            return {
                SonWinIDs: this.mergeObjectsOnPropByValue(this.rawProject, 'SonWinIDs'),
                Workers: this.mergeObjectsOnPropByKey(this.rawProject, 'Workers', 'Email'),
                Name: 'Alle Projekter',
                id: 'all'
            }
        },

        workers() {
            if (this.firWorkers.length == 0) return []
            const initialValue = {}
            return this.firWorkers.reduce((obj, user) => {
                return {
                    ...obj,
                    [String(user['Email']).toLowerCase()]: user,
                }
            }, initialValue)
        },

        uniqueAreas() {
            let areas = []
            for (let i in this.project.AreaCodes){
                areas.push({
                    id: this.project.AreaCodes[i],
                    name: null //TODO: Get area name from API
                })
            }
            return areas
        },

        firebaseUser() {
            return this.$parent.$parent.firebaseUser
        },

        userHasLeadPermissions() {
            let user = this.firebaseUser
            // console.log(JSON.stringify(user))
            if(user && user.Roles && user.Roles.includes(UserRoles.EFB_PROJECT_EDITOR)) return true //Admins always have lead permissions
            if (!this.project || !this.project.Contacts) return false
            let userContact = this.project.Contacts.find(contact => contact.Email == user.email) //Find user in project contacts
            if (!userContact) return false //if user is not in project contacts, they cannot be project lead
            if (userContact.Roles.includes('ProjectLead')) return true //Allow lead permissions for project lead
            return false
        },
    },

    methods: {
        generateStyleColorsFromName(name) {
            const imageData = this.generateUserProfileImageFromName(name)
            return {
                backgroundColor: imageData.bgColor,
                color: imageData.fgColor
            }
        },

        async update() {
            EventBus.$emit('function-activity', {functionName: 'Project/Detail_update', isActive: true})
            this.projectTasks = []
            let projects = await this.dataGetProjectTasks({
                areaCodes: this.project.SonWinIDs, 
                projectTypes: this.project.Type,
            })
            this.projectTasks = projects.projectTasks
            EventBus.$emit('function-activity', {functionName: 'Project/Detail_update', isActive: false})
        },

        formatContacts() {
            if (this.rawProject.Contacts && this.rawProject.Contacts.length) {
                for (const i in this.rawProject.Contacts) {
                    if (!this.rawProject.Contacts[i].Company) continue;

                    let company = this.rawProject.Contacts[i].Company
                    db.collection('Companies').doc(company).get()
                        .then(res => {
                            this.rawProject.Contacts[i].Company = res.data();
                        });
                }
            }
        },

        editProject() {
            this.$router.push(`/projects/edit/${this.project.id}`)
        },
    },

    async created() {
        EventBus.$emit('function-activity', {functionName: 'Project/Detail_created', isActive: true})

        this.$bind('firWorkers', db.collection('Users'))
        // this.apiWorkers = await this.dataGetWorkers()

        // let projects = await this.dataGetProjectTasks(this.project.SonWinIDs, this.project.Type)
        // this.projectTasks = projects.projectTasks
        EventBus.$emit('function-activity', {functionName: 'Project/Detail_created', isActive: false})
    },

    mounted() {
        document.title = `${this.project.Name} - FiberTeam`
    },

    watch: {
        'project': {
            immediate: false,
            handler(){
                this.update()
                document.title = `${this.project.Name} - FiberTeam`
            }
        },
        'rawProject': 'formatContacts'
    }
}
</script>
<style scoped>
.workers-table td {
    padding-left: 1.5rem !important;
}
</style>
