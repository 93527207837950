<template>
    <div class="row" ref="topOfPage">
        <portal to="page-actions">
            <div style="zoom: 0.85">
                <span v-if="currentMedia.type != 'Mobile'" style="float: right; margin-left: 20px;">
                    <i class="fa-kit fa-layout-3-3-6" :class="layoutButtonSelected('layout0')" @click="layoutButtonClicked('layout0')" title="Vælg layout"></i>
                    <i class="fa-kit fa-layout-4-4-4" :class="layoutButtonSelected('layout1')" @click="layoutButtonClicked('layout1')" title="Vælg layout"></i>
                    <i class="fa-kit fa-layout-2x2" :class="layoutButtonSelected('layout2')" @click="layoutButtonClicked('layout2')" title="Vælg layout"></i>
                </span>
                <sui-button v-if="showSettingsButton"
                    floated="right"
                    class="labeled icon button"
                    icon="cog"
                    @click="onEditSettingsButtonClicked()"
                >
                    Indstillinger
                </sui-button>
                <sui-button v-if="showSettingsButton"
                    floated="right"
                    title="Åben Afbudsliste"
                    style="padding-right: 12px; padding-left: 12px;"
                    @click="showCancellationListModal = true"
                >
                    <i class="fa-kit fa-solid-list-clock"></i>
                </sui-button>

                <sui-button v-if="settingsBD.get('showSearchInVirkplan')"
                    floated="right"
                    title="Skriv installationsnummer i søgefeltet og søg i Virkplan"
                    style="padding-right: 12px; padding-left: 12px;"
                    @click="getInstallationByNumber()"
                >
                    <i class="fa-solid fa-magnifying-glass-plus"></i>
                    Søg i Virkplan
                </sui-button>
                
                <template v-if="settingsBD.get('showClosed')">
                    <label>Afsluttede siden: </label>
                    <sui-input type="date" v-model="closedSinceInput" />
                    <sui-button icon="download" color="green" :disabled="closedSince == closedSinceInput" :loading="getClosedLoading" @click="closedSince = closedSinceInput"/>
                </template>
            </div>
        </portal>

        <state-change-modal 
            :isOpen="stateModalOpen" 
            :serviceOrder="activeServiceOrder" 
            :task="activeTask" 
            :projectProp="project" 
            :installationLabel="activeInstallationLabel" 
            :technicalData="activeTechnicalData" 
            :availableWorkers="availableWorkers"
            :relevantAppointments="taskAppointments"
        />

        <sui-modal small v-model="noteModalOpen">
            <sui-modal-header>
                Ny Note
            </sui-modal-header>
            <sui-modal-content>
                <sui-form :success="noteFormSuccess" @submit.prevent="submitNote" :loading="noteFormLoading">
                    <sui-form-fields inline>
                        <label>Intern eller ekstern note?</label>
                        <sui-form-field>
                            <sui-checkbox label="Intern" radio value="Intern" v-model="noteType"/>
                        </sui-form-field>
                        <sui-form-field>
                            <sui-checkbox label="Ekstern" radio value="Ekstern" v-model="noteType"/>
                        </sui-form-field>
                    </sui-form-fields>
                    <sui-message> Eksterne noter skrives på sagen i PilotBI, Interne noter kan kun ses gennem ftth.fiberlan.dk <br> Der skrives automatisk navn på alle noter <br> Noter der er oprettet kan ikke slettes </sui-message>
                    <sui-message v-if="noteType == 'Ekstern'" class="ui negative message"> Eksterne noter kan ses af vores samarbejdspartnere, derfor må de ikke indeholde person data <br> Telefonnumre mv. på kunder skal skrives i interne noter</sui-message>
                    <sui-form-field>
                        <label>Note tekst</label>
                        <textarea rows="5" v-model="noteText"></textarea>
                    </sui-form-field>
                    <sui-button type="submit" :disabled="!noteType">Gem</sui-button>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <sui-modal small v-model="CAnoteModalOpen">
            <sui-modal-header>
                Ny Note
            </sui-modal-header>
            <sui-modal-content>
                <sui-form :success="CAnoteFormSuccess" @submit.prevent="submitCANote" :loading="CAnoteFormLoading">
                    <sui-message> Der skrives automatisk navn på alle noter <br> Noter der er oprettet kan ikke slettes </sui-message>
                    <sui-form-field>
                        <label>Note tekst</label>
                        <textarea rows="5" v-model="CAnoteText"></textarea>
                    </sui-form-field>
                    <sui-button type="submit" :disabled="!CAnoteText">Gem</sui-button>
                </sui-form>
            </sui-modal-content>
        </sui-modal>

        <sui-modal small v-model="uploadFileModalOpen">
            <sui-modal-header>
                Vedhæft fil
            </sui-modal-header>
            <sui-modal-content>
                <sui-form :success="fileFormSuccess" @submit.prevent="submitFile" :loading="fileFormLoading">
                    <sui-form-field>
                        <label>Navngiv den uploadede fil</label>
                        <input type="text" placeholder="FilNavn" v-model="fileNameInput"/>
                    </sui-form-field>
                    <sui-form-field>
                        <input type="file" @change="setFileUpload"/>
                    </sui-form-field>
                    <sui-message> Uploadede filer sendes til PilotBI </sui-message>
                    <sui-button type="submit" :disabled="!fileUpload">Gem</sui-button>
                    <sui-message success>Fil sendt til PilotBI</sui-message>
                </sui-form>
            </sui-modal-content>
        </sui-modal>
        <file-viewer-modal
            :openFile="openFile"
            :isOpen="openFileModalOpen"
            :openFileLoading="openFileLoading"
        />
        <sms-modal title="SMS" :isOpen="smsModalOpen" :phoneNumber="activePhoneNumber" />
        
        <splice-reports-download-modal :activeItem="downloadReportActiveItem" :isOpen="downloadReportModalOpen"/>
        
        <edit-tech-data-modal 
            v-if="activeInstallationData" 
            :isOpen="editTechDataModalOpen" 
            :task="activeInstallationData.tasks[0]" 
            :installationLabel="activeInstallationLabel"
        />
        
        <edit-unit-work-modal 
            v-if="activeInstallationData" 
            :isOpen="editUnitWorkModalOpen" 
            :task="activeInstallationData.tasks[0]"
            :availableWorkers="availableWorkers"
            :user="user"
            :userHasLeadPermissions="userHasLeadPermissions"
            :beforeChange="activeUnitWork"
            :unitWorkId="activeUnitWork.id"
            :preFilledDescription="preFilledUnitWorkDescription"
        />

        <edit-custom-appointment-modal
            :isOpen="editCustomAppointmentModalOpen"
            :customAppointment="activeInstallationData ? activeInstallationData.appointment : null"
            :customAppointmentId="activeInstallationData ? activeInstallationData.label : null"
            :allProjects="allProjects"
        />

        <edit-settings-modal
            :isOpen="editSettings"
            :project="project"
            :settings="settingsBD"
            :hasProjectTasks="loadProjectTasks"
            :hasTroubleTickets="loadTickets"
            page="booking dashboard"
        />

        <!-- LEFT COLUMN -->
        <div :class="getColumnWidth.dataCard">
            <!-- Scheduled appointments -->
            <DataCard 
                :title="scheduledHeader()" 
                :subtitle="bookedInstallationsLength" 
                success 
                outline 
                no-padding 
                :class="getHeightBooked" 
                :actions="[{type: 'button', title: 'Tilføj Fritekst', icon: 'plus', eventName: 'add-custom-appointment-button'}]"
            >
                <div class="scrollable">
                <!-- <div> -->
                    <ProjectTimeline
                        :itemClickHandlerType="BookingItemHandlerType.SEND_EVENT"
                        :bookings="bookedInstallations"
                        :loading="false"
                        :showBookingData="true"
                        :settings="settingsBD"
                        :enableMultiSelect="false"
                    />
                </div>
            </DataCard>

            <!-- Non scheduled appointments -->
            <DataCard 
                :title="nonScheduledHeader()" 
                :subtitle="availableInstallationsLength" 
                violet 
                outline 
                no-padding 
                class="half-height" 
                v-if="settingsBD.get('showUnbookedCard') && getLayoutBD == 'layout2'"
            >
                <div class="scrollable">
                    <TableLoader v-if="loadingUnBookedTimeline" :overlay="true" />
                    <!-- Tickets -->
                    <ProjectTimeline
                        v-if="availableInstallations[0]"
                        :itemClickHandlerType="BookingItemHandlerType.SEND_EVENT"
                        :bookings="availableInstallations[0]"
                        :loading="loadingUnBookedTimeline"
                        :showBookingData="false"
                        :showDueDate="true"
                        :settings="settingsBD"
                        :disableLoadingAnimation="true"
                        :enableMultiSelect="false"
                    />
                    <!-- Project Tasks -->
                    <ProjectTimeline
                        v-if="availableInstallations[1]"
                        :itemClickHandlerType="BookingItemHandlerType.SEND_EVENT"
                        :bookings="availableInstallations[1]"
                        :loading="loadingUnBookedTimeline"
                        :showBookingData="false"
                        :showDueDate="true"
                        :settings="settingsBD"
                        :disableLoadingAnimation="true"
                        :enableMultiSelect="false"
                    />
                </div>
            </DataCard>

        </div>

        <!-- RIGHT COLUMN -->
        <div :class="getColumnWidth.dataCard">
            <!-- Non scheduled appointments -->
            <DataCard 
                :title="nonScheduledHeader()" 
                :subtitle="availableInstallationsLength" 
                violet 
                outline 
                no-padding 
                :class="getHeightUnbookedRightColumn"
                v-if="settingsBD.get('showUnbookedCard') && getLayoutBD != 'layout2'"
            >
                <div class="scrollable">
                    <TableLoader v-if="loadingUnBookedTimeline" :overlay="true" />
                    <!-- Tickets -->
                    <ProjectTimeline
                        v-if="availableInstallations[0]"
                        :itemClickHandlerType="BookingItemHandlerType.SEND_EVENT"
                        :bookings="availableInstallations[0]"
                        :loading="loadingUnBookedTimeline"
                        :showBookingData="false"
                        :showDueDate="true"
                        :settings="settingsBD"
                        :disableLoadingAnimation="true"
                        :enableMultiSelect="false"
                    />
                    <!-- Project Tasks -->
                    <ProjectTimeline
                        v-if="availableInstallations[1]"
                        :itemClickHandlerType="BookingItemHandlerType.SEND_EVENT"
                        :bookings="availableInstallations[1]"
                        :loading="loadingUnBookedTimeline"
                        :showBookingData="false"
                        :showDueDate="true"
                        :settings="settingsBD"
                        :disableLoadingAnimation="true"
                        :enableMultiSelect="false"
                    />
                </div>
            </DataCard>

            <!-- Active Installation -->
            <div ref="activeInstallationCard">
                <DataCard
                    no-padding
                    primary
                    outline
                    :title="(activeInstallationData && activeInstallationData.appointment && activeInstallationData.appointment.AppointmentType && activeInstallationData.appointment.AppointmentType == AppointmentType.CUSTOM) ? 
                        activeInstallationData.appointment.Title : 
                        activeInstallationAddress"
                    :actions="(activeInstallationData && activeInstallationData.appointment && activeInstallationData.appointment.AppointmentType && activeInstallationData.appointment.AppointmentType == AppointmentType.CUSTOM) ? 
                        [{ type: 'button', title: 'Redigér aftale', icon: 'edit', eventName: 'edit-custom-appointment-button' }] :
                        activeInstallationData ?
                        [
                            { type: 'button', title: 'Genindlæs', icon: 'fas fa-sync', eventName: 'reload-inst-button', spinning: instDataReloadIconSpinning},
                            { type: 'button', title: 'Check Installationen', icon: 'fa-info', eventName: 'portCheck-button'},
                            { type: 'dropdown', title: 'Aftaler', icon: 'calendar plus outline', options: activeAppointmentOptions, eventName: 'book-button' },
                        ] :
                        []
                    "
                    :class="getHeightActiveInstRightColumn"
                    v-if="(this.settingsBD.get('showMap') && getLayoutBD == 'layout1') || getLayoutBD == 'layout0' || getLayoutBD == 'layout2'"
                >
                    <div v-if="loadingInstallationData">
                        <TableLoader/>
                    </div>
                    <!-- CUSTOM APPOINTMENT -->
                    <div class="scrollable" v-else-if="activeInstallationData && activeInstallationData.appointment && activeInstallationData.appointment.AppointmentType && activeInstallationData.appointment.AppointmentType == AppointmentType.CUSTOM">
                        <ActiveInstallationCustom
                            :activeInstallationData="activeInstallationData"
                            :loadingInstallationData="loadingInstallationData"
                            :allProjects="allProjects"
                        />
                    </div>      
                    <!-- >PROJECT TASK OR TROUBLE TICKET -->     
                    <div class="scrollable" v-else>
                        <sui-dimmer :active="loadingInstallationData">
                            <multi-step-loader class="content" :steps="installationDataLoadingSteps" />
                        </sui-dimmer>
                        <ActiveInstallation
                            :activeInstallationLabel="activeInstallationLabel"
                            :activeInstallationData="activeInstallationData"
                            :loadingInstallationData="loadingInstallationData"
                            :appointments="appointments"
                            :activeHub="activeHub"
                            :activeServiceProvider="activeServiceProvider"
                            :loadingProducts="loadingProducts"
                            :loadingTechnicals="loadingTechnicals"
                            :firstProductDeliveryDate="firstProductDeliveryDate"
                            :internalSubTasks="internalSubTasks"
                            :activeInstallationUnitWork="activeInstallationUnitWork"
                            :productError="productError"
                            :firebaseUser="firebaseUser"
                            :settings="settingsBD"
                        />
                    </div>
                </DataCard>
            </div>

            <!-- Map -->
            <DataCard no-padding class="half-height map-card" v-if="this.settingsBD.get('showMap') && (getLayoutBD === 'layout2')">
                <OKAPIMap
                    :mapMarkers="mapMarkers"
                />
            </DataCard>
        </div>

        <div :class="getColumnWidth.map">
            <DataCard no-padding class="full-height map-card" v-if="this.settingsBD.get('showMap') && (getLayoutBD != 'layout2')">
                <OKAPIMap
                    :mapMarkers="mapMarkers"
                />
            </DataCard>

            <!-- Active Installation -->
            <div>
                <DataCard
                    no-padding
                    primary
                    outline
                    :title="(activeInstallationData && activeInstallationData.appointment && activeInstallationData.appointment.AppointmentType && activeInstallationData.appointment.AppointmentType == AppointmentType.CUSTOM) ? 
                        activeInstallationData.appointment.Title : 
                        activeInstallationAddress"
                    :actions="(activeInstallationData && activeInstallationData.appointment && activeInstallationData.appointment.AppointmentType && activeInstallationData.appointment.AppointmentType == AppointmentType.CUSTOM) ? 
                        [{ type: 'button', title: 'Redigér aftale', icon: 'edit', eventName: 'edit-custom-appointment-button' }] :
                        activeInstallationData ?
                        [
                            { type: 'button', title: 'Genindlæs', icon: 'fas fa-sync', eventName: 'reload-inst-button', spinning: instDataReloadIconSpinning},
                            { type: 'button', title: 'Check Installationen', icon: 'fa-info', eventName: 'portCheck-button'},
                            { type: 'dropdown', title: 'Aftaler', icon: 'calendar plus outline', options: activeAppointmentOptions, eventName: 'book-button' },
                        ] :
                        []
                    "
                    class="full-height active-installation-box"
                    v-if="!this.settingsBD.get('showMap') && getLayoutBD == 'layout1'"
                >
                    <div v-if="loadingInstallationData">
                        <TableLoader/>
                    </div>
                    <!-- CUSTOM APPOINTMENT -->
                    <div class="scrollable" v-else-if="activeInstallationData && activeInstallationData.appointment && activeInstallationData.appointment.AppointmentType && activeInstallationData.appointment.AppointmentType == AppointmentType.CUSTOM">
                        <ActiveInstallationCustom
                            :activeInstallationData="activeInstallationData"
                            :loadingInstallationData="loadingInstallationData"
                            :allProjects="allProjects"
                        />
                    </div>      
                    <!-- >PROJECT TASK OR TROUBLE TICKET -->     
                    <div class="scrollable" v-else>
                        <sui-dimmer :active="loadingInstallationData">
                            <multi-step-loader class="content" :steps="installationDataLoadingSteps" />
                        </sui-dimmer>
                        <ActiveInstallation
                            :activeInstallationLabel="activeInstallationLabel"
                            :activeInstallationData="activeInstallationData"
                            :loadingInstallationData="loadingInstallationData"
                            :appointments="appointments"
                            :activeHub="activeHub"
                            :activeServiceProvider="activeServiceProvider"
                            :loadingProducts="loadingProducts"
                            :loadingTechnicals="loadingTechnicals"
                            :firstProductDeliveryDate="firstProductDeliveryDate"
                            :internalSubTasks="internalSubTasks"
                            :activeInstallationUnitWork="activeInstallationUnitWork"
                            :productError="productError"
                            :firebaseUser="firebaseUser"
                            :settings="settingsBD"
                        />
                    </div>
                </DataCard>
            </div>
        </div>

        <SubtaskStateChangeForm 
            :isOpen="showSubtaskModal"
            :modalTitle="activeSubtask.name"
            :activeSubtask="activeSubtask"
            :instLabel="activeInstallationLabel"
            :project="project"
            :workerData="availableWorkersData"
            :subtaskModalLoading="subtaskModalLoading"
            :firebaseSubTasks="internalSubTasks[activeInstallationLabel]"
            :user="user"
            :activeInst="activeInstallationData"
            :ignoreLeadPermissions="ignoreLeadPermissions"
            :activeSubtaskValue="activeSubtaskValue"
        />

        <div class="booking-mini-modal" v-if="showBookingModal">
            <DataCard class="scrollable">
                <BookingFormModal 
                    v-if="activeInstallationData && bookingFormData.label"
                    :installationData="activeInstallationData"
                    :bookingDataProp="bookingFormData"
                    :workers="availableWorkers"
                    :loading="loadingSaveBooking"
                    :settings="settingsBD"
                />
                <sui-dimmer :active="loadingSaveBooking">
                    <multi-step-loader
                        class="content"
                        :steps="saveBookingLoadingSteps"
                    />
                </sui-dimmer>
            </DataCard>
        </div>

        <portal to="semantic-ui-modal">
            <sui-modal small v-model="showCoordinateImportModal" @displayChanged="checkMissingCoordinateStateForModal">
                <sui-modal-header>Manglende geo koordinater</sui-modal-header>
                <sui-modal-content>

                    <div v-if="coordinateImportStage == 0">
                        <p>Der er fundet <strong>{{ installationMissingCoordinates.length }}</strong> installationer hvor der mangler geo koordinater på, dette er et krav for systemet og du bedes derfor lade systemet importere disse.</p>
                        <p>Den estimerede import tid er: <strong>{{ estimatedCoordinatesImportTime }} sekunder</strong></p>
                        <p></p>
                        <p>Du kan også vælge at vente til senere, dog vil booking siden have begrænset funktionalitet.</p>
                    </div>
                    <div v-if="coordinateImportStage == 1">
                        <strong>Henter koordinater.. {{ parseInt(100 / Math.min(installationMissingCoordinates.length / installationMissingCoordinatesIteratorIndex, 100)) }}%</strong>
                        <sui-progress
                            :percent="parseInt(100 / Math.min(installationMissingCoordinates.length / installationMissingCoordinatesIteratorIndex, 100))"
                            indicating
                            state="active"
                        />


                        Estimeret tid tilbage: {{ estimatedCoordinatesImportTimeLeft }} sekunder.
                    </div>
                    <div v-if="coordinateImportStage == 2">
                        <div v-if="!failedInstallationMissingCoordinates.length" class="ui success message">
                            <h4>
                                Hentede {{ installationMissingCoordinates.length - failedInstallationMissingCoordinates.length }} 
                                af {{ installationMissingCoordinates.length }}
                            </h4>
                            <p>Du kan nu lukke denne popup og bruge siden.</p>
                            <p>Tid brugt: {{ parseInt(coordinateImportTime) }} sekunder.</p>
                        </div>

                        <div v-else>
                            <div class="ui warning message">
                                <h4>
                                    Hentede {{ installationMissingCoordinates.length - failedInstallationMissingCoordinates.length }} 
                                    af {{ installationMissingCoordinates.length }}
                                </h4>
                                <p>Desværre opstod der fejl ved en eller flere. Prøv venligst at genindlæse siden eller kontakt IT</p>
                                <p>Tid brugt: {{ parseInt(coordinateImportTime) }} sekunder.</p>
                            </div>

                            <h4>Fejlede:</h4>
                            <pre class="failed-coordinate-imports">{{ failedInstallationMissingCoordinates }}</pre>
                        </div>
                    </div>

                </sui-modal-content>
                <sui-modal-actions>
                    <sui-button v-if="coordinateImportStage == 0" @click.native="showCoordinateImportModal = false">
                        Senere
                    </sui-button>
                    <sui-button v-if="coordinateImportStage == 0" primary @click.native="startCoordinateImport">
                        Start import
                    </sui-button>
                    <sui-button v-if="coordinateImportStage == 1" negative @click.native="showCoordinateImportModal = false">
                        Afbryd
                    </sui-button>
                    <sui-button v-if="coordinateImportStage == 2" primary @click.native="showCoordinateImportModal = false">
                        Færdig
                    </sui-button>
                </sui-modal-actions>
            </sui-modal>

            <sui-modal 
                small 
                :open="openSelectAppointmentToEdit"
                v-on:clickAwayModal="openSelectAppointmentToEdit = false"
            >
                <sui-modal-header>
                    Vælg aftale
                </sui-modal-header>
                <sui-modal-content>
                    <sui-form>
                        <sui-form-field>
                        <label>Aftale</label>
                        <sui-dropdown
                            placeholder="Aftale"
                            selection
                            :options="appointmentOptionsNoNew"
                            v-model="editSelectedAppointmentId"
                        />
                        </sui-form-field>
                        <sui-form-field style="margin-top: 50px;"></sui-form-field>
                    </sui-form>
                </sui-modal-content>
                <sui-modal-actions>
                <sui-button  
                    @click.prevent="openSelectAppointmentToEdit = false"
                >
                    Annullér
                </sui-button>
                <sui-button  
                    @click="onBookButtonClick(editSelectedAppointmentId), openSelectAppointmentToEdit = false"
                >
                    Vælg
                </sui-button>
            </sui-modal-actions>
            </sui-modal>

            <sui-modal small v-model="coordinateImportErrorPopup">
                <sui-modal-header>
                    Kunne ikke gemme koordinater
                </sui-modal-header>
                <sui-modal-content>
                    I forsøget på at gemme koordinater på kunderne i Firebase er der sket {{coordinateImportErrorCount}} fejl.
                    Seneste fejlmeddelelse:
                    <pre>{{coordinateImportErrorMessage}}</pre>
                </sui-modal-content>
                <sui-modal-actions>
                    <sui-button @click="coordinateImportErrorPopup = false">
                        Luk
                    </sui-button>
                </sui-modal-actions>
            </sui-modal>     
            
            <sui-modal small v-model="migrateData">
                <sui-modal-header>
                    Migration af data til dette projekt
                </sui-modal-header>
                <sui-modal-content>
                    <sui-progress state="active" indicating :percent="migrationProgressPercent"/>
                    <label> {{ migrationProgressBarLabel }} </label>
                </sui-modal-content>
            </sui-modal>

            <RemoveAppointmentModal
                :isOpen="showRemoveAppointmentModal"
                :installation="activeInstallationData"
                :appointmentIdProp="selectedAppointmentId"
            />

            <FailedContactModal
                :isOpen="failedContactModalOpen"
                :contacts="activeContacts"
                :installation="activeInstallationData || {}"
            />

            <port-check-modal
                v-if="portCheckModalOpen"
                :isOpen="portCheckModalOpen"
                :installationLabel="activeInstallationLabel"
            />

            <EditAppointmentState
                :isOpen="showEditAppointmentStateModal"
                :appointmentID="selectedAppointmentId"
            />

            <CancellationListModal
                :isOpen="showCancellationListModal"
                :availableInsts="availableInstallations"
                :bookedInsts="bookedInstallations"
                :settings="settingsBD"
            />

            <view-change-log-modal
                :isOpen="openShowChangeLogModal"
                :docId="changeLogDocId"
                :document="activeChangeLogDoc"
            />
        </portal>

        <div
            v-if="showScrollToTopButton" 
            style="position: sticky; bottom: 0; right: 0; margin: 10px; z-index: 1000; width: 100%; padding-bottom: 5px;"> 
            <sui-button
                floated="right"
                size="mini"
                style="padding: 9.87755px;"
                @click="scrollToTop()"
                >
                <i class="fa-solid fa-arrow-up"></i>
            </sui-button>
        </div>
    </div>

</template>
<script>
import { mapGetters } from 'vuex'
import swal from 'sweetalert'
import { analytics, db, FieldValue } from '@/firebase.js'
import { Maps/*, MapMarkerImages*/ } from '@/lib/maps.js'
import { Mixin } from '@/lib/Mixins/mixin.js'
import { DateMixin } from '../../lib/Mixins/dateMixin'
import { DataAPI } from '@/lib/DataAPI.js'
import { Bookingmixin } from '@/lib/Bookingmixin.js'
import { Mime } from '@/lib/helpers/mime.js'
import { EmptySubTasks } from '@/lib/Enums/SubtaskType.js'
import { SortingMixin } from '../../lib/Mixins/SortingMixin.js'
import { unitWorkMixin } from '@/lib/unitWorkMixin.js'
import EventBus from '@/EventBus.js'
import NetworkError from '@/lib/Errors/Network.js'
import TaskCode from '@/lib/Enums/TaskCode.js'
import TaskState from '@/lib/Enums/TaskState.js'
import TicketState from '@/lib/Enums/TicketState'
import AppointmentState from '@/lib/Enums/AppointmentState.js'
import BookingItemHandlerType from '@/lib/Enums/BookingItemHandlerType.js'
import ProjectType from '@/lib/Enums/ProjectType.js'
import AppointmentType from '@/lib/Enums/AppointmentType.js'
import SmsModal from '@/components/Project/SmsModal.vue'
import Timeline from '@/components/Project/Timeline.vue'
// import TimelineV2 from '@/components/Project/TimelineV2.vue'
import TableLoader from '@/components/TableLoader.vue'
import BookingFormModal from '@/components/Project/BookingFormModal.vue'
import StateChangeModal from '@/components/Project/StateChangeModal.vue'
import FileViewerModal from '@/components/Project/FileViewerModal.vue'
import RemoveAppointmentModal from '@/components/Project/RemoveAppointmentModal.vue'
import SubtaskStateChangeForm from '@/components/Project/SubtaskStateChangeForm.vue'
import SpliceReportsDownloadModal from '@/components/Project/SpliceReportsDownloadModal.vue'
import EditTechDataModal from '@/components/Project/EditTechDataModal.vue'
import TaskType from '@/lib/Enums/TaskType.js'
import EditUnitWorkModal from '@/components/Project/EditUnitWorkModal.vue'
import EditCustomAppointmentModal from '@/components/Project/EditCustomAppointmentModal.vue'
import EditSettingsModal from '@/modules/Settings/EditSettingsModal.vue'
import MultiStepLoader from '@/components/Global/MultiStepLoader.vue'
import OnHoldReason from '@/lib/Enums/OnHoldReason.js'
import ActiveInstallationCustom from '@/components/Project/ActiveInstallationCustom.vue'
import ActiveInstallation from '@/components/Project/ActiveInstallation.vue'
import PortCheckModal from '@/components/Project/PortCheckModal'
import CancellationListModal from '@/components/Project/CancellationListModal.vue'

// import { cloudFunctions } from '@/firebase.js'
import FailedContactModal from '@/components/Project/FailedContactModal.vue'
import EditAppointmentState from '../../components/Project/EditAppointmentState.vue'
// import OKAPIscript from '@/lib/DataProviders/OKapi.js'
import OKAPIMap from '@/components/Maps/OKAPImap.vue'
import { mapMarkerMixin } from '@/lib/Mixins/mapMarkerMixin.js'
import viewChangeLogModal from '../../components/Project/viewChangeLogModal.vue'
import UserRoles from '../../lib/Enums/UserRoles'
import { CoreMixin } from '@/lib/core/coreMixin.js'

import { TransferMixin } from '../../lib/helpers/transferMixin'
import { ScrollMixin } from '@/lib/Mixins/ScrollMixin.js'

export default {
    name: 'ProjectBooking',
    mixins: [Mixin, DateMixin, Maps, DataAPI, Bookingmixin, Mime, SortingMixin, mapMarkerMixin, CoreMixin, unitWorkMixin, TransferMixin, ScrollMixin],

    components: {
        'ProjectTimeline': Timeline,
        // 'ProjectTimelineV2': TimelineV2,
        TableLoader,
        StateChangeModal,
        SmsModal,
        BookingFormModal,
        RemoveAppointmentModal,
        SubtaskStateChangeForm,
        FileViewerModal,
        SpliceReportsDownloadModal,
        EditTechDataModal,
        EditUnitWorkModal,
        EditCustomAppointmentModal,
        MultiStepLoader,
        FailedContactModal,
        EditSettingsModal,
        EditAppointmentState,
        ActiveInstallationCustom,
        ActiveInstallation,
        PortCheckModal,
        OKAPIMap,
        CancellationListModal,
        viewChangeLogModal,
    },

    enums: {
        TaskCode,
        TaskState,
        AppointmentState,
        BookingItemHandlerType,
        ProjectType,
        AppointmentType,
        TaskType,
        OnHoldReason,
        UserRoles,
    },

    data() {
        return {
            appointments: [],
            allAppointments: [],
            allUpdates: [],
            projectCustomAppointments: [],
            globalCustomAppointments: [],
            closedAppointments: [],
            firebaseUsers: [],

            closedSinceInput: this.subtractDays(new Date(),1).toISOString().substr(0,10),
            closedSince: this.subtractDays(new Date(),1).toISOString().substr(0,10),
            getClosedLoading: false,

            searchFilterValue: '',
            lastIteratedDate: null,

            activeInstallationLabel: null,
            activeHub: null,
            activeInstallationData: null,
            productError: null,
            selectedAppointmentId: null, 
            
            stateModalOpen: false,
            activeTask: {},

            editSettings: false,

            installationMissingCoordinates: [],
            installationMissingCoordinatesIteratorIndex: 0,
            failedInstallationMissingCoordinates: [],
            showCoordinateImportModal: false,
            coordinateImportStage: 0,
            coordinateImportTime: 0,
            ignoreCoordinateImport: false,
            googleCoordinateLookupsPerSecond: 20, // Max allowed by Google: 50 req/s // Dawa max 30 req/s
            coordinatesImportIntervalLoopRef: null,
            coordinateImportErrorPopup: false,
            coordinateImportErrorMessage: null,
            coordinateImportErrorPopupDidShow: false,
            coordinateImportErrorCount: 0,

            loadingProducts: false,
            loadingInstallationData: false,
            instDataReloadIconSpinning: false,
            loadingBookedTimeline: true,
            loadingUnBookedTimeline: true,
            loadingSaveBooking: false,
            saveBookingLoadingSteps: [],
            installationDataLoadingSteps: [],

            showBookingModal: false,
            bookingFormData: {},

            showRemoveAppointmentModal: false,

            map: null,
            ignoreLeadPermissions: window.localStorage.getItem('admin-ignore-lead-permissions') == 'true',

            noteModalOpen: false,
            noteType: "",
            noteText: "",
            noteFormSuccess: false,
            noteFormLoading: false,

            CAnoteModalOpen: false,
            CAnoteText: "",
            CAnoteFormSuccess: false,
            CAnoteFormLoading: false,

            uploadFileModalOpen: false,
            fileNameInput: null,
            fileUpload: null,
            fileFormSuccess: false,
            fileFormLoading: false,

            openFileModalOpen: false,
            openFile: null,
            openFileLoading: false,
            openShowChangeLogModal: false,

            smsModalOpen: false,
            activePhoneNumber: "",

            stashedServiceOrders: {},
            
            firebaseInternalSubtasks: [],
            activeSubtask: {},
            activeSubtaskValue: null,
            activeSubtaskResponsibleIndex: null,
            activeSubtaskDeferred: false,
            showSubtaskModal: false,
            subtaskModalLoading: false,

            stashedTechnicals: {
                hubs: {},
                uubs: {}
            },
            loadingTechnicals: false,

            downloadReportModalOpen: false,
            downloadReportActiveItem: {},

            editTechDataModalOpen: false,
            editUnitWorkModalOpen: false,
            activeInstallationUnitWork: [],
            activeUnitWork: {},
            preFilledUnitWorkDescription: null,

            firebaseServiceProviders: null,

            editCustomAppointmentModalOpen: false,
            showEditAppointmentStateModal: false,
            portCheckModalOpen: false,

            inVainVisits: [],

            productResponses: null,
            bindingCachedProducts: null,

            minuteInterval: null,
            availableInstallationsRecomputeCount: 0,

            failedContactModalOpen: false,

            mapMarkers: {},

            openSelectAppointmentToEdit: false,
            appointmentOptionsNoNew: [],
            editSelectedAppointmentId: null,
            showCancellationListModal: false,
            activeChangeLogDoc: null,
            changeLogDocId: '',
            // bookingDashboardLayout: 'layout1',


            migrateData: false,
            migrationProgressPercent: 0,
            migrationProgressBarLabel: '',

            appointmentsBound: false,
        }
    },

    computed: {
        user() {
            return this.$root.$children[0].user
        },

        firebaseUser() {
            return this.$root.$children[0].firebaseUser
        },

        currentMedia(){
            return this.$root.$children[0].currentMedia
        },

        showScrollToTopButton(){
            return this.$root.$children[0].showScrollToTopButton && this.currentMedia.type != 'Desktop';
        },

        showSettingsButton(){
            if (this.currentMedia.type == 'Mobile' && this.currentMedia.orientation == 'Portrait') return false
            return true
        },

        sortAscending() {
            if (this.settingsBD) {
                if (this.settingsBD.get("bookSortAscending") == '0') return true}
            return false
        },
        
        getColumnWidth(){
            let mapWidth = 'col-sm-6'
            let dataCardWidth = 'col-sm-6'

            if (this.settingsBD.get('showMap') && this.getLayoutBD != 'layout2'){
                dataCardWidth = 'col-sm-3'
            }

            if (this.getLayoutBD == 'layout1'){
                mapWidth = 'col-sm-4'
                dataCardWidth = 'col-sm-4'
            }
            return {map: mapWidth, dataCard: dataCardWidth}
        },

        getLayoutBD() {
            if (this.currentMedia.type == 'Mobile') return 'layout0'
            if ((this.settingsBD.get('bookingDashboardLayout') == 'layout2' || this.settingsBD.get('bookingDashboardLayout') == 'layout1') && !this.settingsBD.get('showMap') && !this.settingsBD.get('showUnbookedCard')) return 'layout0'
            return this.settingsBD.get('bookingDashboardLayout')
        },

        getHeightBooked(){
            return (this.getLayoutBD == 'layout2' && this.settingsBD.get('showUnbookedCard')) ? 'half-height' : 'full-height'
        },

        getHeightUnbookedRightColumn(){
            return (this.settingsBD.get('showMap') && this.getLayoutBD == 'layout1') || this.getLayoutBD == 'layout0' ? 'half-height' : 'full-height'
        },

        getHeightActiveInstRightColumn(){
            if (!this.settingsBD.get('showUnbookedCard') && this.getLayoutBD == 'layout0') return 'full-height active-installation-box'

            if (!this.settingsBD.get('showUnbookedCard') && this.getLayoutBD == 'layout1') return 'full-height active-installation-box'

            return this.settingsBD.get('showMap') || this.getLayoutBD == 'layout0' ? 'half-height active-installation-box' : 'full-height active-installation-box'
        },

        userHasLeadPermissions() {
            if(this.ignoreLeadPermissions) return false
            if(this.firebaseUser && this.firebaseUser.Roles && this.firebaseUser.Roles.includes(UserRoles.USER_ADMIN)) return true //Admins always have lead permissions
            let userContact = this.project.Contacts.find(contact => contact.Email == this.user.email) //Find user in project contacts
            if (!userContact) return false //if user is not in project contacts, they cannot be project lead
            if (userContact.Roles.includes('ProjectLead')) return true //Allow lead permissions for project lead
            return false
        },

        ...mapGetters({
            project: 'activeProject',
        }),

        availableInstallations() {
            if (!this.installations || !this.installations.length) return []

            this.allUpdates.forEach((update) => {
                let instLabel = update.InstallationLabel
                let instIndex = this.installations.findIndex((ins) => {return ins.label == instLabel})
                if (instIndex != -1){
                    let updateClone = this.cloneJson(update)
                    updateClone.id = update.id
                    this.installations[instIndex].updates = updateClone
                }
                
            })

            let  installations = this.cloneJson(this.installations)
                .filter((ins) => {
                    return !this.allAppointments.some(b => {
                        return ins.label == b.InstallationLabel
                    })
                })

            // Filter Pending and OnHold
            if (!this.settingsBD.get("showPendingTasks") || !this.settingsBD.get("showOnHoldTasks")) {
                installations = installations.filter((ins) => {
                    if (ins.tasks[0]?.code != TaskCode.TICKET) {
                        if (!this.settingsBD.get("showPendingTasks") && !this.settingsBD.get("showOnHoldTasks")) {
                            return !this.insContainsOnlyState(ins, TaskState.PENDING) && !this.insContainsState(ins, TaskState.ON_HOLD)
                        } else if (!this.settingsBD.get("showPendingTasks")) {
                            return !this.insContainsOnlyState(ins, TaskState.PENDING)
                        } else if (!this.settingsBD.get("showOnHoldTasks")) {
                            return !this.insContainsState(ins, TaskState.ON_HOLD)
                        }
                    } else {
                        if (!this.settingsBD.get("showOnHoldTasks")) {
                            return !this.insContainsState(ins, TicketState.ON_HOLD)
                        }
                    }
                    return true
                })
            }

            // Filter Resolved
            if (!this.settingsBD.get('showResolved')) {
                const showResolved = this.settingsBD.get('showResolved')
                installations = installations.filter((ins) => {
                    if (ins.tasks[0]?.code == TaskCode.TICKET) {
                        if (!showResolved) {
                            return !this.insContainsOnlyState(ins, TicketState.RESOLVED) //NOT ALL tasks are 'resolved' - TODO: fix for mixed with 'closed' and 'canceled'
                        }
                    }
                    return true
                })
            }

            // Filter AppointmentTypes
            if (this.shouldFilteraAppointmentTypes(this.settingsBD)){
                installations = installations.filter((ins) => {return this.filterInsAppointmentTypes(this.settingsBD,ins)})
            }

            // Filter By Search Value
            if (this.searchFilterValue) {
                installations = installations.filter((ins) => {
                    return this.matchSearchCriteria(ins)
                })
            }

            // Add subtasks and inVainVisits
            installations.forEach(ins => {
                // TODO: Move subtasks to a new method to better handle which subtasks gets applied to which installation types
                // Patch: No subtasks
                // Only Tickets: No subtasks
                // Technical: No subtasks
                ins.subtasks = this.makeSubtaskObjFromArray(this.internalSubTasks[ins.label])
                ins.inVainVisits = this.inVainVisits.filter(ivv => ivv.ConfigurationItem.Label == ins.label)
            })

            // Split Installations by only ProjectTasks / ProjectTasks and or TroubleTickets
            let installationsTickets = []
            let installationsRemaining = []
            installations.forEach(ins => {
                let projectTaskCount = 0
                let troubleTicketCount = 0
                for (let i in ins.tasks){
                    if (ins.tasks[i].code == TaskCode.TICKET) {troubleTicketCount += 1}
                    else {projectTaskCount += 1}
                }
                ins.projectTaskCount = projectTaskCount
                ins.troubleTicketCount = troubleTicketCount

                if (troubleTicketCount != 0){installationsTickets.push(ins)}
                else {installationsRemaining.push(ins)}
            })

    

            // Group Installations
            let groupedTicketsInstallations = this.uniqueBookingGroups(installationsTickets, this.settingsBD.get('sortUnbookedTTBy'), true)
            let groupedRemainingInstallations = this.uniqueBookingGroups(installationsRemaining, this.settingsBD.get("sortUnbookedPTBy"), true)

            installationsTickets.forEach(ins => {
                const task = ins.tasks[0]
                if (!task) {
                    if (!groupedTicketsInstallations['N/A']) groupedTicketsInstallations['N/A'] = []
                    groupedTicketsInstallations['N/A'].push(ins)
                    return
                }
                if (!groupedTicketsInstallations[this.formatBookingGroup(this.resolveObjPath(this.settingsBD.get("sortUnbookedTTBy"), task))]) {
                    groupedTicketsInstallations[this.formatBookingGroup(this.resolveObjPath(this.settingsBD.get("sortUnbookedTTBy"), task))] = []
                }
                groupedTicketsInstallations[this.formatBookingGroup(this.resolveObjPath(this.settingsBD.get("sortUnbookedTTBy"), task))].push(ins)
            })

            installationsRemaining.forEach(ins => {
                const task = ins.tasks[0]
                if (!task) {
                    if (!groupedRemainingInstallations['N/A']) groupedRemainingInstallations['N/A'] = []
                    groupedRemainingInstallations['N/A'].push(ins)
                    return
                }
                if (!groupedRemainingInstallations[this.formatBookingGroup(this.resolveObjPath(this.settingsBD.get("sortUnbookedPTBy"), task))]) {
                    groupedRemainingInstallations[this.formatBookingGroup(this.resolveObjPath(this.settingsBD.get("sortUnbookedPTBy"), task))] = []
                }
                groupedRemainingInstallations[this.formatBookingGroup(this.resolveObjPath(this.settingsBD.get("sortUnbookedPTBy"), task))].push(ins)
            })

            if (this.settingsBD.get('mergeTaskTypes') == 'merge'){
                this.combineUniqueBookingGroups(groupedTicketsInstallations,groupedRemainingInstallations)
            }

            let sortedTicketsInstallations = this.nestedSortInstallations(groupedTicketsInstallations)
            let sortedRemainingInstallations = this.nestedSortInstallations(groupedRemainingInstallations)

            return [sortedTicketsInstallations, sortedRemainingInstallations]
        },

        bookedInstallations() {
            let appointments = [
                ...this.projectCustomAppointments,
                ...this.globalCustomAppointments,
                ...this.appointments,
                ...this.closedAppointments,
            ]
            
            var mergedData = [];

            let appointmentsWithNoInstallation = 0
            for (let i in appointments) {
                const appointment = appointments[i]
                const foundInstallation = this.installations.find(ins => {
                    if (!ins.tasks[0]) return false
                    return ins.label == appointment.InstallationLabel
                })
                let installation = this.cloneJson(foundInstallation) //Ensure a side-effect free clone of the installation data

                if (appointment.AppointmentType == 'Custom') {
                    installation = {
                        label: appointment.id,
                        tasks: [{
                            AppointmentDate: this.readTimeWindowString(appointment.TimeWindowString).Date,
                            state: {
                                value: TaskState.OPEN
                            }
                        }],
                    }
                } 
                
                if (!installation && appointment.InstallationLabel) {
                    installation = {
                        label: appointment.InstallationLabel,
                        tasks: [],
                    }
                }

                if (!installation) {
                    appointmentsWithNoInstallation += 1
                    continue
                }

                if (this.searchFilterValue && !this.matchSearchCriteria(installation)) {
                    continue
                }

                let timeWindowStringObj = this.readTimeWindowString(appointment.TimeWindowString)

                let appClone = { //cloned version of the appointment, with timeWindow data added
                    ...this.cloneJson(appointment),
                    ...this.cloneJson(timeWindowStringObj)
                }
                installation.appointment = this.cloneJson(appClone) //appointment (singular) used for rendering timeline
                
                if (installation.tasks && installation.tasks[0]) {
                    installation.tasks[0].AppointmentDate = timeWindowStringObj.Date
                    installation.subtasks = this.makeSubtaskObjFromArray(this.internalSubTasks[appointment.InstallationLabel])
                    installation.inVainVisits = this.inVainVisits.filter(ivv => ivv.ConfigurationItem.Label == installation.label)
                }
                mergedData.push(installation)
            }

            if (!this.settingsBD.get('showResolved')) {
                mergedData = mergedData.filter((ins) => {
                    const today = this.formatMachineDate(new Date())
                    if (!this.settingsBD.get('showResolved') && ins.appointment.Date < today) {
                        return !this.insContainsOnlyState(ins, TicketState.RESOLVED) //NOT ALL tasks are 'resolved' - TODO: fix for mixed with 'closed' and 'canceled'
                    }
                    return true
                })
            }

            if(appointmentsWithNoInstallation){
                console.error(`${appointmentsWithNoInstallation}/${appointments.length} appointments were missing a referenced installation`)
            }
            let groupedByDates = this.uniqueBookingGroups(mergedData, 'AppointmentDate', true) //Find unique dates from firebase appointments

            mergedData.forEach(value => { //For each appointment
                let projectTaskCount = 0
                let troubleTicketCount = 0
                for (let i in value.tasks){
                    if (value.tasks[i].code == TaskCode.TICKET) {troubleTicketCount += 1}
                    else {projectTaskCount += 1}
                }
                value.projectTaskCount = projectTaskCount
                value.troubleTicketCount = troubleTicketCount
                if(!groupedByDates[this.formatMachineDate(value.appointment.Date)]){
                    console.error("Date from appointment not found in dates", value.appointment, Object.keys(groupedByDates))
                } else {
                    groupedByDates[this.formatMachineDate(value.appointment.Date)].push(value) //Push all relevant appointments to the relevant date
                }
            })

            let sortedBookings = this.nestedSortBookings(groupedByDates)
            return sortedBookings
        },

        availableInstallationsLength() {
            const bookingDates = this.availableInstallations
            let counter = 0

            for(let i in bookingDates) {
                for (let j in bookingDates[i]){counter += bookingDates[i][j].length}
            }

            return String(counter)
        },

        bookedInstallationsLength() {
            const bookingDates = this.bookedInstallations
            let counter = 0


            for(let i in bookingDates) {
                counter += bookingDates[i].length
            }

            return String(counter)
        },

        availableWorkers() {

            let workersArray = this.cloneJson(this.project.Workers) || []
            if (!workersArray.find(w => w.Email == 'Ingen')){ //If no 'Ingen' worker is found, add one
                workersArray.push({Email: "Ingen"})
            }

            let workerOptions = []
            for (let i in workersArray) {
                let w = workersArray[i]
                let firWorker = this.firebaseUsers.find((worker) => w.Email.toLowerCase() == worker.Email.toLowerCase())
                if (firWorker) {
                    workerOptions.push({key: i, value: w.Email.toLowerCase(), text: `${firWorker.Name} (${firWorker.Initials})`})
                }
            }

            return workerOptions
        },

        availableWorkersData() {
            if (!this.project || !this.project.Workers || !this.firebaseUsers.length) {
                // let missingProp = !this.project ? 'project' : !this.project.Workers ? 'project.Workers' : 'firebaseUsers'
                // console.error(`getAvailableWorkersData: this.${missingProp} not populated`)
                // throw new Error(`getAvailableWorkersData: this.${missingProp} not populated`)
                return []
            }

            return this.project.Workers.map(worker => {
                let _worker = this.firebaseUsers.find(user => {return this.lowercase(user.Email) == this.lowercase(worker.Email)})
                // console.log(`Found user based on email: ${worker.Email} - ${_worker?.Name}`)
                if (!_worker) return null //If no user matches worker, return null
                return _worker
            }).filter(worker => {return worker != null}) //Remove null values
            
        },

        activeInstallationAddress() {
            // console.log('activeInstallationAddress re-computing')
            if (!this.activeInstallationData || !this.activeInstallationData.tasks || !this.activeInstallationData.tasks[0] || !this.getConfiguration(this.activeInstallationData.tasks[0]) || !this.getConfiguration(this.activeInstallationData.tasks[0]).address) return "Ingen adresse"
            return this.formatAddress(this.getConfiguration(this.activeInstallationData.tasks[0]).address)
        },

        activeServiceOrder() {
            // console.log('activeServiceOrder re-computing')
            // console.log(this.activeInstallationData.tasks[0])
            if (!this.activeInstallationData || !this.activeInstallationData.tasks[0] || !this.activeInstallationData.tasks[0].serviceOrder || !this.activeInstallationData.tasks[0].serviceOrder.id) return null
            return this.stashedServiceOrders[this.activeInstallationData.tasks[0].serviceOrder.id] || this.activeInstallationData.tasks[0].serviceOrder
        },

        activeServiceProvider() {
            // console.log('activeServiceProvider re-computing')
            if (!this.activeServiceOrder) return {number: 'Denne opgave har ingen serviceOrder'}
            if (!this.activeServiceOrder.serviceProvider) return {number: 'Denne opgaves serviceOrder har ingen serviceProvider'}
            if (this.firebaseServiceProviders && this.firebaseServiceProviders.findIndex(sp => sp.Id == this.activeServiceOrder.serviceProvider.id) != -1) {
                return this.firebaseServiceProviders.find(sp => sp.Id == this.activeServiceOrder.serviceProvider.id)
            } else {
                return this.activeServiceOrder.serviceProvider
            }
        },

        estimatedCoordinatesImportTime() {
            const estimate = Math.round(this.installationMissingCoordinates.length / this.googleCoordinateLookupsPerSecond) + 1
            return estimate > 2 ? estimate : 2
        },

        estimatedCoordinatesImportTimeLeft() {
            const estimate = Math.round(
                (this.installationMissingCoordinates.length - this.installationMissingCoordinatesIteratorIndex)
                / this.googleCoordinateLookupsPerSecond) + 1
            return estimate
        },

        internalSubTasks() {
            let outputData = {}
            const firebaseData = this.firebaseInternalSubtasks
            for (let i in firebaseData){
                const instSubtasks = firebaseData[i]
                outputData[instSubtasks.InstallationLabel] = this.constructSubTasksArray(instSubtasks)
            }
            for (const inst of this.installations){
                if (!outputData[inst.label]){
                    outputData[inst.label] = [...EmptySubTasks]
                }
            }
            return outputData
        },

        activeTechnicalData() {
            return this.getConfiguration(this.activeInstallationData?.tasks[0])?.technicalData
        },

        customAppointmentIds() {
            let arr = []
            for (let i in this.projectCustomAppointments) {
                arr.push(this.projectCustomAppointments[i].id)
            }
            for (let i in this.globalCustomAppointments) {
                arr.push(this.globalCustomAppointments[i].id)
            }
            return arr
        },

        allProjects() {
            return this.$parent.allProjects
        },

        firstProductDeliveryDate() {
            return this.findFirstProductDeliveryDate(this.activeInstallationData?.tasks?.[0]?.products)
        },

        taskAppointments() {
            return this.appointments.filter(appointment => appointment.ProjectTasks.findIndex(task => task.Id == this.activeTask.id) != -1)
        },

        activeInVainVisits() {
            return this.activeInstallationUnitWork.filter(uw => uw.Unit.Id == '931.F.99') //931.F.99 is ID of VisitInVain unitWorkUnit
        },

        // mapsBounds() {
        //     if (!this.settingsBD.get("showMap")) return null // If map is not shown, don't bother with mapsBounds
        //     // console.log('calculating bounds')
        //     let bounds = null
        //     for (const ins of this.cloneJson(this.installations)) {
        //         if (!ins || !ins.coordinates || !ins.coordinates.Lat || !ins.coordinates.Lng){
        //             console.error('invalid coordinates for installation', ins)
        //             continue;
        //         }
        //         if (!bounds) {
        //             bounds = {
        //                 north: Number(ins.coordinates.Lat),
        //                 south: Number(ins.coordinates.Lat),
        //                 east: Number(ins.coordinates.Lng),
        //                 west: Number(ins.coordinates.Lng),
        //             }
        //         } else {
        //             bounds.north = Math.max(Number(ins.coordinates.Lat), bounds.north)
        //             bounds.south = Math.min(Number(ins.coordinates.Lat), bounds.south)
        //             bounds.east = Math.max(Number(ins.coordinates.Lng), bounds.east)
        //             bounds.west = Math.min(Number(ins.coordinates.Lng), bounds.west)
        //         }
        //     }
        //     // console.log(bounds)
        //     return bounds
        // },

        activeContacts() {
            return this.activeInstallationData?.tasks?.[0]?.contacts || []
        },

        activeInstallationTasksSorted() {
            let tasksClone = this.cloneJson(this.activeInstallationData.tasks)
            return tasksClone.sort(this.sortTasks) //this.sortTasks is a method defined in this file, taking two tasks as input, and returning either -1 or 1
        },

        activeAppointmentOptions() {
            let appointmentOptions = [{
                value: null,
                text: 'Ny aftale'
            }]
            const appointments = this.cloneJson(this.activeInstallationData?.appointments)
            if (!appointments) return appointmentOptions
            const appointmentKeys = Object.keys(appointments)
            for (let key of appointmentKeys) {
                let appointment = appointments[key]
                appointmentOptions.push({
                    value: key,
                    text: this.toTitleCase(AppointmentType.translate(appointment.AppointmentType)) + ' ' + this.formatDate(appointment.Date)
                })
            }
            return appointmentOptions
        },

        activeInstallationInstAppointment() {
            if (!this.activeInstallationData || !this.activeInstallationData.appointments || !Object.keys(this.activeInstallationData.appointments).length) return null
            for (let key of Object.keys(this.activeInstallationData.appointments)) {
                let app = this.activeInstallationData.appointments[key]
                if (app.AppointmentType == AppointmentType.INSTALLATION) {
                    return app
                }
            }
            return null
        },

        // mapMarkers() {
        //     let typeString = `installation-pending`

        //     //TODO: Refer to Hub Address for PATCH-tasks
        //     return this.installations.reduce((arr, ins) => {
        //         if (!ins.coordinates) { //skip installations with no coordinates
        //             return arr
        //         }
        //         let markerObj = {
        //             key: `mapMarker-${ins.label}`,
        //             id: ins.label,
        //             type: this.getMarkerTypeFromInstallation(ins, this.activeInstallationLabel) || typeString,
        //             title: this.formatAddress(ins.tasks[0].configurationItem.address, false),
        //             coordinates: {
        //                 lat: parseFloat(ins.coordinates.Lat),
        //                 lng: parseFloat(ins.coordinates.Lng),
        //             },
        //             address: this.formatAddress(ins.tasks[0].configurationItem.address, false),
        //         }
        //         arr.push(markerObj)
        //         return arr
        //     }, [])
        // },
    },

    methods: {
        scrollToTop(){
            this.scrollToRef(this.$refs.topOfPage);
        },

        getInstallationByNumber(){
            this.activeInstallationLabel = this.searchFilterValue
        },



        layoutButtonClicked(layout){
            this.settingsBD.set('bookingDashboardLayout', layout) 
            this.settingsBD.saveAll()
            console.log(layout)
        },

        layoutButtonSelected(layout){
            if (this.settingsBD.get('bookingDashboardLayout') == layout) return 'layout-button-selected'
            return 'layout-button'
        },

        nonScheduledHeader(){
            let returnStr = `<div class='headerWrapper'><span>Ikke Booket</span>&nbsp;&nbsp;<div class='headerInner'>`
            

            
            returnStr += `<div style='margin-bottom:-11px; margin-top:-8px'><span><small><small><em>`
            if (this.loadTickets && !this.loadProjectTasks) {
                returnStr += `(Sortering: ${this.unbookedSortingOptTroubleTickets.find(o => o.value == this.settingsBD.get('sortUnbookedTTBy')).text})`
            } else {
                returnStr += `(Sortering: ${this.unbookedSortingOptProjTasks.find(o => o.value == this.settingsBD.get('sortUnbookedPTBy')).text})`
            }
            returnStr += `</em></small></small></span></div><div><span><small><small><em>(Filter:`

            if (this.loadTickets){
                if (this.settingsBD.get('showApptTypeTickets')){
                    returnStr += ` <i title='Driftsager er slået til' class='fa-solid fa-bug' style='color: #FFAD00;'></i>`
                } else {
                    returnStr += ` <i title='Driftsager er slået fra' class='fa-solid fa-bug-slash' style='color: gray;'></i>`
                }   
            }
            
            if (this.loadProjectTasks){
                if (this.settingsBD.get('showApptTypeInspection')){
                    returnStr += ` <i title='Besigtigelsesopgaver er slået til' class='fa-solid fa-file-contract' style='color: #C182C1;'></i>`
                } else {
                    returnStr += ` <i title='Besigtigelsesopgaver er slået fra' class='fa-kit fa-solid-file-contract-slash' style='color: gray;'></i>`
                }
                
                if (this.settingsBD.get('showApptTypeInstallation')){
                    returnStr += ` <i title="Installationsopgaver er slået til" class="fa-solid fa-earth-europe" style="color: #C182C1;"></i>`
                } else {
                    returnStr += ` <i title="Installationsopgaver er slået fra" class="fa-kit fa-solid-earth-europe-slash" style="color: gray;"></i>`
                }
                
                if (this.settingsBD.get('showApptTypePatch')){
                    returnStr += ` <i title="Patchopgaver er slået til" class="fas fa-plug" style="color: green;"></i>`
                } else {
                    returnStr += ` <i title="Patchopgaver er slået fra" class="fa-kit fa-solid-plug-slash" style="color: gray;"></i>`
                }
                
                if (this.settingsBD.get('showApptTypeTechnician')){
                    returnStr += ` <i title="Udvidet installationsopgaver er slået til" class="fa-solid fa-router" style="color: #C182C1;"></i>`
                } else {
                    returnStr += ` <i title="Udvidet installationsopgaver er slået fra" class="fa-kit fa-solid-router-slash" style="color: gray;"></i>`
                }     
            }
            
            returnStr += `)</em></small></small></span></div></div></div>`
            return returnStr
        },

        scheduledHeader() {
            return `<div class='headerWrapper'><span>Tidsplan</span>&nbsp;&nbsp;<div class='headerInner'><div style='margin-bottom:-6px; margin-top:-8px'><span><small><small><em>(Filter: ${this.settingsBD.allSettings.bookedDuration.options.find(o => o.value == this.settingsBD.get('bookedDuration')).text})</em></small></small></span></div></div></div>`
        },

        getHubFromASRName() {
            if (!this.activeTechnicalData) {
                console.error('activeTechnicalData not populated, cannot get asrName or hub')
                return this.getConfiguration(this.activeInstallationData.tasks[0])?.cabinet?.technicalHouseNumber || null
            }
            return this.activeTechnicalData.asrName.split('-')[2]
        },

        getHubNumber() {
            let hubNo = this.getHubFromASRName()
            if (hubNo != null){return hubNo.replace(/\D/g, '').padStart(4,'0')}
            return null
        },

        async getServiceOrders(forceFetch = false) {
            EventBus.$emit('function-activity', {functionName: 'Booking_getServiceOrders', isActive: true})
            const startingInstallationLabel = this.activeInstallationLabel.toLowerCase()
            // console.log(`getServiceOrders: ${startingInstallationLabel} - starting`)

            this.productError = null //Clear any previous product errors, that might prevent new products from rendering
            var serviceOrders = []

            //Guard against missing or invalid installation label
            if (
                !startingInstallationLabel ||  //Installation label must be present
                !startingInstallationLabel.match(/^[0-9]{6,12}$/) //Installation label must be 6-12 digits
            ) {
                console.error('getServiceOrders: Missing or invalid installation label', startingInstallationLabel)
                EventBus.$emit('function-activity', {functionName: 'Booking_getServiceOrders', isActive: false})
                return serviceOrders
            }

            let products = this.activeInstallationData?.tasks?.[0]?.products
            let productDeliveryDate = this.findFirstProductDeliveryDate(products)

            const task = this.activeInstallationData?.tasks?.[0] //TODO: use Primary task instead of first task
            if (typeof(task) == 'undefined'){
                EventBus.$emit('function-activity', {functionName: 'Booking_getServiceOrders', isActive: false})
                return
            }
            
            // Don't continue if the service already has been saved AND forceFetch is false.
            if (this.objExistsInArray('id', task.serviceOrder?.id, this.stashedServiceOrders) && !forceFetch) {
                this.loadingInstallationData = false
                EventBus.$emit('function-activity', {functionName: 'Booking_getServiceOrders', isActive: false})
                // console.log(`getServiceOrders: ${startingInstallationLabel} - already in stash`)
                return
            }

            let serviceOrder = {id: null}

            try{
                if (task.serviceOrder.id) {
                    serviceOrder = await this.dataGetServiceOrder(task.serviceOrder.id)
                    serviceOrders.push(serviceOrder)
                } else {
                    throw new Error(`No serviceOrder on task ${task.number}, attempting to get serviceOrder from all serviceOrders for installation ${startingInstallationLabel}`)
                }
            }
            catch(err) {
                console.error(`Could not get serviceOrder, with error:\n${err}`)
                try {
                    serviceOrders = await this.dataGetAllServiceOrdersByInst(startingInstallationLabel)
                    if (!serviceOrders.length) {
                        throw new Error(`Could not find any serviceOrders for installation ${startingInstallationLabel}`)
                    }
                    if (task.serviceOrder.id){ //If the task has an associated serviceOrder Id, find the serviceOrder with that Id
                        serviceOrder = serviceOrders.find((so) => so.id == task.serviceOrder.id)
                    } else { //If the task does not have an associated serviceOrder Id, find the serviceOrder that contains the task
                        for (let so of serviceOrders) {
                            if (so.project && so.project.tasks && so.project.tasks.length) {
                                for (let soTask of so.project.tasks) {
                                    if (soTask.id == task.id) {
                                        serviceOrder = so
                                        break
                                    }
                                }
                            }
                        }
                    }
                    if (!serviceOrder) {
                        console.error(`Could not find serviceOrder ${task.serviceOrder.id} in serviceOrders for installation ${startingInstallationLabel}`, serviceOrders)
                        throw new Error(`Could not find serviceOrder ${task.serviceOrder.id} in serviceOrders for installation ${startingInstallationLabel}`)
                    }
                } catch (error) {
                    console.error(`Could not get all serviceOrders, with error:\n${error}`)
                    swal('Fejl ved hentning af service-order', `Fik følgende fejl ved forsøg på at hente service order:\n${err}\n \nFik efterfølgende fejl ved hentning af alle serviceOrders:\n${error}`,'error')
                }
                
            }

            if (serviceOrder && serviceOrder.project && serviceOrder.project.tasks && serviceOrder.project.tasks.length) { //If the serviceOrder has tasks, update those tasks state in rawTasks
                for (let task of serviceOrder.project.tasks) { //Loop through serviceOrderTasks
                    let hasRawTask = this.rawTasks.has(task.id) //Find serviceOrderTask in rawTasks
                    if (!hasRawTask) {
                        if (TaskCode.AllArray.includes(task.code)) { //If task is relevant
                            //Get the task and add it to rawTasks
                            try {
                                // console.log(`Found relevant task ${task.number} in serviceOrder ${serviceOrder.number}, retrieving it, and adding it to rawTasks`)
                                let fullTask = await this.dataGetProjectTask({id: task.id, number: task.number})
                                if (products && !fullTask.products) {
                                    fullTask.products = products
                                    fullTask.productDeliveryDate = productDeliveryDate
                                }
                                this.setOrUpdateTaskInRawTasks(fullTask, true)
                            } catch (error) {
                                console.error(error)   
                            }
                        } 
                        continue;
                    }
                    let taskCopy = this.rawTasks.get(task.id)
                    taskCopy.state = task.state
                    this.setOrUpdateTaskInRawTasks(taskCopy)
                }
            }

            //Loop through serviceOrders to get relevant tasks
            // console.log(`getServiceOrders: ${startingInstallationLabel} - looping through ${serviceOrders.length} serviceOrder(s) to get relevant tasks`)
            for (let serviceOrder of serviceOrders) {
                //Guard against missing project Tasks
                if (!serviceOrder.project || !serviceOrder.project.tasks || !serviceOrder.project.tasks.length) {
                    console.error(`ServiceOrder ${serviceOrder.id} has no tasks`)
                    continue;
                } else {
                    // console.log(`ServiceOrder ${serviceOrder.id} has ${serviceOrder.project.tasks.length} tasks`)
                }


                this.$set(this.stashedServiceOrders, serviceOrder.id, serviceOrder) //Add serviceOrder to stashedServiceOrders

                //Loop through project tasks, retrieve them if they are not already in rawTasks, and add them to rawTasks
                for (let task of serviceOrder.project.tasks) {
                    //Guard against irrelevant task code
                    // if (!TaskCode.AllArray.includes(task.code)) continue; //Not needed if we only get relevant tasks from API

                    // let fullTask = {...this.rawTasks.find(t => t.id == task.id)} //Find task in rawTasks
                    let fullTask = this.rawTasks.get(task.id)
                    // console.log(fullTask)

                    if (!fullTask || forceFetch){ //If task is not in rawTasks, or forceFetch is true, retrieve it
                        try {
                            fullTask = await this.dataGetProjectTask({id: task.id, number: task.number})
                            await this.setOrUpdateTaskInRawTasks(fullTask, true)
                        } catch (error) {
                            console.error(`Error getting task ${task.number}`, error)
                        }
                    }
                    if (serviceOrder.products){
                        this.setProductsInRawTasks(serviceOrder.products, startingInstallationLabel) //Add products to rawTasks
                    }
                }
            }

            if (this.activeInstallationLabel != startingInstallationLabel) {
                console.warn(`Active installation changed from ${startingInstallationLabel} to ${this.activeInstallationLabel} while getting serviceOrder from API, quitting function.`)
                EventBus.$emit('function-activity', {functionName: 'Booking_getServiceOrders', isActive: false})
                return
            }

            // Trigger re-formatting rawTasks to tasks and thus installations.
            try {
                // console.log(`should format booking data: ${startingInstallationLabel}`)
                await this.bookFormatBookingData(this.settingsBD.get("showMap"))
            } catch (error) {
                console.error(`Error formatting booking data: ${error}`)
            }

            this.loadingInstallationData = false
            EventBus.$emit('function-activity', {functionName: 'Booking_getServiceOrders', isActive: false})
            return serviceOrders
        },

        async getTasksWithoutServiceOrder(forcefetch = false){
            EventBus.$emit('function-activity', {functionName: 'Booking_getTasksWithoutServiceOrder', isActive: true})
            const startingInstallationLabel = this.activeInstallationLabel.toLowerCase()
            let tasks = this.rawTasks.filter(task => this.getConfiguration(task).label == this.activeInstallationLabel)
            let cpeTaskIndex = tasks.find(t => t.code == TaskCode.CPE)
            if (cpeTaskIndex == -1) {
                let retrievedListTasks = await this.dataGetTasksV2(TaskType.PROJECTTASK, this.getConfiguration(tasks[0]).value)
                if (startingInstallationLabel != this.activeInstallationLabel.toLowerCase()){
                    console.warn('Installation changed while getting tasks, quitting function')
                    EventBus.$emit('function-activity', {functionName: 'Booking_getTasksWithoutServiceOrder', isActive: false})
                    return
                }
                for (let i in retrievedListTasks) {
                    this.setOrUpdateTaskInRawTasks(retrievedListTasks[i])
                }
            }
            for (let i in tasks){
                let task = tasks[i]
                if (task.notes && !forcefetch) continue; //notes is null for listed tasks, and an array (though possibly empty, stil truthy) for individually retrieved tasks
                let retreivedTask = await this.dataGetProjectTask(task)
                // console.log(retreivedTask)
                if (startingInstallationLabel != this.activeInstallationLabel.toLowerCase()){
                    console.warn('Installation changed while getting tasks, quitting function')
                    EventBus.$emit('function-activity', {functionName: 'Booking_getTasksWithoutServiceOrder', isActive: false})
                    return
                }
                tasks[i] = retreivedTask
                await this.setOrUpdateTaskInRawTasks(retreivedTask)
                if (startingInstallationLabel != this.activeInstallationLabel.toLowerCase()){
                    console.warn('Installation changed while getting tasks, quitting function')
                    EventBus.$emit('function-activity', {functionName: 'Booking_getTasksWithoutServiceOrder', isActive: false})
                    return
                }
            }
            this.$set(this.activeInstallationData.tasks[0], 'notes', this.findLastTask(tasks).notes)
            this.$set(this.activeInstallationData.tasks[0], 'attachments', this.findLastTask(tasks).attachments)
            this.$set(this.activeInstallationData.tasks[0], 'configurationItem', this.getConfiguration(this.findLastTask(tasks)))
            EventBus.$emit('function-activity', {functionName: 'Booking_getTasksWithoutServiceOrder', isActive: false})
            return tasks
        },

        async getHubs(id, forcefetch){
            EventBus.$emit('function-activity', {functionName: 'Booking_getHubs', isActive: true})
            let hubId = id.replaceAll(' ', '').substr(0, id.replaceAll(' ', '').indexOf('-'))
            let hubs = this.stashedTechnicals.hubs[hubId]
            if (!hubs?.length || forcefetch){
                hubs = await this.dataGetHubs(id)
                this.stashedTechnicals.hubs[hubId] = hubs
            }
            EventBus.$emit('function-activity', {functionName: 'Booking_getHubs', isActive: false})
            return hubs
        },

        async getUubs(id, forcefetch){
            EventBus.$emit('function-activity', {functionName: 'Booking_getUubs', isActive: true})
            let uubs = this.stashedTechnicals.hubs[id]
            if (!uubs?.length || forcefetch){
                uubs = await this.dataGetUubs(id)
                this.stashedTechnicals.uubs[id] = uubs.sort((a, b) => { return (a.identification > b.identification) ? 1 : -1 })
            }
            EventBus.$emit('function-activity', {functionName: 'Booking_getUubs', isActive: false})
            if (!uubs) return
            return uubs.sort((a, b) => {
                return (a.identification > b.identification) ? 1 : -1
            })
        },

        async getTechnicals(forcefetch = false) {
            if (this.activeInstallationData?.appointment?.AppointmentType == AppointmentType.CUSTOM) return //Dont bother for custom appointments
            EventBus.$emit('function-activity', {functionName: 'Booking_getTechnicals', isActive: true})
            this.loadingTechnicals = true
            const startingInstallationLabel = String(this.activeInstallationLabel).toLowerCase()
            const cabinetName = this.getConfiguration(this.activeInstallationData?.tasks?.[0])?.cabinet?.name
            if (!cabinetName) {
                console.error(`Installation ${this.activeInstallationData?.label} has no cabinet`)
                this.loadingTechnicals = false
                EventBus.$emit('function-activity', {functionName: 'Booking_getTechnicals', isActive: false})
                return
            }

            if (this.activeInstallationData.technicals?.length && !forcefetch){
                this.loadingTechnicals = false
                EventBus.$emit('function-activity', {functionName: 'Booking_getTechnicals', isActive: false})
                return
            }

            await Promise.all([
                this.getHubs(cabinetName, forcefetch),
                this.getUubs(cabinetName, forcefetch)
            ])

            if (startingInstallationLabel != String(this.activeInstallationLabel).toLowerCase()){
                console.warn('Installation changed while getting technicals, quitting function')
                EventBus.$emit('function-activity', {functionName: 'Booking_getTechnicals', isActive: false})
                return
            }
            await this.bookFormatBookingData(false)

            if (startingInstallationLabel != String(this.activeInstallationLabel).toLowerCase()){
                console.warn('Installation changed while rendering technicals, quitting function')
                EventBus.$emit('function-activity', {functionName: 'Booking_getTechnicals', isActive: false})
                return
            }

            let technicals = []
            let id = this.getConfiguration(this.activeInstallationData.tasks[0]).cabinet.name
            let hubId = id.replaceAll(' ', '').substr(0, id.replaceAll(' ', '').indexOf('-'))
            if (this.stashedTechnicals.hubs[hubId]) {
                technicals = technicals.concat(this.stashedTechnicals.hubs[hubId])
            }
            if (this.stashedTechnicals.uubs[id]) {
                technicals = technicals.concat(this.stashedTechnicals.uubs[id])
            }

            if (startingInstallationLabel != String(this.activeInstallationLabel).toLowerCase()){
                console.warn('Installation changed while rendering technicals, quitting function')
                EventBus.$emit('function-activity', {functionName: 'Booking_getTechnicals', isActive: false})
                return
            }

            this.activeInstallationData.technicals = technicals

            this.loadingTechnicals = false
            EventBus.$emit('function-activity', {functionName: 'Booking_getTechnicals', isActive: false})
        },

        async attachProductsToActiveData(serviceOrder, forcefetch = false) {
            EventBus.$emit('function-activity', {functionName: 'Booking_attachProductsToActiveData', isActive: true})
            this.productError = null
            this.loadingProducts = true

            if (!forcefetch) { //Forcefetch is OFF
                if (this.activeInstallationData.tasks[0].products && this.activeInstallationData.tasks[0].products.length){ //There is already products on the task
                    this.$set(this.activeInstallationData.tasks[0], "productDeliveryDate", this.findFirstProductDeliveryDate(this.activeInstallationData.tasks[0].products))
                    this.loadingProducts = false
                    EventBus.$emit('function-activity', {functionName: 'Booking_attachProductsToActiveData', isActive: false})
                    return
                }
                if (serviceOrder.products && serviceOrder.products.length) { //There is already products on the serviceOrder
                    this.$set(this.activeInstallationData.tasks[0], "products", serviceOrder.products)
                    this.$set(this.activeInstallationData.tasks[0], "productDeliveryDate", this.findFirstProductDeliveryDate(serviceOrder.products))
                    this.loadingProducts = false
                    EventBus.$emit('function-activity', {functionName: 'Booking_attachProductsToActiveData', isActive: false})
                    return
                }
            }

            let response = null
            try {
                response = await this.dataGetProducts(serviceOrder.sonWinId)
            } catch (error) {
                this.productError = error
                this.loadingProducts = false
                EventBus.$emit('function-activity', {functionName: 'Booking_attachProductsToActiveData', isActive: false})
                return
            }
            let orderLines = this.filterProductOrderlines(response, true)

            if (!orderLines.length) {
                this.loadingProducts = false
                EventBus.$emit('function-activity', {functionName: 'Booking_attachProductsToActiveData', isActive: false})
                return
            }

            this.setProductsInRawTasks(orderLines, serviceOrder.installation.id)

            serviceOrder.products = response
            this.$set(this.stashedServiceOrders, serviceOrder.id, serviceOrder)
            // console.log(`added ${orderLines.length} products to serviceOrder ${serviceOrder.id}`)
            if (this.activeInstallationData && this.activeInstallationData.tasks[0].serviceOrder.id == serviceOrder.id) {
                this.$set(this.activeInstallationData.tasks[0], "products", orderLines)
                this.$set(this.activeInstallationData.tasks[0], "productDeliveryDate", this.findFirstProductDeliveryDate(orderLines))
            } else {
                try {
                    throw new Error('Could not save products to activeInstallationData')
                }
                catch(e) {
                    console.error(e)
                }
            }
            this.loadingProducts = false
            EventBus.$emit('function-activity', {functionName: 'Booking_attachProductsToActiveData', isActive: false})
        },

        onEditSettingsButtonClicked(){
            this.editSettings = true
            // console.log("Opening settings modal")
        },

        onAddCustomAppointmentButtonClicked() {
            this.onReceiveItemClicked(null) //Clear active installation
            this.editCustomAppointmentModalOpen = true //Open modal for adding custom appointment
        },

        async onReceiveItemClicked(installationLabel) { //The user clicked on an installation

            if (this.activeInstallationLabel && this.activeInstallationLabel == installationLabel) {
                installationLabel = null //If the function is called with the already active installation, reset it to null, to reset the active installation to null
            }

            this.showBookingModal = false
            // console.log(installationLabel)
            
            if (this.activeInstallationData){ //If any installation was previously selected active
                this.updateMarkerType(this.activeInstallationData, installationLabel) //Revert the previously 'active' mapMarker to its inactive type
            }

            if (!installationLabel) {
                this.activeInstallationLabel = null
                this.activeInstallationData = null
                this.loadingInstallationData = false
                this.subtaskStatusClicked()
            }

            this.sleep(50).then(() => { //Wait 50ms before updating marker type, to ensure activeInstallationLabel has been set
            })

            if (this.activeInstallationLabel != installationLabel) {
                this.subtaskStatusClicked() //Ensure subtask stateChangeModal is closed
                // this.showBookingModal = false
                this.bookingFormData = {}
                this.activeInstallationLabel = installationLabel //Triggers watcher

                this.scrollToRef(this.$refs.activeInstallationCard);
            }

            // Nothing more happens here because the rest is triggered via watchers.
        },

        appointmentExists(label) {
            return this.appointments.some((a) => {
                return a.InstallationLabel == label
            })
        },

        async deleteConnectionDate(){
            EventBus.$emit('function-activity', {functionName: 'Booking_deleteConnectionDate', isActive: true})
            
            let shouldDelete = await swal({
                title: 'Er du sikker?',
                icon: 'warning',
                buttons: true,
                dangerMode: true,
            })

            if (!shouldDelete) {
                EventBus.$emit('function-activity', {functionName: 'Booking_deleteConnectionDate', isActive: false})
                return false
            }
            
            let projectTaskId = this.activeInstallationData.tasks[0].id

            await this.dataDeleteConnectionDate(projectTaskId)
            await this.setActiveInstallationData(true)
            EventBus.$emit('function-activity', {functionName: 'Booking_deleteConnectionDate', isActive: false})
            return true
        },

        async populateBookingFormData(appointmentId) {
            EventBus.$emit('function-activity', {functionName: 'Booking_populateBookingFormData', isActive: true})
            let appointment
            if (appointmentId){
                appointment = await this.dataGetAppointmentById(this.project.id, appointmentId)
            } 

            if (appointment) {
                let timeWindowObj = this.readTimeWindowString(appointment.TimeWindowString)
                let worker = null
                if (appointment.Worker) {
                    let workerOption = this.availableWorkers.find(w => String(w.value).toLowerCase() == String(appointment.Worker.Email).toLowerCase())
                    if (workerOption) {
                        worker = workerOption.value
                    }
                }

                let address = this.getConfiguration(this.activeInstallationData.tasks[0]).address

                this.bookingFormData = {
                    appointmentId: appointment.id,
                    appointmentType: appointment.AppointmentType,
                    date: timeWindowObj.Date.replaceAll("/","-"),
                    timeFrom: timeWindowObj.Time.From,
                    timeTo: timeWindowObj.Time.To,
                    confirmed: appointment.Confirmed,
                    worker: worker,
                    contacts: appointment.Contacts,
                    sendReminder: appointment.SendReminder,
                    flag: appointment.Flag,
                    lock: appointment.Lock,
                    label: appointment.InstallationLabel,
                    address: this.formatAddress(address, false),
                    addressShort: this.formatAddress(address, true),
                    callInAdvance: appointment?.CallInAdvance?.call || false,
                    callInAdvanceDuration: appointment?.CallInAdvance?.duration || '',
                    callInAdvancePhone: appointment?.CallInAdvance?.phone || '',
                    callInAdvanceNoteBody: appointment?.CallInAdvance?.noteBody || '',
                    isOnCancellationList: appointment?.IsOnCancellationList || false,
                    changeLog: appointment?.changeLog || [],
                    changeLogOverflow: appointment?.changeLogOverflow || false,
                    metadata: appointment?.metadata || null,
                }
            } else {
                this.bookingFormData = { 
                    label: this.activeInstallationLabel,
                }
            }
            EventBus.$emit('function-activity', {functionName: 'Booking_populateBookingFormData', isActive: false})
            return this.bookingFormData
        },

        async onBookButtonClick(appointmentId, maxRetries = 10, currentRetry = 0) {
            if (appointmentId == null && this.activeInstallationData && Object.keys(this.activeInstallationData.appointments).length != 0 && typeof(appointmentId) != 'undefined'){
                let swalResponse = await swal({
                    title: 'Der findes allerede en aftale på denne installation!',
                    text: "Ønsker du at?",
                    icon: 'warning',
                    buttons: {
                        cancel: {
                            text: 'Annullére',
                            value: 'CANCEL',
                            visible: true,
                        },
                        new: {
                            text: 'Oprette Ny Aftale',
                            visible: true,
                            className: 'swalDangerBtn',
                        },
                        edit: {
                            text: 'Redigére',
                            visible: true,
                        },
                    },
                })

                if (swalResponse == 'CANCEL') return
                if (swalResponse == 'edit'){
                    let appointmentKeys = Object.keys(this.activeInstallationData.appointments)
                    if (appointmentKeys.length < 2){
                        appointmentId = appointmentKeys[0]
                    } else {
                        
                        const appointments = this.cloneJson(this.activeInstallationData?.appointments)
                        this.appointmentOptionsNoNew = []
                        for (let key of appointmentKeys) {
                            let appointment = appointments[key]
                            this.appointmentOptionsNoNew.push({
                                value: key,
                                text: this.toTitleCase(AppointmentType.translate(appointment.AppointmentType)) + ' ' + this.formatDate(appointment.Date)
                            })
                        }
                       this.openSelectAppointmentToEdit = true
                       return
                        
                    }
                }
                
            }

            EventBus.$emit('function-activity', {functionName: 'Booking_onBookButtonClick', isActive: true})
            this.showBookingModal = !this.showBookingModal //Toggle the visibility of the booking modal
            if (!this.showBookingModal) { //If booking modal was just closed, stop the function here
                EventBus.$emit('function-activity', {functionName: 'Booking_onBookButtonClick', isActive: false})
                return
            }

            this.loadingSaveBooking = true //Start loading animation on booking modal

            let retry = false
            if (!this.activeInstallationData || !this.activeInstallationData.tasks[0]) retry = true

            if (retry) {
                if (currentRetry < maxRetries) {
                    // console.log("trying again at time:", performance.now())
                    setTimeout(async () => {await this.onBookButtonClick(appointmentId, maxRetries, currentRetry+1)}, 100)
                } else {
                    console.error(`onBookButtonClick function exceeded max retries (${maxRetries})`)
                }
                EventBus.$emit('function-activity', {functionName: 'Booking_onBookButtonClick', isActive: false})
                return
            }

            await this.populateBookingFormData(appointmentId)

            this.loadingSaveBooking = false
            EventBus.$emit('function-activity', {functionName: 'Booking_onBookButtonClick', isActive: false})

            if (this.insContainsOnlyState(this.activeInstallationData, TaskState.PENDING)) {
                swal('Advarsel', 'Denne installation har status `Pending`, er du sikker på du vil booke den?', 'warning')
            }
        },

        changeLoadingStep(arrayName, code, state){
            // console.log("changeLoadingStep array:", this[arrayName].length, "code:", code, "state:", state)
            let stepIndex = this[arrayName].findIndex(step => step.code == code)
            if (stepIndex == -1) {
                console.error(`Could not find step with code ${code} in this.${arrayName}`)
                return
            }
            let stepObj = this[arrayName][stepIndex]
            stepObj.state = state
            this.$set(this[arrayName], stepIndex, stepObj)
            return stepObj
        },

        /**
         * Function to save booking to databases, includes multi-step-loader events.
         * Warns user if no worker selected, and sanitizes worker data.
         * Formats data to be sent to database, and checks for missing data.
         * Sends data to firebase and PilotBI, using the dataSaveBooking function.
         * Saves unitWork for booking.
         * Logs booking to analytics.
         * Gets updated data from PilotBI, using setActiveInstallationData() with forceFetch = true.
         * @param {Object} payload Massive object containing all data needed to save booking, see below for details
         * @param {String || null} payload.appointmentId Id of appointment to be saved, null for new appointment
         * @param {String} payload.appointmentType Type of appointment, from @/lib/Enums/AppointmentType.js enum, e.g. 'installation'
         * @param {String} payload.date Date of appointment, as produced by html input of date type, e.g. '2021-01-01'
         * @param {String} payload.timeFrom Start-time of appointment, as input by user, either as hours or hours:minutes, e.g. '10' or '10:30'
         * @param {String} payload.timeTo End.time of appointment, as input by user, either as hours or hours:minutes, e.g. '10' or '10:30'
         * @param {Boolean} payload.confirmed Whether appointment is confirmed or not (will only send to PilotBI if confirmed)
         * @param {String} payload.worker Email of the worker to be assigned to appointment, will be looked up in firebase to get additional data
         * @param {Array<String>} payload.contactMobiles Array of mobile numbers to be added to appointment, for SMS reminders
         * @param {Array<String>} payload.contactEmails Array of email addresses to be added to appointment, for email reminders
         * @param {Boolean} payload.sendReminder Whether to send reminder to contacts or not
         * @param {Boolean} payload.flag Whether to flag appointment or not
         * @param {Boolean} payload.lock Whether to lock appointment or not
         * @param {{label: String, tasks: Array<Object>}} payload.installationData Data about installation, containing minimum: label, tasks
         */
        async onSaveBooking(payload) {
            EventBus.$emit('function-activity', {functionName: 'Booking_onSaveBooking', isActive: true})
            
            const { 
                appointmentId, 
                appointmentType, 
                date, 
                timeFrom, 
                timeTo, 
                confirmed, 
                worker, 
                contactMobiles, 
                contactEmails, 
                sendReminder, 
                flag, 
                lock, 
                isHouseAssociation,
                installationData, 
                isOnCancellationList, 
                cancellationListTimestamp, 
                callInAdvance, 
                changeLog, 
                changeLogOverflow, 
                sendToEFB, 
                user, 
                metadata, 
                externalUpdateNote 
            } = payload //Unpack payload

            if (!installationData) {
                EventBus.$emit('function-activity', {functionName: 'Booking_onSaveBooking', isActive: false})
                return null
            }

            this.saveBookingLoadingSteps = [
                {
                    code: 'VALIDATE_DATA',
                    title: 'Validér data',
                    state: 'in progress',
                },
                {
                    code: 'UPDATE_BOOKING',
                    title: 'Opdater aftale i databaser',
                    state: 'pending',
                },
                {
                    code: 'UNIT_WORK',
                    title: 'Notér evt enheds-arbejde',
                    state: 'pending',
                },
                {
                    code: 'GET_LATEST_TASK_DATA',
                    title: 'Hent opdateret data',
                    state: 'pending',
                },
            ]
            this.loadingSaveBooking = true

            if (this.worker === null && this.confirmed) { //Warn user if booking is confirmed but no worker is selected
                let resp = await swal('Advarsel', { //Await user response to popup
                    icon: 'warning',
                    text: 'Du har ikke valgt en medarbejder. Vil du stadig gennemføre bookingen?',
                    dangerMode: true,
                    buttons: ['Annuller', 'Ja'],
                })
                if (resp == null) { //User chose to cancel the booking
                    this.loadingSaveBooking = false
                    EventBus.$emit('function-activity', {functionName: 'Booking_onSaveBooking', isActive: false})
                    return
                }
            }

            try {
                let workerData = null
                if (worker) {
                    workerData = this.availableWorkersData.find(w => w && this.lowercase(w.Email) == this.lowercase(worker)) // Find workerdata on selected worker
                    if (workerData) {
                        workerData = {
                            Email:  workerData.Email,
                            Name:   workerData.Name || null, //Guarded against undefined
                            Mobile: workerData.Mobile || null //Guarded against undefined
                        }
                    }
                }

                if (worker && !workerData) {
                    this.loadingSaveBooking = false
                    EventBus.$emit('function-activity', {functionName: 'Booking_onSaveBooking', isActive: false})
                    throw new Error(`Selected worker was not found. Data entry was expected in 'availableWorkersData':\n${JSON.stringify(this.availableWorkersData, null, 4)}`)
                }

                const state = AppointmentState.ACTIVE
                const _addressObj = this.getConfiguration(installationData.tasks[0]).address
                const addressLong = this.formatAddress(_addressObj, false)
                const addressShort = this.formatAddress(_addressObj, true)

                if(!installationData.label){
                    EventBus.$emit('function-activity', {functionName: 'Booking_onSaveBooking', isActive: false})
                    throw new Error("Booking must contain an Installation Label")
                }

                let taskArray = installationData.tasks
                if(this.activeServiceOrder){ //TODO: Rewrite to take multiple service orders
                    for (let i in this.activeServiceOrder.project.tasks) {
                        let task = this.activeServiceOrder.project.tasks[i]
                        if (!this.objExistsInArray("code",task.code,taskArray)) {
                            if (TaskCode.AllArray.includes(task.code)){
                                taskArray.push(task)
                            }
                        }
                    }
                } else {
                    console.error('saving booking without serviceOrder\nThis might result in tasks being ignored')
                }

                let projectType = installationData.tasks[0].project?.type?.value
                if (!projectType) { //If there is no project type, try to guess if from the project definition //TODO: Get rid of this before implementing moving tasks between projects, as this would mean a task does not necessarily match the project definition
                    let types = this.project.Type
                    let cabinetIndex = types.indexOf("2")
                    if ( cabinetIndex >= 0 ) {
                        types.splice(cabinetIndex, 1) //Remove cabinet type, as cabinets do not have appointments
                    }
                    if (types.length == 1) {
                        projectType = types[0] //If there is only one type (apart from cabinets), use that
                    } else {
                        types.sort((a,b) => a > b ? 1 : -1) //Sort types by number in ascending order
                        projectType = types[0] //Select the lowest type number //TODO: Ensure this is used as little as possible, it is bad practice!
                    }
                }

                this.changeLoadingStep('saveBookingLoadingSteps', 'VALIDATE_DATA', 'success')
                try {
                    this.changeLoadingStep('saveBookingLoadingSteps', 'UPDATE_BOOKING', 'in progress')
                    await this.dataUpdateBooking( //Update booking in firebase AND PilotBI
                        this.project.id,
                        installationData.label,
                        taskArray,
                        appointmentType,
                        appointmentId,
                        {
                            appointmentType,
                            timeWindowString: this.makeTimeWindowString(date, timeFrom, timeTo),
                            confirmed: !!confirmed,
                            worker: workerData,
                            contactMobiles,
                            contactEmails,
                            sendReminder,
                            flag,
                            lock,
                            isHouseAssociation,
                            state,
                            addressLong,
                            addressShort,
                            projectType,
                            referenceId: installationData.tasks[0].referenceId,
                            cabinet: {Hub: this.getHubFromASRName()},
                            callInAdvance,
                            isOnCancellationList,
                            cancellationListTimestamp,
                            changeLog,
                            changeLogOverflow,
                            sendToEFB,
                            user,
                            metadata,
                            externalUpdateNote
                        })
                        console.log("Technical data", this.getConfiguration(installationData.tasks[0])?.technicalData)
                        this.changeLoadingStep('saveBookingLoadingSteps', 'UPDATE_BOOKING', 'success')
                } catch (error) {
                    // console.error(error)
                    this.changeLoadingStep('saveBookingLoadingSteps', 'UPDATE_BOOKING', 'error')
                    throw new Error(error) //Re-throw error to stop function
                }
                
                try { //Save Unit Work for having spoken to the customer about the booking
                    this.changeLoadingStep('saveBookingLoadingSteps', 'UNIT_WORK', 'in progress')

                    let config = this.getConfiguration(installationData.tasks[0])
                    if (payload.talkedToCustomer){
                        let pit = null
                        if (installationData.tasks[0]?.project?.type?.value) {
                            pit = installationData.tasks[0].project.type.value
                        }
                        if (installationData.tasks[0].code == TaskCode.TICKET){
                            pit = 'TroubleTickets'
                        }
                        if (!pit && this.project.id === '31Kab0br5wfyWGpFxydR') { // If project is 'Nyborg 500G' and no project type is found
                            pit = '6' // Set project type to '6' (Installationsprojekt Opsamling)
                        }
                        let unitWorkDocument = {
                            AreaCode: config?.area?.sonWinProjectId,
                            ProjectInstallationType: pit,
                            PayedBy: 'EFB',
                            Description: 'Telefonisk kontakt med kunde',
                            ConfigurationItem: {
                                Label: this.getConfigurationLabel(installationData.tasks[0]),
                                Area: config?.area || null,
                                Cabinet: config?.cabinet || null,
                                TechnicalData: config?.technicalData || null,
                                Type: config?.type || null,
                                Value: config?.value || null,
                                Address: this.formatAddress(config?.address, false) || null,
                            },
                            ReferenceId: installationData.tasks[0].referenceId,
                            Workers: [this.user.email],
                            TimeStamp: new Date(),
                            CreatedBy: {
                                Name: this.user.displayName,
                                Email: this.user.email,
                                Initials: this.user?.email?.substr(0, this.user.email.indexOf('@')).toUpperCase()
                            },
                            AutoGenerated: true,
                            Date: this.formatMachineDate(new Date(), '-'),
                            Billed: {
                                Bool: false,
                                Updated: new Date()
                            },
                            LinkedProjects: [this.$route.params.projectIdentifier],
                            FromDocumentation: {},
                        }
                        unitWorkDocument.Unit = {
                            Id: this.unitWorkCompositeUnits.find((u) => u.id == '951.F.01').id, //Fibertekniker timer
                            ...this.unitWorkCompositeUnits.find((u) => u.id == '951.F.01')
                        }
                        let amount = 0.25
                        unitWorkDocument.Amount = amount
                        unitWorkDocument.EquivalentHours = this.calculateEquivalentHours(unitWorkDocument.Unit.Id, amount)
                        unitWorkDocument.Price = this.calculatePrice(unitWorkDocument.Unit.Id, amount)


                        await this.dataAddOrUpdateUnitWork(unitWorkDocument)
                    }

                    let filteredUnitWork = this.activeInstallationUnitWork.filter(uw => uw.Unit.Id == '931.F.07')
                    if (payload.confirmed && !payload.talkedToCustomer && !filteredUnitWork.length && appointmentType != AppointmentType.TICKET){

                        let pit = null
                        if (installationData.tasks[0]?.project?.type?.value) {
                            pit = installationData.tasks[0].project.type.value
                        }
                        if (!pit && this.project.id === '31Kab0br5wfyWGpFxydR') { // If project is 'Nyborg 500G' and no project type is found
                            pit = '6' // Set project type to '6' (Installationsprojekt Opsamling)
                        }

                        let unitWorkDocument = {
                            AreaCode: config?.area?.sonWinProjectId,
                            ProjectInstallationType: pit,
                            PayedBy: 'EFB',
                            Description: 'Aflevering af varslingskort',
                            ConfigurationItem: {
                                Label: config?.label,
                                Area: config?.area,
                                Cabinet: config?.cabinet,
                                TechnicalData: config?.technicalData,
                                Type: config?.type,
                                Value: config?.value,
                            },
                            Workers: [this.user.email],
                            TimeStamp: new Date(),
                            CreatedBy: {
                                Name: this.user.displayName,
                                Email: this.user.email,
                                Initials: this.user?.email?.substr(0, this.user.email.indexOf('@')).toUpperCase()
                            },
                            AutoGenerated: true,
                            Date: this.formatMachineDate(new Date(), '-'),
                            Billed: {
                                Bool: false,
                                Updated: new Date()
                            },
                            LinkedProjects: [this.$route.params.projectIdentifier],
                            FromDocumentation: {},
                        }
                        unitWorkDocument.Unit = {
                            Id: this.unitWorkCompositeUnits.find((u) => u.id == '931.F.07').id, //Aflevering af varslingskort akkord
                            ...this.unitWorkCompositeUnits.find((u) => u.id == '931.F.07')
                        }
                        let amount = 1
                        unitWorkDocument.Amount = amount
                        unitWorkDocument.EquivalentHours = this.calculateEquivalentHours(unitWorkDocument.Unit.Id, amount)
                        unitWorkDocument.Price = this.calculatePrice(unitWorkDocument.Unit.Id, amount)


                        await this.dataAddOrUpdateUnitWork(unitWorkDocument)
                    }

                    this.changeLoadingStep('saveBookingLoadingSteps', 'UNIT_WORK', 'success')
                } catch (error) {
                    swal('Kunne ikke gemme ekstra-arbejde', `Der skete en fejl, så ekstra-arbejdet for telefonisk kontakt med kunden ikke blev gemt, prøv at skrive det manuelt.\nFejlmeddelelse:\n${error.message}`, 'error')
                    this.changeLoadingStep('saveBookingLoadingSteps', 'UNIT_WORK', 'error')
                }

                let anonymizedPayload = payload
                delete anonymizedPayload.contactMobiles
                delete anonymizedPayload.contactEmails
                analytics.logEvent('booking_update', anonymizedPayload)
            }
            catch (error) {
                if (error instanceof NetworkError) {
                    swal('Netværks fejl', `Der opstod en netværks fejl, prøv venligst igen.\n\n${error.message}`, 'error')
                } else {
                    swal('Ukendt fejl', error.message, 'error')
                }
                console.error(error)
            }

            this.changeLoadingStep('saveBookingLoadingSteps', 'GET_LATEST_TASK_DATA', 'in progress')
            if (sendToEFB){
                try { //TODO: This code is hopelessly inefficient, and should probably be written over from scratch
                    
                    await this.setActiveInstallationData(true) //Retrieve latest task data
                    await this.populateBookingFormData() //TODO: populate with appointment ID
        
                    this.changeLoadingStep('saveBookingLoadingSteps', 'GET_LATEST_TASK_DATA', 'success')
                } catch (error) {
                    console.error(error)
                    this.changeLoadingStep('saveBookingLoadingSteps', 'GET_LATEST_TASK_DATA', 'error')
                }
            }

            // Firebase you're just too fast..
            setTimeout(() => {
                this.loadingSaveBooking = false
                this.showBookingModal = false
                EventBus.$emit('function-activity', {functionName: 'Booking_onSaveBooking', isActive: false})
            }, 500)
        },

        /**
         * Populates this.activeInstallationData object, based on this.activeInstallationLabel
         * @param {Boolean} forceFetch Weather or not to disregard cached data, and get new data from PilotBI
         */
        async setActiveInstallationData(forceFetch = false) {
            this.instDataReloadIconSpinning = true
            const installationLabel = this.activeInstallationLabel
            if (!this.activeInstallationLabel) {
                return
            }

            EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: true})

            // TODO: add loading steps
            // this.installationDataLoadingSteps = [
            //     {
            //         code: '',
            //         title: '',
            //         state: '',
            //     },
            // ]
            this.loadingInstallationData = false
            this.activeInstallationData = null

            const foundIns = this.installations.find(ins => {
                return ins.label == installationLabel
            })
            let activeIns = this.cloneJson(foundIns)

            this.updateMarkerType(activeIns, this.activeInstallationLabel) //Update the map marker

            if (!activeIns || forceFetch) {

                // Get ProjectTasks
                if (this.loadProjectTasks) {
                    try {
                        var serviceOrders = await this.dataGetAllServiceOrdersByInst(installationLabel, this.settingsBD.get('showClosed'))
                    } catch (error) {
                        console.error('Error getting serviceOrders:',error)
                        EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: false})
                        this.instDataReloadIconSpinning = false
                        throw error
                    }
                    for (let serviceOrder of serviceOrders) {
                        if (!serviceOrder?.project?.tasks?.length) continue;
                        for (let task of serviceOrder.project.tasks) {
                            try {
                                let fullTask = await this.dataGetProjectTask({id: task.id, number: task.number}, true)
                                this.setOrUpdateTaskInRawTasks(fullTask, true)
                            } catch (error) {
                                console.error(`Error getting task ${task.number}`, error)
                            }
                        }
                    }
                }

                // Get TroubleTickets
                if (this.loadTickets) {
                    try {
                        var troubleTickets = await this.dataGetTroubleTicketsByInst(installationLabel)
                    } catch (error) {
                        console.error('Error getting troubleTickets:',error)
                        EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: false})
                        this.instDataReloadIconSpinning = false
                        throw error
                    }
                    for (let ticket of troubleTickets){
                        if (ticket.urgency && !forceFetch){
                            this.setOrUpdateTaskInRawTasks(ticket, true)
                        } else {
                            try {
                                let fullTicket = await this.dataGetTroubleTicket(ticket.id, ticket.installation.number)
                                this.setOrUpdateTaskInRawTasks(fullTicket, true)
                            } catch (error) {
                                console.error(`Error getting ticket ${ticket.installation.number}`, error)
                            }
                        }
                    }
                }
                
                try {
                    await this.bookFormatBookingData(false)
                } catch (error) {
                    console.error('Error formatting booking data: ',error)
                    EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: false})
                    this.instDataReloadIconSpinning = false
                    throw error
                }

                const foundInsAgain = this.installations.find(ins => {
                    return ins.label == installationLabel
                })
                activeIns = this.cloneJson(foundInsAgain)

                //Extra check to ensure all task are full tasks
                let updatedTasks = 0
                if (activeIns?.tasks){
                    for (let task of activeIns.tasks){
                        if (!task.isFullTask){
                            try {
                                updatedTasks += 1
                                let fullTask = await this.dataGetProjectTask({id: task.id, number: task.number})
                                this.setOrUpdateTaskInRawTasks(fullTask, true)
                            } catch (error) {
                                console.error(`Error getting task ${task.number}`, error)
                            }
                        }
                    }
                }

                if (updatedTasks){
                    try {
                        await this.bookFormatBookingData(false)
                    } catch (error) {
                        console.error('Error formatting booking data: ',error)
                        EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: false})
                        this.instDataReloadIconSpinning = false
                        throw error
                    }
                }
            }

            if (!activeIns) {
                if (this.customAppointmentIds && this.customAppointmentIds.includes(installationLabel)){
                    for (let i in this.bookedInstallations) {
                        let shouldBreak = false
                        for (let j in this.bookedInstallations[i]){
                            if (this.bookedInstallations[i][j].label == installationLabel) {
                                activeIns = this.bookedInstallations[i][j]
                                shouldBreak = true
                                break
                            }
                        }
                        if (shouldBreak) break
                    }
                    this.loadingInstallationData = false
                } else {
                    swal('Kunne ikke finde installation', 'Installation blev desværre ikke fundet i systemet ved en fejl.', 'error')
                    this.loadingInstallationData = false
                    EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: false})
                    this.instDataReloadIconSpinning = false
                    throw new Error('setActiveInstallationData: Installation not found in system')
                }
            }

            if (this.activeInstallationLabel != installationLabel) { //Ensure the active installation has not changed
                console.warn(`activeInstallationLabel changed from ${installationLabel} to ${this.activeInstallationLabel} while setting active installation data, quitting function`)
                EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: false})
                this.instDataReloadIconSpinning = false
                return
            }
            
            this.activeInstallationData = activeIns

            if (this.loadProjectTasks){
                try {
                    const serviceOrders = await this.getServiceOrders(forceFetch) //OBS: Lots of important side-effects for this function, apart from its return value
                    for (let so of serviceOrders) {
                        for (let task of so.project.tasks) {
                            const i = activeIns.tasks.findIndex(t => t.id == task.id)
                            if (i == -1) {
                                activeIns.tasks.push(task)
                                activeIns.tasks.sort((a, b) => a.number - b.number)
                            }
                        }
                        await this.attachProductsToActiveData(so) //Get products for each serviceOrder, and add them to the active installation
                    }
                } catch (error) {
                    console.error(error)
                }
            }

            if (this.activeInstallationLabel != installationLabel) { //Ensure the active installation has not changed
                console.warn(`activeInstallationLabel changed from ${installationLabel} to ${this.activeInstallationLabel} while setting active installation data, quitting function`)
                EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: false})
                this.instDataReloadIconSpinning = false
                return
            }
            
            try {
                await this.getTechnicals(forceFetch)
            } catch (error) {
                console.log(error)
            }

            if (this.activeInstallationLabel != installationLabel) { //Ensure the active installation has not changed
                console.warn(`activeInstallationLabel changed from ${installationLabel} to ${this.activeInstallationLabel} while setting active installation data, quitting function`)
                EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: false})
                this.instDataReloadIconSpinning = false
                return
            }

            if (this.loadProjectTasks){
                try {
                    let hubNr = this.getHubNumber()
                    if (hubNr) {
                        await this.$bind('activeHub',db.collection('HUBs').doc(hubNr)) // Get Hub data 
                    }
                } catch (error) {
                    console.error(error)
                }
            }

            if (this.activeInstallationLabel != installationLabel) { //Ensure the active installation has not changed
                console.warn(`activeInstallationLabel changed from ${installationLabel} to ${this.activeInstallationLabel} while setting active installation data, quitting function`)
                EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: false})
                this.instDataReloadIconSpinning = false
                return
            }

            let activeTask = activeIns.tasks.find(task => task.id == this.activeTask.id)
            if (activeTask) {
                this.activeTask = activeTask
            }

            this.activeInstallationData = activeIns

            // Update allTasksComplete state on appointment if any
            if (this.activeInstallationData?.appointments){
                if (Object.keys(this.activeInstallationData.appointments).length){
                    for (let appointmentID in this.activeInstallationData.appointments){
                        this.allTasksComplete(appointmentID,true)
                    }
                } else 
                {
                    for (let task of this.activeInstallationData.tasks){
                        this.allTasksComplete(task.id)
                    }
                }
            }

            EventBus.$emit('function-activity', {functionName: 'Booking_setActiveInstallationData', isActive: false})
            this.instDataReloadIconSpinning = false
            return
        },

        async checkMissingCoordinateStateForModal(payload) {
            if (payload != 'closing') return
            if (this.installationMissingCoordinates.length == 0) return
            EventBus.$emit('function-activity', {functionName: 'Booking_checkMissingCoordinateStateForModal', isActive: true})
            if (this.coordinateImportStage == 0) {
                this.ignoreCoordinateImport = true
                this.bookFormatBookingData(false)
                EventBus.$emit('function-activity', {functionName: 'Booking_checkMissingCoordinateStateForModal', isActive: false})
                return
            }

            if (this.coordinateImportStage == 2) {
                EventBus.$emit('function-activity', {functionName: 'Booking_checkMissingCoordinateStateForModal', isActive: false})
                return
            }

            if (this.ignoreCoordinateImport) {
                this.bookFormatBookingData(false)
                EventBus.$emit('function-activity', {functionName: 'Booking_checkMissingCoordinateStateForModal', isActive: false})
                return
            }
            
            let resp = await swal('Advarsel', {
                icon: 'warning',
                text: 'Ønsker du at stoppe processen?',
                dangerMode: true,
                buttons: ['Nej, gå tilbage', 'Stop process']
            })
            
            if (resp) {
                this.ignoreCoordinateImport = true
                clearInterval(this.coordinatesImportIntervalLoopRef)
                this.bookFormatBookingData(false)
                EventBus.$emit('function-activity', {functionName: 'Booking_checkMissingCoordinateStateForModal', isActive: false})
                return
            }
            
            this.showCoordinateImportModal = true
            EventBus.$emit('function-activity', {functionName: 'Booking_checkMissingCoordinateStateForModal', isActive: false})
        },

        async getAttachment(attachmentId){
            EventBus.$emit('function-activity', {functionName: 'Booking_getAttachment', isActive: true})
            let response = await this.dataGetAttachment(attachmentId)
            EventBus.$emit('function-activity', {functionName: 'Booking_getAttachment', isActive: false})
            return response
        },

        async openAttachment(attachmentId){
            EventBus.$emit('function-activity', {functionName: 'Booking_openAttachment', isActive: true})
            this.openFileLoading = true //Start loading animation
            this.openFileModalOpen = true //Open modal
            this.openFile = await this.getAttachment(attachmentId) //Get attachment from API
            this.openFileLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'Booking_openAttachment', isActive: false})
        },

        openUploadNoteModal(){
            this.noteModalOpen = true //Open the note modal
        },

        async postNote(){
            EventBus.$emit('function-activity', {functionName: 'Booking_postNote', isActive: true})
            let bookId = this.activeServiceOrder?.project?.tasks?.find(task => task.code == TaskCode.BOOKKUNDE)?.id
            let isTicket = false
            if (!bookId){
                let firstTask = this.findFirstTask(this.activeInstallationData.tasks)
                bookId = firstTask.id
                if (firstTask.code != TaskType.TICKET){
                    isTicket = true
                }
            }
            let response
            if (!isTicket){
                response = await this.dataPostNote(bookId,this.activeInstallationLabel,this.noteText,this.noteType,this.$route.params.projectIdentifier)
            } else {
                response = await this.dataPostNote(bookId,this.activeInstallationLabel,this.noteText,this.noteType,this.$route.params.projectIdentifier,'TroubleTickets/Note','')
            }
            EventBus.$emit('function-activity', {functionName: 'Booking_postNote', isActive: false})
            return(response)
        },

        async submitNote(){
            EventBus.$emit('function-activity', {functionName: 'Booking_submitNote', isActive: true})
            this.noteFormLoading = true //Start loading animation
            await this.postNote() //Post note to database
            this.noteText = "" //Clear textfield
            this.noteType = "" //Clear radio-buttons
            await this.setActiveInstallationData(true) //Retrieve latest task data
            this.noteModalOpen = false //Close modal
            this.noteFormLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'Booking_submitNote', isActive: false})
        },

        openCustomAppointmentNoteModal(){
            this.CAnoteModalOpen = true
        },

        async submitCANote(){
            EventBus.$emit('function-activity', {functionName: 'Booking_submitCANote', isActive: true})
            this.CAnoteFormLoading = true //Start loading animation
            await db.collection('CustomAppointments').doc(this.activeInstallationLabel).update({ //Update the database
                Notes: FieldValue.arrayUnion({ //arrayUnion ensures the note is added without risk of overwriting other notes
                    authorEmail: this.user.email,
                    configurationItemLabel: 'CUSTOMAPPOINTMENT',
                    noteBody: `@${this.user.displayName}:\n${this.CAnoteText}`,
                    projectTaskId: 'CUSTOMAPPOINTMENT',
                    timeStamp: this.formatMachineTimestamp(new Date()),
                })
            })
            this.CAnoteText = null, //Clear textfield
            this.CAnoteFormLoading = false //End loading animation

            // this.getCustomAppointments(this.sorting) //Not neccesary, since it is already a listener
            let date = this.activeInstallationData.appointment.Date //The date of the custom appointment, for use below
            // console.log(this.bookedInstallations, date, this.bookedInstallations[date])
            this.activeInstallationData = this.bookedInstallations[date].find((i) => i.label == this.activeInstallationLabel) //Render newest data to blue Card

            this.CAnoteModalOpen = false //close modal
            EventBus.$emit('function-activity', {functionName: 'Booking_submitCANote', isActive: false})
        },

        onEditCustomAppointmentButtonClick(){
            this.editCustomAppointmentModalOpen = true
        },

        openFileModal(){
            this.uploadFileModalOpen = true //Open the file modal
        },

        setFileUpload(e){
            var files = e.target.files || e.dataTransfer.files;
            if (!files.length) {
                this.fileUpload = null
                return false
            }
            this.fileUpload = files[0]
            return true
        },

        async encodeFile(file){
            EventBus.$emit('function-activity', {functionName: 'Booking_encodeFile', isActive: true})
            const toBase64 = file => new Promise((resolve, reject) => {
                const reader = new FileReader()
                reader.readAsDataURL(file)
                reader.onload = () => resolve (reader.result)
                reader.onerror = error => reject(error)
            })
            let encodedFile = await toBase64(file).catch(e => Error(e))
            if(encodedFile instanceof Error) {
                console.error('Error encoding file: ',encodedFile.message)
                EventBus.$emit('function-activity', {functionName: 'Booking_encodeFile', isActive: false})
                return
            }
            EventBus.$emit('function-activity', {functionName: 'Booking_encodeFile', isActive: false})
            return encodedFile
        },

        // TODO: Merge
        async submitFile(){
            EventBus.$emit('function-activity', {functionName: 'Booking_submitFile', isActive: true})
            this.fileFormLoading = true //Start loading animation
            let base64Content = await this.encodeFile(this.fileUpload)
            base64Content = String(base64Content).substring(base64Content.indexOf('base64,')+7)
            
            let fileName = this.fileNameInput
            if (!fileName) fileName = this.fileUpload.name
            
            const expectedExtension = this.getExtenstionByMimeType(this.fileUpload.type)
            const fileExtension = this.getFileExtension(fileName)
            if (fileExtension != expectedExtension) {
                fileName += `.${expectedExtension}`
            }
            if (this.activeInstallationData.tasks[0].code != TaskCode.TICKET){
                await this.dataPostAttachment(this.activeInstallationData.tasks[0].id, fileName, this.fileUpload.type, base64Content)
            } else {
                await this.dataPostAttachment(this.activeInstallationData.tasks[0].id, fileName, this.fileUpload.type, base64Content, 'TroubleTickets/TroubleTicket')
            }
            
            // Reset modal
            this.uploadFileModalOpen = false
            this.fileNameInput = null
            this.fileFormLoading = false //End loading animation
            EventBus.$emit('function-activity', {functionName: 'Booking_submitFile', isActive: false})
            await this.setActiveInstallationData(true)
        },

        sortTasks(t1, t2) {
              return t1.number > t2.number ? 1 : -1
        },

        statusClicked(task) {
            this.activeTask = task
            this.stateModalOpen = true
        },

        openSmsModal(phoneNumber) {
            this.activePhoneNumber = phoneNumber
            this.smsModalOpen = true
        },

        subtaskStatusClicked(subtask = null) {
            if(!subtask || !subtask.code) {
                this.showSubtaskModal = false
                this.activeSubtaskResponsibleIndex = null
            }
            else if (this.activeSubtask.code == subtask.code && this.showSubtaskModal == true){
                this.showSubtaskModal = false
                this.activeSubtaskResponsibleIndex = null
            } else {
                this.activeSubtask = {...subtask}
                this.activeSubtaskValue = subtask.state.value
                this.activeSubtaskDeferred = subtask.Deferred?.Value
                if (subtask.Responsible && this.availableWorkers) {
                    this.activeSubtaskResponsibleIndex = this.availableWorkers.findIndex(worker => {
                        if (!worker) return false
                        return worker.text.toLowerCase() == subtask.Responsible.Email.toLowerCase()
                    })
                }
                this.showSubtaskModal = true
            }
        },

        /**
         * Method for closing service order tasks BLAES and SPLIDS automatically when internal subtasks are closed
         * 
         * This method can handle both a combined task with code 'BLAES' and seperate tasks
         * 
         * @param {Boolean} subtaskDeferred
         */
        async closePrjTaskIfSubtaskComplete(subtaskDeferred) {
            EventBus.$emit('function-activity', {functionName: 'Booking_closePrjTaskIfSubtaskComplete', isActive: true})

            let localActInstLabel = this.cloneJson(this.activeInstallationLabel)
            let localActInstData = this.cloneJson(this.activeInstallationData)

            // Get subtask status
            let blowComplete = this.internalSubTasks[localActInstLabel][0].state.isPositive
            let spliceComplete = this.internalSubTasks[localActInstLabel][1].state.isPositive

            let spliceServiceOrderTask = localActInstData.tasks.findIndex((task) => task.code == TaskCode.SPLIDS) // returns -1 if no such task
            console.log("Splice Task:", spliceServiceOrderTask)

            if (spliceServiceOrderTask == -1) // Blow and splice combined in one task
            {
                if (blowComplete === true && spliceComplete === true){ // If both complete: New Service Order State = CLOSED COMPLETE
                let blowSpliceIndex = localActInstData.tasks.findIndex((task) => task.code == TaskCode.BLAES)
                if (blowSpliceIndex != -1){ // If Service Order Exists 
                    if (localActInstData.tasks[blowSpliceIndex].state.value == TaskState.WORK_IN_PROGRESS){
                        await this.dataUpdateProjectTask(localActInstData.tasks[blowSpliceIndex].id,TaskState.OPEN).then(() => {this.getServiceOrders(true)})
                    }
                    this.dataUpdateProjectTask(localActInstData.tasks[blowSpliceIndex].id,TaskState.CLOSED_COMPLETE).then(() => {this.getServiceOrders(true)})
                }
                } else if (blowComplete === true || spliceComplete === true || (subtaskDeferred === true && this.activeSubtask.code == "BLOW")) { // If either complete (unless ON HOLD): New Service Order State = WORK IN PROGRESS
                    let blowSpliceIndex = localActInstData.tasks.findIndex((task) => task.code == TaskCode.BLAES)
                    if (blowSpliceIndex != -1){ // If Service Order Exists
                        if (localActInstData.tasks[blowSpliceIndex].state.value != TaskState.ON_HOLD) // If not: ON HOLD
                            this.dataUpdateProjectTask(localActInstData.tasks[blowSpliceIndex].id,TaskState.WORK_IN_PROGRESS).then(() => {this.getServiceOrders(true)})
                    }
                } else if (!blowComplete && !spliceComplete && (!subtaskDeferred && this.activeSubtask.code == "BLOW")) { // If none complete (unless ON HOLD): New Service Order State = PENDING
                    let blowSpliceIndex = localActInstData.tasks.findIndex((task) => task.code == TaskCode.BLAES)
                    if (blowSpliceIndex != -1){ // If Service Order Exists
                        if (localActInstData.tasks[blowSpliceIndex].state.value != TaskState.ON_HOLD) // If not: ON HOLD
                            this.dataUpdateProjectTask(localActInstData.tasks[blowSpliceIndex].id,TaskState.OPEN).then(() => {this.getServiceOrders(true)}) 
                    }
                }
            } else { // blow and splice in seperate tasks
                if (this.activeSubtask.code == 'BLOW' && blowComplete){
                    let blowIndex = localActInstData.tasks.findIndex((task) => task.code == TaskCode.BLAES)
                    if (blowIndex != -1){ // If Service Order Exists
                        if (localActInstData.tasks[blowIndex].state.value != TaskState.ON_HOLD){ // If not: ON HOLD
                            if (localActInstData.tasks[blowIndex].state.value == TaskState.WORK_IN_PROGRESS){
                                await this.dataUpdateProjectTask(localActInstData.tasks[blowIndex].id,TaskState.OPEN).then(() => {this.getServiceOrders(true)})
                            }
                            this.dataUpdateProjectTask(localActInstData.tasks[blowIndex].id,TaskState.CLOSED_COMPLETE).then(() => {this.getServiceOrders(true)})
                        }
                    }
                }
                if (this.activeSubtask.code == 'SPLICE' && spliceComplete){
                    let blowIndex = localActInstData.tasks.findIndex((task) => task.code == TaskCode.BLAES)
                    let spliceIndex = localActInstData.tasks.findIndex((task) => task.code == TaskCode.SPLIDS)
                    if (spliceIndex != -1){ // If Service Order Exists
                        if (localActInstData.tasks[spliceIndex].state.value != TaskState.ON_HOLD){ // If not: ON HOLD
                            if (blowIndex != -1){
                                if (localActInstData.tasks[blowIndex].state.value != TaskState.CLOSED_COMPLETE){
                                    if (localActInstData.tasks[blowIndex].state.value == TaskState.WORK_IN_PROGRESS){
                                        await this.dataUpdateProjectTask(localActInstData.tasks[blowIndex].id,TaskState.OPEN).then(() => {this.getServiceOrders(true)})
                                    }
                                   this.dataUpdateProjectTask(localActInstData.tasks[blowIndex].id,TaskState.CLOSED_COMPLETE).then(() => {this.getServiceOrders(true)})
                                }
                            }
                            this.dataUpdateProjectTask(localActInstData.tasks[spliceIndex].id,TaskState.CLOSED_COMPLETE).then(() => {this.getServiceOrders(true)})
                        }
                    }
                }
            }
            EventBus.$emit('function-activity', {functionName: 'Booking_closePrjTaskIfSubtaskComplete', isActive: false})
        },

        makeSubtaskObjFromArray(subtaskArray) {
            let subtaskObj = {}
            if(!subtaskArray){
                return subtaskObj
            }
            subtaskArray.forEach(subtask => {
                subtaskObj[subtask.code] = subtask
            })
            return subtaskObj
        },

        getApptQureyTimeString() {
            let timeString = ''
            let workingDate = new Date()
            workingDate = new Date(workingDate.setHours(12)) // Fix summer/winter time
            switch(this.settingsBD.get('bookedDuration')) {
                case 'today':
                    workingDate = new Date(workingDate.setUTCDate(workingDate.getUTCDate() + 1))
                    timeString = workingDate.toISOString().substring(0, 10).replace(/-/g, '/')
                    break;
                case '7days':
                    workingDate = new Date(workingDate.setUTCDate(workingDate.getUTCDate() + 8))
                    timeString = workingDate.toISOString().substring(0, 10).replace(/-/g, '/')
                    break;
                case '30days':
                    workingDate = new Date(workingDate.setUTCDate(workingDate.getUTCDate() + 31))
                    timeString = workingDate.toISOString().substring(0, 10).replace(/-/g, '/')
                    break;
                default:

            }
            return timeString
        },

        getAppointments(sorting) {

            if (this.settingsBD.get('bookedDuration') == 'all'){
                this.$bind('appointments', db.collection(`Appointments`)
                    .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier)
                    .where('State', '==', AppointmentState.ACTIVE)
                    .orderBy('TimeWindowString', sorting)
                ).then(() => {
                    this.appointmentsBound = true;
                })
            } else {
                const timeString = this.getApptQureyTimeString()
                this.$bind('appointments', db.collection(`Appointments`)
                    .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier)
                    .where('State', '==', AppointmentState.ACTIVE)
                    .where("TimeWindowString", "<", timeString)
                    .orderBy('TimeWindowString', sorting)
                ).then(() => {
                    this.appointmentsBound = true;
                })
            }

            this.$bind('allAppointments', db.collection(`Appointments`)
                .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier)
                .where('State', '==', AppointmentState.ACTIVE)
                .orderBy('TimeWindowString', sorting)
            ).then(() => {
                this.appointmentsBound = true;
            })
        },

        getAllInstUpdates() {
            if (this.settingsBD.get('showUnbookedCard')){
                this.$bind('allUpdates', db.collection('UpdatesToInstallations').where('State', '==', AppointmentState.ACTIVE))
            }
        },

        getCustomAppointments(sorting) {
            if (this.settingsBD.get('bookedDuration') == 'all'){
                const today = new Date()
                const todayString = today.toISOString().substr(0, 10).replace("-", "/").replace("-", "/")
                this.$bind('projectCustomAppointments', db.collection(`CustomAppointments`)
                    .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier)
                    .where('State', '==', AppointmentState.ACTIVE)
                    .where('TimeWindowString', '>=', todayString)
                    .orderBy('TimeWindowString', sorting)
                )
                this.$bind('globalCustomAppointments', db.collection(`CustomAppointments`)
                    .where('LinkedProjects', '==', [])
                    .where('State', '==', AppointmentState.ACTIVE)
                    .where('TimeWindowString', '>=', todayString)
                    .orderBy('TimeWindowString', sorting)
                )
            } else {
                const timeString = this.getApptQureyTimeString()
                const today = new Date()
                const todayString = today.toISOString().substr(0, 10).replace("-", "/").replace("-", "/")
                this.$bind('projectCustomAppointments', db.collection(`CustomAppointments`)
                    .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier)
                    .where('State', '==', AppointmentState.ACTIVE)
                    .where('TimeWindowString', '>=', todayString)
                    .where('TimeWindowString', '<', timeString)
                    .orderBy('TimeWindowString', sorting)
                )
                this.$bind('globalCustomAppointments', db.collection(`CustomAppointments`)
                    .where('LinkedProjects', '==', [])
                    .where('State', '==', AppointmentState.ACTIVE)
                    .where('TimeWindowString', '>=', todayString)
                    .where('TimeWindowString', '<', timeString)
                    .orderBy('TimeWindowString', sorting)
                )
            }


            
        },

        bindClosedAppointments(shouldBind) {
            console.log('binding/unbinding appointment data')
                if(shouldBind){
                    this.$bind('closedAppointments', db.collection(`Appointments`).where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier).where('State','==','closed').where('TimeWindowString', '>=', this.closedSince))
                } else {
                    this.$unbind('closedAppointments')
                    this.closedAppointments = []
                }
        },

        async triggerLoadBookingData() {
            // Failsafe loading of booking data. Project must be populated before this.
            if (this.project.Name) {
                let projectTypes = [...this.project.Type]
                let cabietTypeIndex = projectTypes.indexOf(String(ProjectType.CABINET))
                if (cabietTypeIndex != -1){
                    projectTypes.splice(cabietTypeIndex, 1)
                }
                await this.bookGetBaseBookingData(
                    // this.project.AreaCodes, 
                    // projectTypes, 
                    this.settingsBD.get("showPendingTasks"), 
                    this.settingsBD.get("showOnHoldTasks"), 
                    this.settingsBD.get("showResolved")
                    // this.settingsBD.get("showClosed"), 
                    // this.settingsBD.get("showClosed"), 
                    // !this.settingsBD.get("showUnbookedCard"), 
                    // this.project.ReferenceIdWhitelist, 
                    // this.project.ReferenceIdBlacklist
                ) //TODO: Option for showing closed incomplete/skipped
                this.onSettingUpdate("sortUnbookedPTBy")
            } else {
                this.loadAttempt++
                if (this.loadAttempt < 1000) {
                    setTimeout(this.triggerLoadBookingData, 100)
                } else {
                    swal('Fejl', 'Fejl i load af data. Genindlæs venligst siden.', 'error')
                }
            }
        },

        openDownloadReportModal(technical) {
            this.downloadReportActiveItem = technical
            this.downloadReportModalOpen = true
        },

        openEditTechDataModal() {
            this.editTechDataModalOpen = true
        },
        
        addNewUnitWork(note = null) {
            this.preFilledUnitWorkDescription = note
            if (typeof this.preFilledUnitWorkDescription != 'string') {
                this.preFilledUnitWorkDescription = null
            }
            this.activeUnitWork = {}
            this.editUnitWorkModalOpen = true
        },

        unitWorkClicked(unitWork){
            this.activeUnitWork = unitWork
            this.editUnitWorkModalOpen = true
        },
        
        
        // TODO: Implement for trouble tickets
        async visitInVain(inst, app){
            EventBus.$emit('function-activity', {functionName: 'Booking_visitInVain', isActive: true})
            this.loadingInstallationData = true
            this.installationDataLoadingSteps = [ // Init loading steps for multi-step-loader
                {
                    code: 'CANCEL_APP',
                    title: 'Luk aftale i database',
                    state: 'in progress',
                },
                {
                    code: 'ADD_UNIT_WORK',
                    title: 'Skriv ekstra-arbejde',
                    state: 'pending',
                },
                {
                    code: 'UPLOAD_NOTE',
                    title: 'Skriv note på opgaven i PilotBI',
                    state: 'pending',
                },
                {
                    code: 'DELETE_CONNECTIONDATE',
                    title: 'Slet ConnectionDate i PilotBI',
                    state: 'pending',
                },
                {
                    code: 'UPDATE_TASK_STATES',
                    title: 'Hent opdaterede opgave-statusser',
                    state: 'pending',
                },
                {
                    code: 'CONDITIONAL_ON_HOLD',
                    title: 'Sæt evt opgave "on hold"',
                    state: 'pending',
                },
            ]

            let appointment = app || this.appointments.find(app => app.InstallationLabel == inst.label) // Find appointment
            let inVainVisits = this.activeInstallationUnitWork.filter((unitWork) => unitWork.Unit.Id == '931.F.99') //Find previous inVainVisits

            if (!appointment) {
                swal('Kunne ikke finde aftale', 'Der opstod en fejl i forbindelse med registrering af forgæves besøg:\nKunne ikke finde aftale med kunden', 'error')
                EventBus.$emit('function-activity', {functionName: 'Booking_visitInVain', isActive: false})
                throw new Error('Could not find appointment to cancel when registering in vain visit')
            }

            //Have user write note
            let noteBody = `Kunden var ikke til stede på aftalt tidspunkt (Forsøg ${inVainVisits.length + 1})`
            let swalResponse = await swal({
                content: {
                    text: "Note for forgæves besøg",
                    element: "input",
                    attributes: {
                        placeholder: "Note der skrives på opgaven",
                        type: "textArea",
                        value: noteBody,
                    },
                },
                buttons: {
                    cancel: {
                        text: 'Annulér',
                        value: 'CANCEL',
                        visible: true,
                    },
                    confirm: {
                        text: 'Gem',
                        visible: true,
                    },
                },
            })
            if (swalResponse == 'CANCEL') {
                this.loadingInstallationData = false
                this.installationDataLoadingSteps = []
                EventBus.$emit('function-activity', {functionName: 'Booking_visitInVain', isActive: false})
                return
            } else if (swalResponse) {
                noteBody = swalResponse
            }

            // Cancel appointment in Firestore
            this.dataCancelAppointment(appointment.id).then(() => {
                this.changeLoadingStep('installationDataLoadingSteps', 'CANCEL_APP', 'success')
            }).catch((error) => {
                console.error(error)
                this.changeLoadingStep('installationDataLoadingSteps', 'CANCEL_APP', 'error')
            })

            //Add unitWork to Firestore
            this.changeLoadingStep('installationDataLoadingSteps', 'ADD_UNIT_WORK', 'in progress')
            let configurationItem = this.getConfiguration(inst.tasks[0])
            let taskCode = inst.tasks[0].code
            let unitWorkDocument = {
                AreaCode: configurationItem?.area?.sonWinProjectId,
                ProjectInstallationType: (taskCode == TaskCode.TICKET ? 'TroubleTickets' : inst.tasks[0].project?.type?.value), // TroubleTickets if ticket
                PayedBy: 'EFB',
                Description: 'Forgæves besøg',
                ConfigurationItem: {
                    Label: (taskCode == TaskCode.TICKET ? configurationItem?.number : configurationItem?.label),
                    Area: configurationItem?.area,
                    Cabinet: configurationItem?.cabinet,
                    TechnicalData: configurationItem?.technicalData || null,
                    Type: configurationItem?.type || null,
                    Value: configurationItem?.value || null,
                },
                Workers: [this.user.email],
                TimeStamp: new Date(),
                CreatedBy: {
                    Name: this.user.displayName,
                    Email: this.user.email,
                    Initials: this.getInitialsFromEmail(this.user.email)
                },
                AutoGenerated: true,
                Date: this.formatMachineDate(new Date(), '-'),
                Billed: {
                    Bool: false,
                    Updated: new Date()
                },
                LinkedProjects: [this.$route.params.projectIdentifier],
                FromDocumentation: {},
            }
            unitWorkDocument.Unit = {
                Id: this.unitWorkCompositeUnits.find((u) => u.id == '931.F.99').id,
                ...this.unitWorkCompositeUnits.find((u) => u.id == '931.F.99')
            }
            let amount = 1
            unitWorkDocument.Amount = amount
            unitWorkDocument.EquivalentHours = this.calculateEquivalentHours(unitWorkDocument.Unit.Id, amount)
            unitWorkDocument.Price = this.calculatePrice(unitWorkDocument.Unit.Id, amount)

            this.dataAddOrUpdateUnitWork(unitWorkDocument).then(() => {
                this.changeLoadingStep('installationDataLoadingSteps', 'ADD_UNIT_WORK', 'success')
            }).catch(error => {
                console.error(error)
                this.changeLoadingStep('installationDataLoadingSteps', 'ADD_UNIT_WORK', 'error')
            })

            // Add external note to task (promt user for note text, maybe pre-filled)
            let taskId = this.findLastTask(inst.tasks).id
            this.changeLoadingStep('installationDataLoadingSteps', 'UPLOAD_NOTE', 'in progress')
            let middleRequestText = (taskCode == TaskCode.TICKET ? 'TroubleTickets/Note' : 'ProjectTasks/ProjectTask')
            let endRequestText = (taskCode == TaskCode.TICKET ? '' : '/Note')
            this.dataPostNote(taskId,inst.label,noteBody,'Ekstern',this.project.id, middleRequestText, endRequestText).then(() => {
                this.changeLoadingStep('installationDataLoadingSteps', 'UPLOAD_NOTE', 'success')
            }).catch(error => {
                console.error(error)
                this.changeLoadingStep('installationDataLoadingSteps', 'UPLOAD_NOTE', 'error')
            })

            // Delete connectionDate in PilotBI API
            if (taskCode != TaskCode.TICKET){
                this.changeLoadingStep('installationDataLoadingSteps', 'DELETE_CONNECTIONDATE', 'in progress')
                await this.dataDeleteConnectionDate(taskId).then(() => {
                    this.changeLoadingStep('installationDataLoadingSteps', 'DELETE_CONNECTIONDATE', 'success')
                }).catch((error) => {
                    this.changeLoadingStep('installationDataLoadingSteps', 'DELETE_CONNECTIONDATE', 'error')
                    console.error(error)
                })
            }

            // Get task states from PilotBI
            this.changeLoadingStep('installationDataLoadingSteps', 'UPDATE_TASK_STATES', 'in progress')
            await this.bookUpdateTaskStatesForInst(inst.label).then(() => {
                this.changeLoadingStep('installationDataLoadingSteps', 'UPDATE_TASK_STATES', 'success')
            }).catch((error) => {
                console.error(error)
                this.changeLoadingStep('installationDataLoadingSteps', 'UPDATE_TASK_STATES', 'error')
            })

            this.changeLoadingStep('installationDataLoadingSteps', 'CONDITIONAL_ON_HOLD', 'in progress')
            // If this is the third time, change state of "BOOK" task to ON-HOLD Awaiting Vendor
            if (inVainVisits.length >= 2) { //If this is at least the third time an inVainVisit has been registered on this installation
                let bookTask = this.activeServiceOrder?.project?.tasks?.find(t => t.code == TaskCode.BOOKKUNDE) //Find the task with code "BOOKKUNDE"
                if (bookTask) {
                    await this.dataUpdateProjectTask(bookTask.id, TaskState.ON_HOLD, null, null, OnHoldReason.VENDOR, OnHoldReason.VENDOR_NO_CONTACT).then(() => { //Update the task state via API
                        this.changeLoadingStep('installationDataLoadingSteps', 'CONDITIONAL_ON_HOLD', 'success')
                    }).catch(error => {
                        this.changeLoadingStep('installationDataLoadingSteps', 'CONDITIONAL_ON_HOLD', 'error')
                        console.error(error)
                    })
                } else { //If there is no "BOOKKUNDE" task
                    console.error("No BOOK task in active serviceOrder")
                    this.changeLoadingStep('installationDataLoadingSteps', 'CONDITIONAL_ON_HOLD', 'error')
                }
            } else {
                this.changeLoadingStep('installationDataLoadingSteps', 'CONDITIONAL_ON_HOLD', 'success')
            }

            this.loadingInstallationData = false //TODO: delay closing loader in case of errors
            this.installationDataLoadingSteps = []
            EventBus.$emit('cancellation-list-modal-open-if-any')
            EventBus.$emit('function-activity', {functionName: 'Booking_visitInVain', isActive: false})
        },

        setProductsInRawTasks(products, instLabel) {
            if (!products || !instLabel) {
                console.error('setProductsInRawTasks ran with missing data, quitting function', products, instLabel)
                return
            }
            let orderLines = products
            if (products.orderLines) {
                orderLines = products.orderLines
            }
            let firstDeliveryDate = this.findFirstProductDeliveryDate(orderLines)
            let updatedTaskKeys = []
            for (let taskKey of this.rawTasks.keys()){
                let rawTask = this.rawTasks.get(taskKey)
                if (this.getConfiguration(rawTask).label == instLabel) {
                    updatedTaskKeys.push(taskKey)
                }
            }
            for (let key of updatedTaskKeys) {
                let task = this.rawTasks.get(key)
                task.products = orderLines
                task.firstDeliveryDate = firstDeliveryDate
                this.rawTasks.set(key, task)
            }
        },

        async getAllProducts(unbookedOnly = true, forcefetch = false, attempt = 0, maxAttempts = 10){
            EventBus.$emit('function-activity', {functionName: 'Booking_getAllProducts', isActive: true})
            console.log('Getting all products...')
            let oneMinuteAgo = new Date() //Current dateTime
            oneMinuteAgo = oneMinuteAgo.setMinutes((oneMinuteAgo.getMinutes() -1)) //Subtract 1 minute
            if (!this.productResponses || (this.bindingCachedProducts && this.bindingCachedProducts > oneMinuteAgo)) {
                if (this.bindingCachedProducts && this.bindingCachedProducts > oneMinuteAgo) { //Process of binding cache already started, less than 1 minute ago
                    if (attempt < maxAttempts) {
                        console.log('In process of binding cache, restarting getAllProducts function in 2s...')
                        return this.sleep(2000).then(async () => { //Wait 2 seconds
                            EventBus.$emit('function-activity', {functionName: 'Booking_getAllProducts', isActive: false})
                            return this.getAllProducts(unbookedOnly, forcefetch, attempt+1, maxAttempts) //Try again
                        })
                    } else {
                        EventBus.$emit('function-activity', {functionName: 'Booking_getAllProducts', isActive: false})
                        throw new Error(`Max attempts at getting all products exceeded`)
                    }
                }
                let twelveHoursAgo = new Date()
                twelveHoursAgo.setHours( twelveHoursAgo.getHours() - 12 )
                // console.log(`Cache not yet retrieved from firebase - binding to listener now...`)
                this.bindingCachedProducts = new Date()
                await this.$bind('productResponses', db.collection('cacheData/products/responseData').where('gotDataFromAPI', '>=', twelveHoursAgo)) //TODO: Filter to only include relevant responses, for fewer db reads
                // console.log(`Found ${this.productResponses.length} cached productResponses`)
                this.bindingCachedProducts = null
            }
            let installations = this.cloneJson(this.installations)
            installations = installations.filter((ins) => {
                if (unbookedOnly && ins.appointment) return false //filter out booked
                if (!forcefetch && ins.tasks[0].products?.length) return false //filter out installation that already have products
                return true
            })
            // console.log(`Looping through ${installations.length}/${this.installations.length} installations, to get their products...`)
            for (let index in installations) {
                let ins = installations[index]
                if (!ins.tasks[0]?.serviceOrder?.sonWinId) {
                    console.error(`installation ${ins.label} has no serviceOrder, cannot get products`)
                    continue;
                }
                // console.log(`Installation with index ${index} does have a serviceOrder, getting products for sonWinId ${ins.tasks[0].serviceOrder.sonWinId}`)
                this.dataGetProducts(ins.tasks[0].serviceOrder.sonWinId).then((response) => {
                    console.log(`Got products for sonWinId ${ins.tasks[0].serviceOrder.sonWinId}`)
                    if (!response){
                        console.error(`API returned no products on serviceOrder ${ins.tasks[0].serviceOrder.sonWinId}`)
                        return
                    }
                    let orderLines = this.filterProductOrderlines(response, true)
                    if (!orderLines.length) {
                        throw new Error(`Found no relevant orderLines in products: ${JSON.stringify(response)}`)
                    }
                    let firstDeliveryDate = this.findFirstProductDeliveryDate(orderLines)

                    // console.log(`Writing productDeliveryDate '${firstDeliveryDate}' to tasks on installation ${ins.label}`)

                    //Add products to this.installations
                    let insIndex = this.installations.findIndex((installation) => installation.instId == ins.instId)
                    if (insIndex == -1) {
                        throw new Error('Could not find installation from filtered data in unfiltered data')
                    }
                    for (let i in this.installations[insIndex].tasks) {
                        this.$set(this.installations[insIndex].tasks[i], 'products', orderLines) //Update task in installations
                        this.$set(this.installations[insIndex].tasks[i], 'productDeliveryDate', firstDeliveryDate)
                    }

                    //Add products to rawTasks
                    this.setProductsInRawTasks(orderLines, this.getConfiguration(ins.tasks[0]).label)

                }).catch((error) => {
                    console.error('Error in getting products for installation', error)
                })
            }
            EventBus.$emit('function-activity', {functionName: 'Booking_getAllProducts', isActive: false})
        },

        openFailedContactModal(){
            this.failedContactModalOpen = true
        },

        async regenerateMapMarkers(forceRerender = false) {
            // console.log('Regenterating all markers...')
            let typeString = `unknown-draft`//Default map marker
            let changeCounter = 0

            const markerKeysArr = Object.keys(this.mapMarkers)
            for (let marker of markerKeysArr) {
                if (!this.installations.find((ins) => ins.label == marker)) {
                    changeCounter += 1
                    this.$delete(this.mapMarkers, marker)
                    continue;
                }
                let markerType = this.mapMarkers[marker]?.type

                if (markerType?.includes('pending') && !this.settingsBD.get('showPendingTasks')) {
                    changeCounter += 1
                    this.$delete(this.mapMarkers, marker)
                    continue;
                }
                if (markerType?.includes('onhold') && !this.settingsBD.get('showOnHoldTasks')) {
                    changeCounter += 1
                    this.$delete(this.mapMarkers, marker)
                    continue;
                }

                if (markerType?.includes('unbooked') || markerType?.includes('onhold') || markerType?.includes('pending')){
                    if (markerType?.includes('inspection') && !this.settingsBD.get('showApptTypeInspection')) {
                        changeCounter += 1
                        this.$delete(this.mapMarkers, marker)
                        continue;
                    }
                    if (markerType?.includes('installation') && !this.settingsBD.get('showApptTypeInstallation')) {
                        changeCounter += 1
                        this.$delete(this.mapMarkers, marker)
                        continue;
                    }
                    if (markerType?.includes('technician') && !this.settingsBD.get('showApptTypeTechnician')) {
                        changeCounter += 1
                        this.$delete(this.mapMarkers, marker)
                        continue;
                    }
                    if (markerType?.includes('patch') && !this.settingsBD.get('showApptTypePatch')) {
                        changeCounter += 1
                        this.$delete(this.mapMarkers, marker)
                        continue;
                    }
                    if (markerType?.includes('ticket') && !this.settingsBD.get('showApptTypeTickets')) {
                        changeCounter += 1
                        this.$delete(this.mapMarkers, marker)
                        continue;
                    }
                }


            }
            if (changeCounter) {
                // console.log(`${changeCounter} markers deleted when regenerating map markers`)
                const deletedMarkerCount = markerKeysArr.length - Object.keys(this.mapMarkers).length
                if (changeCounter != deletedMarkerCount) {
                    console.error(`Counted ${changeCounter} deletes to mapMarkers, but ${deletedMarkerCount} have actually been deleted`)
                }
            }

            // let noCoordinateCount = 0
            for (let ins of this.installations) {
                if (!ins.label || !ins.coordinates) {
                    // noCoordinateCount += 1
                    continue; //Skip installations without label or coordinates
                }

                const markerType = this.getMarkerTypeFromInstallation(ins, this.activeInstallationLabel) || typeString

                if (!this.settingsBD.get('showPendingTasks') && markerType.includes('pending')) { continue; } //If showPendingTasks setting is false, skip generating pending markers
                if (!this.settingsBD.get('showOnHoldTasks') && markerType.includes('onhold')) { continue; }
                
                if (markerType.includes('unbooked') || markerType.includes('onhold') || markerType.includes('pending')){
                    if (!this.settingsBD.get('showApptTypeInspection') && markerType.includes('inspection')) { continue; }
                    if (!this.settingsBD.get('showApptTypeInstallation') && markerType.includes('installation')) { continue; }
                    if (!this.settingsBD.get('showApptTypeTechnician') && markerType.includes('technician')) { continue; }
                    if (!this.settingsBD.get('showApptTypePatch') && markerType.includes('patch')) { continue; }
                    if (!this.settingsBD.get('showApptTypeTickets') && markerType.includes('ticket')) { continue; }
                }


                const markerObj = {
                    key: `mapMarker-${ins.label}`,
                    id: ins.label,
                    type: markerType,
                    title: this.formatAddress(this.getConfiguration(ins.tasks[0]).address, false),
                    coordinates: { //TODO: Refer to Hub Address for PATCH-tasks
                        lat: parseFloat(ins.coordinates.Lat),
                        lng: parseFloat(ins.coordinates.Lng),
                    },
                    address: this.formatAddress(this.getConfiguration(ins.tasks[0]).address, false), //TODO: Refer to Hub Address for PATCH-tasks
                }
                if (JSON.stringify(this.mapMarkers[ins.label]) == JSON.stringify(markerObj)) { //Dont do anything if the new map marker is identical to an existing one
                    continue;
                } 

                this.$set(this.mapMarkers, ins.label, markerObj)
                changeCounter += 1
            }

            // console.log(`Regenerating all markers caused ${changeCounter} changes on ${Object.keys(this.mapMarkers).length} markers, based on ${this.installations.length} installations, of which ${noCoordinateCount} had no coordinates attached`)

            if (changeCounter || forceRerender) {
                await this.sleep(50)
                EventBus.$emit('okapi-map-refresh')
                // console.log(`Regenerated all ${Object.keys(this.mapMarkers).length} map markers`)
                if (!this.activeInstallationLabel) { //If no inst is active
                    EventBus.$emit('okapi-map-auto-center') //Center map on all markers
                }
                return this.mapMarkers
            } else {
                return this.mapMarkers
            }
        },

        async updateMarkerType(ins, activeInsLabel) {
            if (!ins || !ins.label || !this.mapMarkers || !this.mapMarkers[ins.label]) return;
            console.log(`Updating marker type for installation ${ins.label} (${activeInsLabel} is active)`)
            let tempMarker = this.mapMarkers[ins.label]
            tempMarker.type = this.getMarkerTypeFromInstallation(ins, activeInsLabel)
            this.$set(this.mapMarkers, ins.label, tempMarker)
            await this.sleep(100)
            EventBus.$emit('okapi-map-refresh')
            // console.log(tempMarker)
            EventBus.$emit('okapi-map-zoom-to-coords', tempMarker.coordinates) //Zoom to active installation
        },

        async mountedHandler() {
            EventBus.$emit('function-activity', {functionName: 'Booking_mounted', isActive: true})

            this.settingsBD.setValue('bookedSelectedOptions',this.bookedSortingOptions)
            this.settingsBD.setOptions('sortUnbookedPTBy',this.unbookedSortingOptProjTasks)
            this.settingsBD.setOptions('sortUnbookedTTBy',this.unbookedSortingOptTroubleTickets)
            this.settingsBD.setOptions('sortInstsBy',this.instListSortingOptions)
            EventBus.$on('edit-settings-modal-closing', () => {this.editSettings = false})
            EventBus.$on('edit-settings-modal-open', () => {this.editSettings = true})

            // Active Installation 
            EventBus.$on('open-custom-appointment-note-modal', () => {this.openCustomAppointmentNoteModal()})
            EventBus.$on('open-failed-contact-modal', () => {this.openFailedContactModal()})
            EventBus.$on('open-edit-tech-data-modal', () => {this.openEditTechDataModal()})
            EventBus.$on('add-new-unit-work', () => {this.addNewUnitWork()})
            EventBus.$on('unit-work-clicked', (payload) => {this.unitWorkClicked(payload)})
            EventBus.$on('open-upload-note-modal', () => {this.openUploadNoteModal()})
            EventBus.$on('open-file-modal', () => {this.openFileModal()})
            EventBus.$on('delete-connection-date', () => {this.deleteConnectionDate()})
            EventBus.$on('status-clicked', (payload) => {this.statusClicked(payload)})
            EventBus.$on('open-attachment', (payload) => {this.openAttachment(payload)})
            EventBus.$on('open-download-report-modal', (payload) => {this.openDownloadReportModal(payload)})
            EventBus.$on('open-sms-modal', (payload) => {this.openSmsModal(payload)})
            EventBus.$on('on-book-button-click', (payload) => {this.onBookButtonClick(payload)})
            EventBus.$on('on-delete-appointment-button-click', (payload) => {
                this.showRemoveAppointmentModal = payload.showRemoveAppointmentModal
                this.selectedAppointmentId = payload.selectedAppointmentId
            })
            EventBus.$on('on-edit-appointment-state-click', (payload) => {
                this.showEditAppointmentStateModal = payload.showEditAppointmentStateModal
                this.selectedAppointmentId = payload.selectedAppointmentId
            })
            EventBus.$on('active-inst-subtask-clicked', (payload) => {this.subtaskStatusClicked(payload)})
            EventBus.$on('visit-in-vain', (payload) => this.visitInVain(payload.activeInstallationData, {...payload.appointment, id: payload.id}))
            EventBus.$on('on-show-appointment-change-log-history-clicked', (payload) => {
                this.activeChangeLogDoc = payload.doc
                this.changeLogDocId = payload.id
                this.openShowChangeLogModal = true
            })
            EventBus.$on('view-change-log-modal-closing', () => {
                this.openShowChangeLogModal = false
                this.activeChangeLogDoc = null
                this.changeLogDocId = ''
            })

            EventBus.$on('portCheck-button-click', () => {this.portCheckModalOpen = true})
            EventBus.$on('port-check-modal-close', () => {this.portCheckModalOpen = false})

            EventBus.$on('booking-form-write-call-in-advance-note', (noteBody) => {
                this.noteType = 'Intern'
                this.noteText = noteBody
                this.submitNote()
            })

            EventBus.$on('add-custom-appointment-button-click', this.onAddCustomAppointmentButtonClicked.bind(this))
            EventBus.$on('project-timeline-item-clicked', this.onReceiveItemClicked.bind(this))
            EventBus.$on('okapi-map-item-clicked', this.onReceiveItemClicked.bind(this))
            EventBus.$on('reload-inst-button-click',() => {this.setActiveInstallationData(true)})
            EventBus.$on('book-button-click', (payload) => {this.onBookButtonClick(payload)})
            EventBus.$on('edit-custom-appointment-button-click', this.onEditCustomAppointmentButtonClick.bind(this))
            EventBus.$on('booking-form-save', this.onSaveBooking.bind(this))

            EventBus.$on('closeSubtaskModal-button-click', this.subtaskStatusClicked.bind(this))

            EventBus.$on('edit-settings-loaded', (payload) => {
                console.log("settings received:",JSON.stringify(payload))
                this.settingsParams = payload

                for (let i in this.bookedSortingOptions)
                {
                    let index = this.settingsBD.get("bookedSelectedOptions").findIndex(p => {
                        return p.value == this.bookedSortingOptions[i].value})
                    this.bookedSortingOptions[i].rank = this.settingsBD.get("bookedSelectedOptions")[index].rank
                }

                if (this.settingsBD.get("sortUnbookedPTBy") == "productDeliveryDate") this.getAllProducts()
            })

            EventBus.$on('edit-settings-updated', (payload) => {



                for (let i in payload){
                    this.onSettingUpdate(payload[i])
                }
            })

            EventBus.$on('newFile-click', () => {this.openFileModal()})
            EventBus.$on('top-search-type', (payload) => {
                this.searchFilterValue = String(payload).toLowerCase()
                if (!payload) this.searchFilterValue = ''
            })
            EventBus.$on('state-changed', () => {this.setActiveInstallationData(true)})
            EventBus.$on('add-unit-work', (note) => this.addNewUnitWork(note))
            EventBus.$on('tech-data-changed', () => {this.setActiveInstallationData(true)})
            EventBus.$on('stateChange-modal-closing', () => {this.stateModalOpen = false})
            EventBus.$on('subtask-state-change-modal-closing', () => {this.showSubtaskModal = false})
            EventBus.$on('remove-appointment-modal-closing', () => { this.showRemoveAppointmentModal = false })
            EventBus.$on('sms-modal-closing', () => {this.smsModalOpen = false})
            EventBus.$on('loading-timelines', (value) => {
                this.loadingBookedTimeline = value; 
                this.loadingUnBookedTimeline = value;
            })
            EventBus.$on('edit-appointment-state-modal-closing', () => {this.showEditAppointmentStateModal = false})
            EventBus.$on('cancellation-list-modal-closing', () => {this.showCancellationListModal = false})
            EventBus.$on('cancellation-list-modal-open', () => {this.showCancellationListModal = true})

            EventBus.$on('timeline-update-task', async () => {
                await this.setActiveInstallationData(true)
                await this.setActiveInstallationData() //Why twice?
            })

            EventBus.$on('subtaskModalLoading', (payload) => {
                this.subtaskModalLoading = payload
            })
            EventBus.$on('subtaskStateChangeFormSubmit', () => {
                this.showSubtaskModal = false
            })
            
            EventBus.$on('file-viewer-modal-close', () => {
                this.openFileModalOpen = false
            })

            EventBus.$on('report-download-modal-close', () => {this.downloadReportModalOpen = false})

            EventBus.$on('edit-tech-data-modal-closing', () => {this.editTechDataModalOpen = false})
            
            EventBus.$on('edit-unit-work-modal-close', () => {
                this.activeUnitWork = {}
                this.editUnitWorkModalOpen = false
            })

            EventBus.$on('edit-custom-appointment-modal-closing', () => {this.editCustomAppointmentModalOpen = false})

            EventBus.$on('customAppointmentSaved', () => {this.setActiveInstallationData()})

            EventBus.$on('customAppointmentDeleted', () => {this.onReceiveItemClicked(null)})

            EventBus.$on('admin-ignore-lead-permissions', (value) => {this.ignoreLeadPermissions = value})

            EventBus.$on('shortcut-to-close-task', (task) => {
                this.activeInstallationLabel = this.getConfiguration(task).label
                this.activeTask = task
                this.stateModalOpen = true
            })

            EventBus.$on('failed-contact-modal-close', () => {
                this.failedContactModalOpen = false
            })

            EventBus.$on('attachmentShouldOpen', (attachmentId) => {
                this.openAttachment(attachmentId)
            })

            EventBus.$on('uploadFileModalShouldOpen', () => {
                this.openFileModal()
            })

            EventBus.$on('fileUploadedWhileStateChangeModalOpen', () => {
                this.setActiveInstallationData(true)
            })

            EventBus.$on('data-migration-started', () => {this.migrateData = true})
            EventBus.$on('data-migration-complete', () => {this.migrateData = false})
            EventBus.$on('data-migration-progress-percentage', (payload) => {
                this.migrationProgressPercent = payload
                this.migrationProgressBarLabel = `${payload}% data overført`
            })
            // EventBus.$on('data-migration-start-migration', () => {this.migrateUnitWorkAndSubtasks()})
            // EventBus.$on('data-migration-start-migration', () => {this.transferInternalSubtasksForAllNonBookedInstallations(this.availableInstallations[1],'JpX2aJB5M6831J4f4Ga1','IvfieHeuXkQiQ0utYPYk')})
            // EventBus.$on('data-migration-start-migration', () => {this.transferAppointments('JpX2aJB5M6831J4f4Ga1','IvfieHeuXkQiQ0utYPYk')})
            // EventBus.$on('data-migration-start-migration', () => {this.transferInternalSubtaskForAllActiveAppointments('IvfieHeuXkQiQ0utYPYk','JpX2aJB5M6831J4f4Ga1','IvfieHeuXkQiQ0utYPYk')})
            this.minuteInterval = setInterval(() => {
                // console.log(`In the latest 30 seconds, availableInstallations were re-computed ${this.availableInstallationsRecomputeCount} times`)
                if (this.availableInstallationsRecomputeCount > 300){
                    swal('Uendeligt loop detekteret', `Listen over ikke-bookede kunder blev opdateret ${this.availableInstallationsRecomputeCount} gange på de sidste 30 sekunder, det formodes at være et uendeligt opdaterings-loop`, 'error')
                    throw new Error(`Infinite loop detected in availableInstallations for URI '${this.$route.path}'`)
                }
                this.availableInstallationsRecomputeCount = 0
            }, 30000)
            // Until here was in beforeMount

            document.body.classList.add('sidebar-collapse')

            let sorting = 'asc'
            this.getAppointments(sorting)
            this.getAllInstUpdates()
            this.getCustomAppointments(sorting)

            if(this.settingsBD.get("showClosed")){ //I think this should always be false
                this.bindClosedAppointments(this.settingsBD.get("showClosed"))
            }

            this.$bind('firebaseInternalSubtasks', db.collection(`InternalSubTasks`)
                .where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier)
            )

            this.$bind('firebaseServiceProviders', db.collection('ServiceProviders'))

            this.$bind('firebaseUsers', db.collection('Users')).then(async () => {
                // if (this.project.Workers && this.project.Workers.length && this.firebaseUsers.length) {
                //     await this.getAvailableWorkersData()
                // }
            })

            this.$bind('inVainVisits', db.collection('UnitWork').where('Unit.Id', '==', '931.F.99').where('LinkedProjects', 'array-contains', this.$route.params.projectIdentifier))

            // this.getAvailableWorkersData()

            // this.triggerLoadBookingData();
            this.runWhenPropertySet(() => {return this.appointmentsBound}, this.triggerLoadBookingData);

            document.title = `${this.project.Name} Dashboard - FiberTeam`
            localStorage.setItem('last-visited-page', 'booking')
            EventBus.$emit('function-activity', {functionName: 'Booking_mounted', isActive: false})
        },
    },

    watch: {
        activeInstallationLabel: {
            immediate: false,
            async handler(newVal, oldVal) {
                console.log(`activeInstallationLabel changed from ${oldVal} to ${newVal}`)
                this.showBookingModal = false
                this.$bind('activeInstallationUnitWork', db.collection('UnitWork')
                    .where('ConfigurationItem.Label','==',this.activeInstallationLabel)
                    .orderBy('Date', 'desc')
                )
                await this.setActiveInstallationData(true)
                // this.setActiveInstallationData() // Why twice?
            }
        },
        
        project: {
            immediate: true,
            async handler() {
                if (this.project.Name) {
                    document.title = `${this.project.Name} Dashboard - FiberTeam`
                    await this.settingsBD.setActiveProject(this.project)
                    // if (this.project.Workers && this.project.Workers.length && this.firebaseUsers.length) {
                    //     await this.getAvailableWorkersData()
                    // }

                    if (this.project.Type.includes(ProjectType.TROUBLE_TICKETS)){ // Project includes Trouble Tickets
                        this.loadTickets = true
                        if (this.project.Type.length == 1){ // Project does not include Project Tasks
                            this.loadProjectTasks = false
                        }
                    }
                    this.hasActiveProject = true

                    try {
                        await this.runWhenPropertySet(() => {return this.$root.$children[0].isReady}, this.getAppointments.bind(this, 'asc'))
                    } catch (error) {
                        console.error(error)
                    }

                    try {
                        await this.runWhenPropertySet(() => {return this.$root.$children[0].isReady}, this.getCustomAppointments.bind(this, 'asc'))
                    } catch (error) {
                        console.error(error)
                    }

                    try {
                        await this.runWhenPropertySet(() => {return this.$root.$children[0].isReady}, this.getAllInstUpdates.bind(this, 'asc'))
                    } catch (error) {
                        console.error(error)
                    }
                }
            }
        },
        
        sortAscending: {
            immediate: true,
            async handler(sortAscending) {
                let sorting = sortAscending ? 'asc' : 'desc'
                sorting = 'asc'

                try {
                    await this.runWhenPropertySet(() => {return this.$root.$children[0].isReady}, this.getAllInstUpdates)
                } catch (error) {
                    console.error(error)
                } 

                try {
                    await this.runWhenPropertySet(() => {return this.$root.$children[0].isReady}, this.getCustomAppointments.bind(this, sorting))
                } catch (error) {
                    console.error(error)
                }
            },
        },
        
        selectedTime: {
            immediate: false,
            handler(selectedTime) {
                if(selectedTime){
                    let selectedTimeObj = JSON.parse(selectedTime)
                    this.bookingFormDataTimeFrom = selectedTimeObj.from
                    this.bookingFormDataTimeTo = selectedTimeObj.to
                    this.selectedTime = null
                }
            }
        },
        
        activeSubtaskResponsibleIndex: {
            immediate: false,
            async handler(activeSubtaskResponsibleIndex) {
                if (this.availableWorkers[activeSubtaskResponsibleIndex] && this.availableWorkers[activeSubtaskResponsibleIndex].text != "Ingen") {
                    let workerEmail = this.availableWorkers[activeSubtaskResponsibleIndex].text
                    let userData = await this.dataGetUser(workerEmail.toLowerCase())
                    let responsibleObj = {
                        Email: workerEmail,
                        Initials: userData.Initials,
                        Name: userData.Name,
                        ResponsibilityTakenAt: this.formatMachineTimestamp(new Date())
                    }
                    this.$set(this.activeSubtask, "Responsible", responsibleObj)
                } else {
                    // delete this.activeSubtask.Responsible
                }
            }
        },
        firebaseInternalSubtasks: {
            immediate: false,
            handler() {
                EventBus.$emit('reload-subtask-status-icons')
            }
        },

        closedSince: {
            immediate: false,
            handler() {
                this.showHideClosedTasks(this.project.AreaCodes)
            }
        },

        availableInstallations: {
            immediate: false,
            handler() {
                this.availableInstallationsRecomputeCount += 1
            },
        },
    },

    beforeDestroy() {
        EventBus.$off('booking-form-save', this.onSaveBooking)
        EventBus.$off('book-button-click', (payload) => {this.onBookButtonClick(payload)})
    },

    // beforeMount() {
    //     this.settingsBD.setValue('bookedSelectedOptions',this.bookedSortingOptions)
    //     this.settingsBD.setOptions('sortUnbookedPTBy',this.unbookedSortingOptProjTasks)
    //     this.settingsBD.setOptions('sortUnbookedTTBy',this.unbookedSortingOptTroubleTickets)
    //     this.settingsBD.setOptions('sortInstsBy',this.instListSortingOptions)
    //     EventBus.$on('edit-settings-modal-closing', () => {this.editSettings = false})
    //     EventBus.$on('edit-settings-modal-open', () => {this.editSettings = true})

    //     // Active Installation 
    //     EventBus.$on('open-custom-appointment-note-modal', () => {this.openCustomAppointmentNoteModal()})
    //     EventBus.$on('open-failed-contact-modal', () => {this.openFailedContactModal()})
    //     EventBus.$on('open-edit-tech-data-modal', () => {this.openEditTechDataModal()})
    //     EventBus.$on('add-new-unit-work', () => {this.addNewUnitWork()})
    //     EventBus.$on('unit-work-clicked', (payload) => {this.unitWorkClicked(payload)})
    //     EventBus.$on('open-upload-note-modal', () => {this.openUploadNoteModal()})
    //     EventBus.$on('open-file-modal', () => {this.openFileModal()})
    //     EventBus.$on('delete-connection-date', () => {this.deleteConnectionDate()})
    //     EventBus.$on('status-clicked', (payload) => {this.statusClicked(payload)})
    //     EventBus.$on('open-attachment', (payload) => {this.openAttachment(payload)})
    //     EventBus.$on('open-download-report-modal', (payload) => {this.openDownloadReportModal(payload)})
    //     EventBus.$on('open-sms-modal', (payload) => {this.openSmsModal(payload)})
    //     EventBus.$on('on-book-button-click', (payload) => {this.onBookButtonClick(payload)})
    //     EventBus.$on('on-delete-appointment-button-click', (payload) => {
    //         this.showRemoveAppointmentModal = payload.showRemoveAppointmentModal
    //         this.selectedAppointmentId = payload.selectedAppointmentId
    //     })
    //     EventBus.$on('on-edit-appointment-state-click', (payload) => {
    //         this.showEditAppointmentStateModal = payload.showEditAppointmentStateModal
    //         this.selectedAppointmentId = payload.selectedAppointmentId
    //     })
    //     EventBus.$on('active-inst-subtask-clicked', (payload) => {this.subtaskStatusClicked(payload)})
    //     EventBus.$on('visit-in-vain', (payload) => this.visitInVain(payload.activeInstallationData, {...payload.appointment, id: payload.id}))
    //     EventBus.$on('on-show-appointment-change-log-history-clicked', (payload) => {
    //         this.activeChangeLogDoc = payload.doc
    //         this.changeLogDocId = payload.id
    //         this.openShowChangeLogModal = true
    //     })
    //     EventBus.$on('view-change-log-modal-closing', () => {
    //         this.openShowChangeLogModal = false
    //         this.activeChangeLogDoc = null
    //         this.changeLogDocId = ''
    //     })

    //     EventBus.$on('portCheck-button-click', () => {this.portCheckModalOpen = true})
    //     EventBus.$on('port-check-modal-close', () => {this.portCheckModalOpen = false})

    //     EventBus.$on('booking-form-write-call-in-advance-note', (noteBody) => {
    //         this.noteType = 'Intern'
    //         this.noteText = noteBody
    //         this.submitNote()
    //     })

    //     EventBus.$on('add-custom-appointment-button-click', this.onAddCustomAppointmentButtonClicked.bind(this))
    //     EventBus.$on('project-timeline-item-clicked', this.onReceiveItemClicked.bind(this))
    //     EventBus.$on('okapi-map-item-clicked', this.onReceiveItemClicked.bind(this))
    //     EventBus.$on('reload-inst-button-click',() => {this.setActiveInstallationData(true)})
    //     EventBus.$on('book-button-click', (payload) => this.onBookButtonClick(payload))
    //     EventBus.$on('edit-custom-appointment-button-click', this.onEditCustomAppointmentButtonClick.bind(this))
    //     EventBus.$on('booking-form-save', this.onSaveBooking.bind(this))

    //     EventBus.$on('closeSubtaskModal-button-click', this.subtaskStatusClicked.bind(this))

    //     EventBus.$on('edit-settings-loaded', (payload) => {
    //         console.log("settings received:",JSON.stringify(payload))
    //         this.settingsParams = payload

    //         for (let i in this.bookedSortingOptions)
    //         {
    //             let index = this.settingsBD.get("bookedSelectedOptions").findIndex(p => {
    //                  return p.value == this.bookedSortingOptions[i].value})
    //             this.bookedSortingOptions[i].rank = this.settingsBD.get("bookedSelectedOptions")[index].rank
    //         }

    //         if (this.settingsBD.get("sortUnbookedPTBy") == "productDeliveryDate") this.getAllProducts()
    //     })

    //     EventBus.$on('edit-settings-updated', (payload) => {

    //         // console.log("The following settings have been updated")
    //         // console.log(JSON.stringify(payload))

    //         for (let i in payload){
    //             // console.log(payload[i])
    //             this.onSettingUpdate(payload[i])
    //         }
    //     })

    //     EventBus.$on('newFile-click', () => {this.openFileModal()})
    //     EventBus.$on('top-search-type', (payload) => {
    //         this.searchFilterValue = String(payload).toLowerCase()
    //         if (!payload) this.searchFilterValue = ''
    //     })
    //     EventBus.$on('state-changed', () => {this.setActiveInstallationData(true)})
    //     EventBus.$on('add-unit-work', (note) => this.addNewUnitWork(note))
    //     EventBus.$on('tech-data-changed', () => {this.setActiveInstallationData(true)})
    //     EventBus.$on('stateChange-modal-closing', () => {this.stateModalOpen = false})
    //     EventBus.$on('subtask-state-change-modal-closing', () => {this.showSubtaskModal = false})
    //     EventBus.$on('remove-appointment-modal-closing', () => { this.showRemoveAppointmentModal = false })
    //     EventBus.$on('sms-modal-closing', () => {this.smsModalOpen = false})
    //     EventBus.$on('loading-timelines', (value) => {
    //         this.loadingBookedTimeline = value; 
    //         this.loadingUnBookedTimeline = value;
    //     })
    //     EventBus.$on('edit-appointment-state-modal-closing', () => {this.showEditAppointmentStateModal = false})
    //     EventBus.$on('cancellation-list-modal-closing', () => {this.showCancellationListModal = false})
    //     EventBus.$on('cancellation-list-modal-open', () => {this.showCancellationListModal = true})

    //     EventBus.$on('timeline-update-task', async () => {
    //         await this.setActiveInstallationData(true)
    //         await this.setActiveInstallationData() //Why twice?
    //     })

    //     EventBus.$on('subtaskModalLoading', (payload) => {
    //         this.subtaskModalLoading = payload
    //     })
    //     EventBus.$on('subtaskStateChangeFormSubmit', () => {
    //         this.showSubtaskModal = false
    //     })
        
    //     EventBus.$on('file-viewer-modal-close', () => {
    //         this.openFileModalOpen = false
    //     })

    //     EventBus.$on('report-download-modal-close', () => {this.downloadReportModalOpen = false})

    //     EventBus.$on('edit-tech-data-modal-closing', () => {this.editTechDataModalOpen = false})
        
    //     EventBus.$on('edit-unit-work-modal-close', () => {
    //         this.activeUnitWork = {}
    //         this.editUnitWorkModalOpen = false
    //     })

    //     EventBus.$on('edit-custom-appointment-modal-closing', () => {this.editCustomAppointmentModalOpen = false})

    //     EventBus.$on('customAppointmentSaved', () => {this.setActiveInstallationData()})

    //     EventBus.$on('customAppointmentDeleted', () => {this.onReceiveItemClicked(null)})

    //     EventBus.$on('admin-ignore-lead-permissions', (value) => {this.ignoreLeadPermissions = value})

    //     EventBus.$on('shortcut-to-close-task', (task) => {
    //         this.activeInstallationLabel = this.getConfiguration(task).label
    //         this.activeTask = task
    //         this.stateModalOpen = true
    //     })

    //     EventBus.$on('failed-contact-modal-close', () => {
    //         this.failedContactModalOpen = false
    //     })

    //     EventBus.$on('attachmentShouldOpen', (attachmentId) => {
    //         this.openAttachment(attachmentId)
    //     })

    //     EventBus.$on('uploadFileModalShouldOpen', () => {
    //         this.openFileModal()
    //     })

    //     EventBus.$on('fileUploadedWhileStateChangeModalOpen', () => {
    //         this.setActiveInstallationData(true)
    //     })

    //     EventBus.$on('data-migration-started', () => {this.migrateData = true})
    //     EventBus.$on('data-migration-complete', () => {this.migrateData = false})
    //     EventBus.$on('data-migration-progress-percentage', (payload) => {
    //         this.migrationProgressPercent = payload
    //         this.migrationProgressBarLabel = `${payload}% data overført`
    //     })
    //     EventBus.$on('data-migration-start-migration', () => {this.migrateUnitWorkAndSubtasks()})

    //     this.minuteInterval = setInterval(() => {
    //         // console.log(`In the latest 30 seconds, availableInstallations were re-computed ${this.availableInstallationsRecomputeCount} times`)
    //         if (this.availableInstallationsRecomputeCount > 300){
    //             swal('Uendeligt loop detekteret', `Listen over ikke-bookede kunder blev opdateret ${this.availableInstallationsRecomputeCount} gange på de sidste 30 sekunder, det formodes at være et uendeligt opdaterings-loop`, 'error')
    //             throw new Error(`Infinite loop detected in availableInstallations for URI '${this.$route.path}'`)
    //         }
    //         this.availableInstallationsRecomputeCount = 0
    //     }, 30000)

    //     console.log('Booking page beforeMount done')
    // },

    async mounted() {
        try {
            await this.runWhenPropertySet(() => {return this.$root.$children[0].isReady}, this.mountedHandler);
            console.log('Booking page mounted done')
        } catch (error) {
            console.error(error)
        }
    },

    destroyed() {
        document.body.classList.remove('sidebar-collapse')
        clearInterval(this.minuteInterval)
    }
}
</script>


<style>
.swalDangerBtn {
    background-color: red;
}

.swalDangerBtn:not([disabled]):hover {
    background-color: color-mix(in srgb, red 90%, black);
}

.scrollable {
    overflow-y: auto;
    overflow-x: auto;
    height: 100%;
    position: relative;
}

.full-height {
    height: calc(100vh - 205px);
}

.half-height {
    height: calc(50vh - 110px);
}

.scrollable table.timeline-table {
    margin-bottom: 0 !important;
}

.scrollable table.timeline-table tr th {
    border-top: 2px solid #dee2e6 !important;
    border-bottom: none !important;
}

.scrollable table.timeline-table tr td {
    border-top: none !important;
}

.scrollable table.timeline-table:first-child tr th {
    border-top: none !important;
}

.table-md td, .table-md th {
    padding: .5rem;
}

.card-body.p-0 .table tbody>tr>td:first-of-type,
.card-body.p-0 .table thead>tr>th:first-of-type {
    padding-left: 0.7rem;
}

.table-md td > span,
.address-cell {
    font-size: 14px;
}

/*.address-cell i {
    top: -5px;
    position: relative;
}*/

/* .address-text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
} */

.map {
    height: 100%;
}

.note-body {
    white-space: break-spaces;
}

.booking-mini-modal {
    /*position: absolute;*/
    position: fixed;
    right: -350px;
    top: 0;
    height: 100vh;
    width: 345px !important;
    /* width: 300px; */
    z-index: 999;
    box-shadow: -11px 0 45px 15px rgb(60 60 60 / 60%);
    -webkit-animation: slide 0.2s forwards;
    animation: slide 0.2s forwards;
}

.booking-mini-modal .card {
    margin-bottom: 0;
    height: 100%;
    border-left: 4px solid #037be4;
    border-radius: 0;
}

.subTask-mini-modal {
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 99;
}
.subTask-mini-modal .card {
    margin-bottom: 0;
    height: 40%;
    border-radius: 0;
}

.ui.mini.input {
    font-size: .78571429em !important;
}

.input.size-3 {
    width: 80px !important;
    margin-right: 10px !important;
}

.input.size-5 {
    width: 140px !important;
    margin-right: 10px !important;
}

.zoom-8 {
    zoom: 0.8;
}

.active-installation-box .card-title {
    font-size: 1rem;
}

.color-1eb230 {
    color: #1eb230;
}
.color-c7141a {
    color: #c7141a;
}
.color-c3ad00 {
    color: #c3ad00;
}
.color-037be4 {
    color: #037be4;
}
.color-666666 {
    color: #666666;
}
.color-ffc107 {
    color: #ffc107;
}
.color-FFCC00 {
    color: #FFCC00;
}
.color-1E8449 {
    color: #1E8449;
}
.color-58D68D {
    color: #58D68D;
}
.color-ee82ee {
    color: #ee82ee;
}

.color-D22E37 {
    background-color: #D22E37!important;
}

.color-D22E37 button:hover {
    background-color: #E37065!important;
}

.ui.standard.modal {
    height: auto;
}

.failed-coordinate-imports {
    max-height: 400px;
}

.imageAttachment {
    min-width: 35%;
    max-width: 100%;
    max-height: 600px;
}

.centered{
    text-align: center;
}

.hover-pointer{
    cursor: pointer;
}

.fa-rotate-135 {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -ms-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.checkbox-fix-position {
    margin-top: 5px;
}

.link {
    cursor: pointer;
}

.headerWrapper{
    margin-bottom: -10px !important;
    display:table;
}

.headerInner{
    display:table-cell;
    vertical-align:middle;
    text-align:left;
}

@-webkit-keyframes slide {
    100% { right: 0; }
}

@keyframes slide {
    100% { right: 0; }
}


@media only screen and (max-width: 575px) {
    .full-height:not(.map-card),
    .half-height { 
        height: auto !important;
        min-height: 200px;
    }
}

.layout-button {
    font-size: 25px;
    margin-top: 7px;
    margin-left: 5px;
    margin-right: 5px;
    color: black;
    opacity: 0.55;
}
.layout-button-selected {
    font-size: 25px;
    margin-top: 7px;
    margin-left: 5px;
    margin-right: 5px;
    color:#2185D0;
}
.layout-button:hover {
    transform: scale(1.125);
}
.layout-button-selected:hover {
    transform: scale(1.125);
}

</style>
